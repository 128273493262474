/* eslint-disable */
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { memo, startTransition, useEffect, useState } from "react";
import * as API from '../../../framework/API/api'
import { ENTITYNAME } from "../../../framework/constant/constant";
import RoundButton from "../../../framework/forms/helpercomponents/buttons/RoundButton";
import { useTranslation } from "../../../locale/useTranslation";
import PlanningFilter from "../../Planning/PlanningFilter";

const ManageContentHeader = (props) => {

    const lang = useTranslation();

    const style = { margin: "0 10px" }
    const textStyle = { padding: "0 5px", display: "inline-block", fontSize: "13px", fontWeight: '400', marginBottom: "0px" }
    const buttonStyle = { padding: "0 5px", display: "inline-block", backgroundColor: "white", height: '30px', width: "150px" }

    const [mediaCategories, setMediaCategories] = useState([]);
    const [selectedMediaCategory, setSelectedMediaCategory] = useState({});
    const [showFilterDropdown, setShowFilterDropDown] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState({});
    const [isFilterCleared, setIsFilterCleared] = useState(true);
    const [filterPopupOpen, setFilterPopupOpen] = useState(false);

    useEffect(() => {
        loadData();
    }, [])

    const loadData = async () => {

        let mediaCategoryRes = await API.getDataLookup(ENTITYNAME.MediaCategory, { sort: { SID: 1 } });
        if (mediaCategoryRes.success && mediaCategoryRes.data.length > 0) {
            setMediaCategories(mediaCategoryRes.data);
            setSelectedMediaCategory(mediaCategoryRes.data[0]);
        } else {
            setMediaCategories([]);
        }

    }

    const onLoad = () => {
        startTransition(() => {
            props.setSelectedMediaCategory(selectedMediaCategory);
        }, [])
    }

    const onExport = () => {
        // props.setShowExport({ show: true, filterData: { ...filterData } });
    }

    //
    const onClickAdvancedFilter = (edit = false) => {
        console.log(edit)
        console.log(selectedTemplate)
        if (edit && selectedTemplate && Object.keys(selectedTemplate).length == 0) {
            toast.error(lang.please_select_template_to_edit_error_message, { position: toast.POSITION.TOP_RIGHT });
            return;
        }
        setFilterPopupOpen(true);
        setSelectedTemplate(edit ? selectedTemplate : {});
    }

    const onShowFilterTemplate = () => {

        console.log(showFilterDropdown)

        if (showFilterDropdown) {
            setIsFilterCleared(true);
            setSelectedTemplate({});
        }

        setShowFilterDropDown(!showFilterDropdown);
    }

    return (
        <div style={{ display: "flex", alignItems: "center", backgroundColor: "white", border: "1px solid #ededed", overflow: 'auto', padding: '10px', height: "80px", overflowY: "hidden" }}>
            <div style={{ marginRight: "15px" }}>
                <h5 style={textStyle}>Media Category :</h5><br />
                <DropDownList
                    style={buttonStyle}
                    data={mediaCategories}
                    dataItemKey="_id"
                    textField="Description"
                    value={selectedMediaCategory}
                    onChange={(e) => setSelectedMediaCategory(e.target.value)}
                    name={"mediaCategory"}
                />
            </div>
            <RoundButton btnColor={'primary'} style={{ alignSelf: "end", ...style, color: "whitesmoke" }} icon={'eye'} title={'Load'} name={'Load'} onClick={onLoad} />
            <RoundButton btnColor={'warning'} style={{ alignSelf: "end", color: "whitesmoke" }} icon={'download'} title={'Export'} name={'Export'} onClick={onExport} />

            <div className="flex-container-reverse flex-item-auto mt-3">

                {showFilterDropdown && <div className="input-group w-300 martb">
                    <DropDownList
                        style={{ width: selectedTemplate && Object.keys(selectedTemplate).length > 0 ? '200px' : '250px' }}
                        name={"Template"}
                        textField="Name"
                        dataItemKey="_id"
                        value={selectedTemplate}
                        // onChange={onFilterTemplateChange}
                        title="Filter Template"
                    />
                    {selectedTemplate && Object.keys(selectedTemplate).length > 0 && <button title="Edit Filter Template" style={{ border: "0px", backgroundColor: "white", height: "40px", marginLeft: "5px" }} onClick={() => onClickAdvancedFilter()} aria-label="Edit Template">
                        <div className="input-group-append" >
                            <span className="input-group-text" style={{ backgroundColor: "white" }}>
                                <i style={{ margin: "5px 0px", color: "black" }} className="fa fa-pencil" />
                            </span>
                        </div>
                    </button>}
                    <button title="Advanced Search" style={{ border: "0px", backgroundColor: "white", height: "40px", marginLeft: "5px" }} onClick={() => onClickAdvancedFilter()} aria-label="Advanced Search">
                        <div className="input-group-append" >
                            <span className="input-group-text" style={isFilterCleared ? { backgroundColor: "white" } : { backgroundColor: "black" }}>
                                <i style={{ margin: "5px 0px", color: isFilterCleared ? "black" : "white" }} className="fa fa-list" />
                            </span>
                        </div>
                    </button>
                </div>
                }


                <div className="input-group w-300 martb mr-4" style={{ margin: '10px 5px 10px 0px' }}>
                    <input
                        type="text"
                        className="form-control shadow-inset-2 pt-0 pb-0"
                        id="searchInput"
                        placeholder={lang.search_button_text}
                    // onChange={onSearch}
                    // onFocus={(e) => {
                    //     if (props.setOutSideFocus) {
                    //         props.setOutSideFocus(true);
                    //     }
                    // }}
                    // onBlur={(e) => {
                    //     if (props.setOutSideFocus) {
                    //         props.setOutSideFocus(false);
                    //     }
                    // }}
                    />
                    <button title={showFilterDropdown ? "Clear Filters" : "Filters"} style={{ border: "0px", backgroundColor: "white", height: "40px", marginLeft: "5px" }} onClick={onShowFilterTemplate} aria-label="Advanced Search">
                        <div className="input-group-append" >
                            <span className="input-group-text" style={!showFilterDropdown ? { backgroundColor: "white" } : { backgroundColor: "black" }}>
                                <i style={{ margin: "4px 0px", color: !showFilterDropdown ? "black" : "white" }} className="fa fa-filter" />
                            </span>
                        </div>
                    </button> : <button id="search" style={{ border: "0px", backgroundColor: "white", height: "38px" }} >
                        {/* <div className="input-group-append">
                            <span className="input-group-text">
                                <i style={{ margin: "4px 0px" }} className="fa fa-search" />
                            </span>
                        </div> */}
                    </button> 
                </div>
            </div>

            {filterPopupOpen && <PlanningFilter
                closeFilterPopup={() => { setFilterPopupOpen(false) }}
                setSelectedTemplate={setSelectedTemplate}
                isFilterCleared={setIsFilterCleared}
            />}
        </div>
    );
}

export default memo(ManageContentHeader);