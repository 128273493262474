/* eslint-disable */
// Some Important Docs
//https://www.codingdeft.com/posts/react-usestate-array/

import moment from "moment";
import { APIURL, LOGINURL, PLANNINGURL, SCHEDULINGURL, MEDIAURL, FILEURL, CMSURL, PAGEURL, GetHeader, RECONSILEURL, USERURL, FILETYPEURL, CONTRACTURL, BOOKINGURL, DATAMIGRATIONURL, BILLINGURL, BOOKINGSUMMARYURL, LOCALSTORAGE_KEY, LOGGERURL, MONETIZATION, ENTITYNAME, WORKORDERURL, LIVEEVENTURL, MANAGECONTENTURL, REPORTSURL, OTTURL, AMAZONMMCMEFXML, SERVERHEALTHURL, ApplicationConfigurationKeys, CONFIGURL, RAIL_STATUS, TRANSLATORURL, DASHBOARDURL, ROLETYPE, INGESTION_MODULETYPES, DELETELOGURL, IMDBURL, THIRDPARTYAPIURL, COLUMNSTYPE, APPCONFIGURL, get_APIURL, SECONDARYEVENTURL, FILEBUCKETURL } from "../constant/constant";
import { utility } from "../utility/utilityProvider";
import { toast } from "react-toastify";
import { useTranslation } from "../../locale/useTranslation";

//default args
var defaultArgs = {
  // MULTIPLE QUERRY EX: [ ['SID', '>', '0'], ['SID', '>', '0'], ['SID', '>', '0']  ]
  // SINGLE QUERRY EX: ['SID', '>', '0']
  query: [],
  // SORT ASC EX: { SID : 1 }
  // SORT DESC EX: { SID : -1 }
  // MULTIPLE SORTS EX: { SID : 1, DATE : -1 }
  sort: { SID: 1 },
  // 1 MEANS FIRST PAGE, 2 MEANS 2 PAGE
  page: null,
  // NO. OF ITEMS PER PAGE
  perPage: null,
  //ARCHIVE KEY
  archive: false
}

const lang = useTranslation();

//use getEntitiesWithSearch instead
export async function getData(entityname, args = defaultArgs) {

  utility.checkSessionExpire();

  try {
    var finalData = {
      query: args.query || defaultArgs.query,
      sort: args.sort || defaultArgs.sort,
      page: args.page || defaultArgs.page,
      perPage: args.perPage || defaultArgs.perPage,

    };
    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };
    var FINALURL = get_APIURL(entityname) + 'getEntities/' + entityname;
    // console.log(finalData);
    const response = await fetch(FINALURL, Finalinit);
    const data = await response.json();
    return data;
  }
  catch (error) {
    console.log("error", error.message);
  }
}

//get entities function with search for server side search
export async function getEntitiesWithSearch(entityname, args = defaultArgs, columns, searchText, filters) {

  utility.checkSessionExpire();

  try {
    var finalData = {
      query: args.query || defaultArgs.query,
      sort: args.sort || defaultArgs.sort,
      page: args.page || defaultArgs.page,
      perPage: args.perPage || defaultArgs.perPage,
      archive: args.archive || defaultArgs.archive,
      dataColumns: columns || [],
      searchText: searchText || '',
      filters: filters
    };

    if (entityname == ENTITYNAME.Users) {
      const user = utility.getValue(LOCALSTORAGE_KEY.userData);
      if (user && user.RoleType) {
        finalData.query = [...finalData.query, ["RoleType.ID", ">=", user.RoleType.ID]]
      }
    }

    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };
    var FINALURL = get_APIURL(entityname) + 'getEntitiesWithSearch/' + entityname;
    console.log(finalData);
    const response = await fetch(FINALURL, Finalinit);
    const data = await response.json();



    if (data.success) {
      return data;
    }

    utility.checkTokenExpire(data);
    utility.checkIsLicenseExpire(data);

  }
  catch (error) {
    console.log("error", error.message);
  }
}

export async function getEntitiesWithSearchAndProjection(entityname, args = defaultArgs, columns, selectedColuns, searchText) {

  utility.checkSessionExpire();

  try {
    var finalData = {
      query: args.query || defaultArgs.query,
      sort: args.sort || defaultArgs.sort,
      page: args.page || defaultArgs.page,
      perPage: args.perPage || defaultArgs.perPage,
      archive: args.archive || defaultArgs.archive,
      dataColumns: columns || [],
      searchText: searchText || '',
      selectedColuns: selectedColuns || []
    };

    if (entityname == ENTITYNAME.Users) {
      const user = utility.getValue(LOCALSTORAGE_KEY.userData);
      if (user && user.RoleType) {
        finalData.query = [...finalData.query, ["RoleType.ID", ">=", user.RoleType.ID]]
      }
    }

    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };
    var FINALURL = get_APIURL(entityname) + 'getEntitiesWithSearchAndProjection/' + entityname;
    console.log(finalData);
    const response = await fetch(FINALURL, Finalinit);
    const data = await response.json();
    // if(data && data.isValidToken == false){
    //   toast.error("Session Expired. Please login again");
    //   utility.clearLocalStorage();
    //   navigate('/');
    // }
    return data;
  }
  catch (error) {
    console.log("error", error.message);
  }
}


export async function getNonLinearPlanning(payload) {

  utility.checkSessionExpire();

  try {
    var finalData = {
      data: payload
    }
    var FINALURL = PLANNINGURL.BASEURL + 'getNonLinearPlanning'

    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) }
    const response = await fetch(FINALURL, Finalinit);
    const data = await response.json();
    return data;
  }
  catch (error) {
    console.log("error", error);
  }

}

export async function getFormData(entityname, userId) {

  utility.checkSessionExpire();

  try {
    var FINALURL = APIURL.BASEURL + 'formData/' + entityname;
    // console.log(userId);
    const finalData = {
      data: userId ? userId : null
    }
    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };
    const response = await fetch(FINALURL, Finalinit);
    const resData = await response.json();
    if (resData.success) {
      console.log(resData);
      return resData.data.sort((a, b) => a.sequence - b.sequence).filter((x) => x.type != COLUMNSTYPE.hidden);
    }
    // If it success return other code otherwise check token expire or not
    utility.checkTokenExpire(resData);
  }
  catch (error) {
    console.log("error", error.message);
  }
}

export async function getEntity(entityname, SID, lookup) {
  // entityname - name of the collection you want to recieve data
  // SID - to recieve only data which has SID ex: SID = 12
  // lookup - to get link data stored in another table ex: {
  //   link_collection:'ott_assets',
  //   link_field:'ott_assets'
  // }

  utility.checkSessionExpire();

  try {
    var FINALURL = get_APIURL(entityname) + 'getEntity/' + entityname;
    var finalData = {
      query: ['SID', '=', parseInt(SID)],
      lookup: lookup
    }
    var Finalinit = { ...GetHeader('POST'), "body": JSON.stringify(finalData) }

    const response = await fetch(FINALURL, Finalinit);
    const data = await response.json();
    return data;
  }
  catch (error) {
    console.log("error", error.message);
  }
}


export async function getEntityfromAssestID(entityname, AssestID, lookup) {
  // entityname - name of the collection you want to recieve data
  // SID - to recieve only data which has SID ex: SID = 12
  // lookup - to get link data stored in another table ex: {
  //   link_collection:'ott_assets',
  //   link_field:'ott_assets'
  // }

  utility.checkSessionExpire();

  try {
    var FINALURL = APIURL.BASEURL + 'getEntity/' + entityname;
    var finalData = {
      query: ['AssetId', '=', AssestID],
      lookup: lookup
    }
    var Finalinit = { ...GetHeader('POST'), "body": JSON.stringify(finalData) }

    const response = await fetch(FINALURL, Finalinit);
    const data = await response.json();
    return data;
  }
  catch (error) {
    console.log("error", error.message);
  }
}

export async function getDataLookup(entityname, args = defaultArgs, localStorageKey = '') {

  utility.checkSessionExpire();

  try {
    var dataLookup = utility.getValue(entityname);
    if (dataLookup != null) return dataLookup;

    console.log(entityname + " feching from server");
    var finalData = {
      query: args.query,
      sort: args.sort,
      page: args.page,
      perPage: args.perPage
    };

    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };
    var FINALURL = get_APIURL(entityname) + 'getEntities/' + entityname;

    const response = await fetch(FINALURL, Finalinit);
    const data = await response.json();
    if (data.success) {
      //setting in loacl straoge
      var key = localStorageKey == '' ? entityname : localStorageKey
      utility.setValue(key, data);
      return data;
    }
    console.log(data);
    utility.checkTokenExpire(data)

  }
  catch (error) {
    console.log("error", error);
  }

}

export async function getValidChannels() {

  utility.checkSessionExpire();

  try {

    var dataLookup = utility.getValue("userValidChannels");
    if (dataLookup != null) return dataLookup;

    const user = utility.getValue(LOCALSTORAGE_KEY.userData);

    var finalData = {
      user: user || {}
    };
    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };
    var FINALURL = USERURL.BASEURL + 'getValidChannels';
    const response = await fetch(FINALURL, Finalinit);
    const data = await response.json();
    utility.setValue("userValidChannels", data);
    return data;
  }
  catch (error) {
    console.log("error", error.message);
  }
}

export async function getDataLookupData(entityname, args = defaultArgs, localStorageKey = '') {

  var res = await getDataLookup(entityname, args, localStorageKey);

  return res.data;

}

export async function saveData(entityname, saveData) {

  saveData = utility.prepareSaveData(entityname, saveData)

  try {
    const user = utility.getValue(LOCALSTORAGE_KEY.userData);
    var FINALURL = get_APIURL(entityname) + "saveData/" + entityname;

    //prepare data
    const finalData = {
      data: saveData,
      user: { _id: user?._id ?? "", name: user?.name ?? "" }
    }
    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };

    const response = await fetch(FINALURL, Finalinit);
    const responseData = await response.json();

    console.log('Saved data response');
    console.log(responseData);
    if (!responseData.success) {
      utility.checkTokenExpire(responseData);
    }
    return responseData;

  }
  catch (error) {
    console.log("error", error.message);
  }


}

export async function deleteData(entityname, id, columnName = 'SID') {

  try {
    const finalData = {
      columnName: columnName
    }
    var Finalinit = { ...GetHeader("DELETE"), "body": JSON.stringify(finalData) };

    const response = await fetch(`${APIURL.BASEURL}${entityname}/${id}`, Finalinit);
    const resData = await response.json();

    return resData;
  }
  catch (error) {
    console.log("error", error.message);
  }

}

export async function bulkUpdate(entityname, updateDataArray) {

  try {
    const user = utility.getValue(LOCALSTORAGE_KEY.userData);
    var FINALURL = APIURL.BASEURL + "bulkUpdate/" + entityname;

    //prepare data
    const finalData = {
      data: updateDataArray,
      user: { _id: user?._id ?? "", name: user?.name ?? "" }
    }
    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };

    const response = await fetch(FINALURL, Finalinit);
    const responseData = await response.json();

    console.log('Saved data response');
    console.log(responseData);
    if (!responseData.success) {
      utility.checkTokenExpire(responseData);
    }
    return responseData;

  }
  catch (error) {
    console.log("error", error.message);
  }


}

export async function bulkUpdateSelectedItems(entityname, selectedItems, updateData) {

  try {
    let FINALURL = APIURL.BASEURL + "bulkUpdateSelectedItems/" + entityname;

    //prepare data
    const finalData = {
      selectedItems: selectedItems,
      data: updateData
    }
    let Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };

    const response = await fetch(FINALURL, Finalinit);
    const responseData = await response.json();

    console.log(responseData);
    if (!responseData.success) {
      utility.checkTokenExpire(responseData);
    }
    return responseData;

  }
  catch (error) {
    console.log("error", error.message);
  }


}

export async function getAndSaveNextNumber(SID, saveData) {

  utility.checkSessionExpire();

  try {
    // http://localhost:3000/v2/data/getAndSaveNextHouseNumber/3
    var FINALURL = APIURL.BASEURL + "getAndSaveNextHouseNumber/" + SID;

    //prepare data
    const finalData = {
      data: saveData,
    }
    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };

    const response = await fetch(FINALURL, Finalinit);
    const responseData = await response.json();

    console.log('Saved data response');
    console.log(responseData);
    if (responseData.success) {
      return responseData;
    }
    utility.checkTokenExpire(responseData);
  }
  catch (error) {
    console.log("error", error.message);
  }

}

export async function getAndSaveMaterialContentNextHouseNumber(SID, saveData) {

  utility.checkSessionExpire();

  try {
    // http://localhost:3000/v2/data/getAndSaveNextHouseNumber/3
    var FINALURL = APIURL.BASEURL + "getAndSaveMaterialContentNextHouseNumber/" + SID;

    //prepare data
    const finalData = {
      data: saveData,
    }
    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };

    const response = await fetch(FINALURL, Finalinit);
    const responseData = await response.json();

    console.log('Saved data response');
    console.log(responseData);
    if (responseData.success) {
      return responseData;
    }
    utility.checkTokenExpire(responseData);

  }
  catch (error) {
    console.log("error", error.message);
  }

}

export async function createSeriesMediaEpisodes(saveData) {

  try {
    // http://localhost:3000/v2/media/createSeriesMediaEpisodes
    var FINALURL = MEDIAURL.BASEURL + "createSeriesMediaEpisodes/";

    //prepare data
    const finalData = {
      data: saveData
    }
    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };

    const response = await fetch(FINALURL, Finalinit);
    const responseData = await response.json();

    console.log('Saved data response');
    console.log(responseData);
    return responseData;
  }
  catch (error) {
    console.log("error", error.message);
  }


}

export async function getLiveEvent(title, duration) {

  utility.checkSessionExpire();

  //title = "Live"
  //duration = in millesconds

  try {
    if (title == "" || title == undefined || duration == "" || duration == undefined) {
      return { success: false, message: "Please enter title and duration", error: "Please enter title and duration" };
    }
    var FINALURL = MEDIAURL.BASEURL + "getLiveEvent";

    //prepare data
    const finalData = {
      title: title,
      duration: duration
    }
    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };

    const response = await fetch(FINALURL, Finalinit);
    const responseData = await response.json();

    console.log('Saved data response');
    console.log(responseData);
    return responseData;
  }
  catch (error) {
    console.log("error", error.message);
  }


}

export async function getBreakItemForSchedule(mediaCategory, mediaCategoryType, duration) {
  //mediaCategory 
  //mediaCategoryType
  //duration in millesconds

  utility.checkSessionExpire();

  try {

    var FINALURL = MEDIAURL.BASEURL + "getBreakItemForSchedule";

    //prepare data
    const finalData = {
      MediaCategory: mediaCategory,
      MediaCategoryType: mediaCategoryType,
      duration: duration
    }
    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };

    const response = await fetch(FINALURL, Finalinit);
    const responseData = await response.json();

    console.log('Saved data response');
    console.log(responseData);
    return responseData;
  }
  catch (error) {
    console.log("error", error.message);
  }


}


export async function createSportsEvent(eventDetail) {

  try {

    var FINALURL = MEDIAURL.BASEURL + "createSportsEvent/";

    //prepare data
    const finalData = {
      data: {
        eventDetail: eventDetail,
        content: eventDetail.Content
      }
    }
    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };

    const response = await fetch(FINALURL, Finalinit);
    const responseData = await response.json();

    console.log('Saved data response');
    console.log(response);
    return responseData;
  }
  catch (error) {
    console.log("error", error.message);
  }


}

export async function createSeriesSeasons(saveData) {

  try {
    // http://localhost:3000/v2/media/createSeriesSeasons
    var FINALURL = MEDIAURL.BASEURL + "createSeriesSeasons/";

    //prepare data
    const finalData = {
      data: saveData
    }
    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };

    const response = await fetch(FINALURL, Finalinit);
    const responseData = await response.json();

    console.log('Saved data response');
    console.log(responseData);
    return responseData;
  }
  catch (error) {
    console.log("error", error.message);
  }


}

export async function getMusicByMusicCategory(musicCategory, filterById = false) {

  try {

    var FINALURL = MEDIAURL.BASEURL + "getMusicByMusicCategory/";

    //prepare data
    const finalData = {
      filters: musicCategory,
      filterById: filterById
    }
    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };

    const response = await fetch(FINALURL, Finalinit);
    const responseData = await response.json();

    console.log('Saved data response');
    return responseData;
  }
  catch (error) {
    console.log("error", error.message);
  }


}

//reconciliation
export async function saveAsRunData(saveData) {

  try {
    // http://localhost:3000/v2/data/saveProgramScheduleReconcileDTO/saveAsRunData
    var FINALURL = RECONSILEURL.BASEURL + "saveAsRunData";

    //prepare data
    const finalData = {
      data: saveData
    }
    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };

    const response = await fetch(FINALURL, Finalinit);
    const responseData = await response.json();

    console.log('Saved data response');
    console.log(responseData);
    return responseData;
  }
  catch (error) {
    console.log("error", error.message);
  }

}

export async function saveReconciliationStatus(saveData) {

  const userData = utility.getValue(LOCALSTORAGE_KEY.userData);

  try {
    // http://localhost:3000/v2/reconciliation/saveAsRunData
    var FINALURL = RECONSILEURL.BASEURL + "saveReconciliationStatus";

    //prepare data
    const finalData = {
      data: saveData,
      User: { _id: userData?._id ?? "", name: userData?.name ?? "" },
    }
    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };

    const response = await fetch(FINALURL, Finalinit);
    const responseData = await response.json();

    console.log('Saved data response');
    console.log(responseData);
    return responseData;
  }
  catch (error) {
    console.log("error", error.message);
  }

}

export async function readAsRunData(file, channelSID) {

  try {
    var FINALURL = RECONSILEURL.BASEURL + 'readAsRunData/' + channelSID;;

    let formData = new FormData()
    formData.append('file', file);

    var Finalinit = { method: 'POST', body: formData };
    const response = await fetch(FINALURL, Finalinit);

    const responseData = await response.json();
    return responseData;
  }
  catch (error) {
    alert(error)
    console.log("error", error);
  }

}

export async function loadImportedAsRun(item) {

  // var item = {
  //   FromData : FromData,
  //   ToDate : ToDate,
  //   Channel : Channel
  // }

  utility.checkSessionExpire();

  try {
    var finalData = {
      data: item
    };
    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };
    var FINALURL = RECONSILEURL.BASEURL + "loadImportedAsRun"
    // console.log(finalData);
    const response = await fetch(FINALURL, Finalinit);
    const data = await response.json();
    return data;
  }
  catch (error) {
    console.log("error", error.message);
  }

}

export async function deleteImportedAsRunFile(fileName) {


  try {
    const finalData = {
      data: fileName
    }

    // deleteImportedAsRun
    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };
    var FINALURL = RECONSILEURL.BASEURL + "deleteImportedAsRun"
    const response = await fetch(FINALURL, Finalinit);
    const resData = await response.json();

    return resData;
  }
  catch (error) {
    console.log("error", error.message);
  }

}

export async function autoReconcilation(payload) {

  // var payload = {
  //   ScheduleDate: scheduleStart ,
  //   ScheduleEndDate: new Date(dataItem.FromDate).getTime() + offsetTime + (24 * 3600000),
  //   channelSID: dataItem.Channel.SID,
  //   isWithOutHeaders: true
  // };

  try {
    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(payload) };
    var FINALURL = RECONSILEURL.BASEURL + "autoReconcilation"
    // console.log(finalData);
    const response = await fetch(FINALURL, Finalinit);
    const data = await response.json();
    return data;
  }
  catch (error) {
    console.log("error", error.message);
  }

}


//planning
export async function getPlanning(payload) {

  try {
    var FINALURL = PLANNINGURL.BASEURL + 'getPlanning'

    var Finalinit = { ...GetHeader('POST'), "body": JSON.stringify(payload) }
    const response = await fetch(FINALURL, Finalinit);
    const data = await response.json();
    return data;
  }
  catch (error) {
    console.log("error", error);
  }

}

export async function loadPlanningMediaLibrary(searchText, filterDataItem, pagination, channels = []) {

  utility.checkSessionExpire();

  // console.log(filterDataItem);

  var finalData = {
    data: searchText,
    filter: filterDataItem,
    pagination: pagination,
    channels: channels.length > 0 ? channels.map((x) => x.SID) : []
  }

  try {
    var FINALURL = PLANNINGURL.BASEURL + 'loadPlanningMediaLibrary'

    var Finalinit = { ...GetHeader('POST'), "body": JSON.stringify(finalData) }
    const response = await fetch(FINALURL, Finalinit);
    const data = await response.json();

    if (data.success) {
      return data;
    }

    utility.checkTokenExpire(data);

  }
  catch (error) {
    console.log("error", error);
  }

}

export async function getMediaLibraryByPlanningSlot(slot_id) {

  utility.checkSessionExpire();

  console.log(slot_id);

  var finalData = {
    planningSlot_id: slot_id
  }

  try {
    var FINALURL = PLANNINGURL.BASEURL + 'getMediaLibraryByPlanningSlot'

    var Finalinit = { ...GetHeader('POST'), "body": JSON.stringify(finalData) }
    const response = await fetch(FINALURL, Finalinit);
    const data = await response.json();

    if (data.success) {
      return data;
    }

    utility.checkTokenExpire(data);

  } catch (error) {
    console.log("error", error);
  }

}

export async function getAutoPlanningPlottingDataByPlanningSlots(payload) {

  utility.checkSessionExpire();

  console.log(payload);

  try {
    var FINALURL = PLANNINGURL.BASEURL + 'getMediaLibraryDummyScheduleByPlanningSlots'

    var Finalinit = { ...GetHeader('POST'), "body": JSON.stringify(payload) }
    const response = await fetch(FINALURL, Finalinit);
    const data = await response.json();

    if (data.success) {
      return data;
    }

    utility.checkTokenExpire(data);

  } catch (error) {
    console.log("error", error);
  }

}

export async function savePlanningSlotSchedule(data) {

  utility.checkSessionExpire();
  const userData = utility.getValue(LOCALSTORAGE_KEY.userData);

  var finalData = {
    data: data,
    user: { _id: userData?._id ?? "", name: userData?.name ?? "" },
  }

  try {
    var FINALURL = PLANNINGURL.BASEURL + 'savePlanningSlotSchedule'

    var Finalinit = { ...GetHeader('POST'), "body": JSON.stringify(finalData) }
    const response = await fetch(FINALURL, Finalinit);
    const data = await response.json();

    if (data.success) {
      return data;
    }

    utility.checkTokenExpire(data);

  } catch (error) {
    console.log("error", error);
  }

}
export async function loopPlanning(fromDate, endDate, channels, dataItem) {

  // console.log(filterDataItem);
  const user = utility.getValue(LOCALSTORAGE_KEY.userData)

  var finalData = {
    fromDate: fromDate,
    endDate: endDate,
    channels: channels,
    dataItem: dataItem,
    userId: user?._id
  }

  try {
    var FINALURL = PLANNINGURL.BASEURL + 'loopPlanning'

    var Finalinit = { ...GetHeader('POST'), "body": JSON.stringify(finalData) }
    const response = await fetch(FINALURL, Finalinit);
    const data = await response.json();

    utility.checkTokenExpire(data);
    return data;

  }
  catch (error) {
    console.log("error", error);
  }

}


export async function savePlanning(saveData, allsegments = [], loadchildren = false, fetchSegmentFromDB = false, mediaEpisodeSID = 0, dayPart = null) {

  console.log(dayPart);

  try {
    const user = utility.getValue(LOCALSTORAGE_KEY.userData)
    var FINALURL = PLANNINGURL.BASEURL + 'savePlanning';

    //prepare data
    const finalData = {
      data: saveData,
      allsegments: allsegments,
      loadchildren: loadchildren,
      user: { _id: user?._id ?? "", name: user?.name ?? "" },
      fetchSegmentFromDB: fetchSegmentFromDB,
      mediaEpisodeSID: mediaEpisodeSID,
      dayPart: dayPart
    }

    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };
    const response = await fetch(FINALURL, Finalinit);

    const responseData = await response.json();
    return responseData;
  }
  catch (error) {
    alert(error)
    console.log("error", error);
  }


}

export async function ReplaceProgram(newProgram, oldProgram) {

  try {
    var FINALURL = PLANNINGURL.BASEURL + 'replaceProgram';

    //prepare data
    const finalData = {
      newProgram: newProgram,
      oldProgram: oldProgram
    }

    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };
    const response = await fetch(FINALURL, Finalinit);

    const responseData = await response.json();
    return responseData;
  }
  catch (error) {
    alert(error)
    console.log("error", error);
  }


}


export async function applyRecursion(parentappointment, recursivemodel) {

  try {

    const currentUser = utility.getValue(LOCALSTORAGE_KEY.userData);

    //prepare data
    const finalData = {
      data: parentappointment,
      recursivemodel: recursivemodel,
      user: {
        name: currentUser?.name ?? "",
        _id: currentUser?._id ?? ""
      }
    }

    let FINALURL = PLANNINGURL.BASEURL + 'applyRecursion';
    if (Object.keys(recursivemodel.RepeatPattern).length > 1) {
      FINALURL = PLANNINGURL.BASEURL + 'applyRecursionWithRepeatPattern';
    }

    let Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };
    const response = await fetch(FINALURL, Finalinit);

    const responseData = await response.json();
    return responseData;
  }
  catch (error) {
    alert(error)
    console.log("error", error);
  }


}

export async function distributePlanningAcrossChannels(parentappointment, template, pullParentAppointmentFromDb = false) {

  try {
    var FINALURL = PLANNINGURL.BASEURL + 'distributePlanningAcrossChannels';

    //prepare data
    const finalData = {
      data: {
        planningItem: parentappointment,
        planningDistributionTemplate: template,
        pullParentAppointmentFromDb: pullParentAppointmentFromDb
      }
    }

    console.log(finalData);

    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };
    const response = await fetch(FINALURL, Finalinit);

    const responseData = await response.json();
    return responseData;
  }
  catch (error) {
    alert(error)
    console.log("error", error);
  }


}

export async function deletePlanning(obj) {

  try {
    const user = utility.getValue(LOCALSTORAGE_KEY.userData)
    var FINALURL = PLANNINGURL.BASEURL + 'deletePlanning';
    var finalData = {
      data: {
        "_id": obj._id,
        "MediaDealRight_id": obj.MediaDealRight_id
      },
      user: { _id: user?._id ?? "", name: user?.name ?? "" }
    };
    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };
    const response = await fetch(FINALURL, Finalinit);
    console.log('resource deleted...');
    const responseData = await response.json();
    return responseData;
  }
  catch (error) {
    console.log("error", error);
  }

}

export async function publishUnPublishLiveEvent(payload) {

  try {
    const user = utility.getValue(LOCALSTORAGE_KEY.userData)
    var FINALURL = PLANNINGURL.BASEURL + 'publishUnPubishLiveEvent';
    var finalData = {
      data: payload,
      user: { _id: user?._id ?? "", name: user?.name ?? "" }
    };
    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };
    const response = await fetch(FINALURL, Finalinit);
    console.log('resource deleted...');
    const responseData = await response.json();
    return responseData;
  }
  catch (error) {
    console.log("error", error);
  }

}
export async function copyStrandAndPaste(obj, pasteDate) {

  try {
    const user = utility.getValue(LOCALSTORAGE_KEY.userData)
    var FINALURL = PLANNINGURL.BASEURL + 'copyStrandAndPaste';


    var finalData = {
      pasteDate: pasteDate,
      data: obj,
      user: { _id: user?._id ?? "", name: user?.name ?? "" }
    };

    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };
    const response = await fetch(FINALURL, Finalinit);
    const responseData = await response.json();
    console.log(responseData);
    return responseData;
  }
  catch (error) {
    console.log("error", error);
  }

}
export async function deletePlanningByOptions(option, obj) {

  try {
    const user = utility.getValue(LOCALSTORAGE_KEY.userData)
    var FINALURL = PLANNINGURL.BASEURL + 'deletePlanningByOptions';


    var finalData = {
      option: option,
      data: {
        "_id": obj._id,
        "SlotDateTime": obj.SlotDateTime,
        "RecursionParentProgramSchedule_id": obj.RecursionParentProgramSchedule_id,
        "MediaDealRight_id": obj.MediaDealRight_id,
        "MediaEpisode_id": obj.MediaEpisode_id,
        "ChannelId": obj.Channel._id
      },
      user: { _id: user?._id ?? "", name: user?.name ?? "" }
    };

    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };
    const response = await fetch(FINALURL, Finalinit);
    const responseData = await response.json();
    console.log(responseData);
    return responseData;
  }
  catch (error) {
    console.log("error", error);
  }

}

export async function copySchedule(payload) {
  console.log(payload);
  // {
  //   payload : {
  //     CopyFromDate : fromDate,
  //     CopyToDate : utility.addTimeToDateInMilliseconds(toDate,23,59,59,999),
  //     CopyTillDate : toDate,
  //     FromChannel : dataItem.Channel,
  //     Activity : dataItem.Activity
  // }
  // }

  try {
    var FINALURL = PLANNINGURL.BASEURL + 'copySchedule';

    var finalData = {
      data: payload
    }

    var Finalinit = { ...GetHeader('POST'), "body": JSON.stringify(finalData) }
    const response = await fetch(FINALURL, Finalinit);
    const data = await response.json();
    return data;

  } catch (error) {
    alert(error)
    console.log("error", error);
  }


}

export async function updateSegmentInPlanning(mediaEpisodeId) {

  try {
    var FINALURL = PLANNINGURL.BASEURL + 'updateSegmentInPlanning';

    //prepare data
    const finalData = {
      data: { MediaEpisode_id: mediaEpisodeId }
    }



    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };
    const response = await fetch(FINALURL, Finalinit);

    const responseData = await response.json();
    return responseData;
  }
  catch (error) {
    alert(error)
    console.log("error", error);
  }


}


export async function exportPDF(payload) {

  //payload
  // {
  //     "param": {
  //         "query": {
  //             "fromDate": 1671301800000, 
  //             "toDate": 1671706599000,
  //             "channelSID": [1,2,...],
  //             }
  //     },
  //     "slotDuration":slotDuration,
  //     "planningPreference":planningPreference,
  //     "selectedColumns":selectedColumns
  // }

  try {
    var FINALURL = PLANNINGURL.BASEURL + 'exportPDF';

    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(payload) };
    const response = await fetch(FINALURL, Finalinit);

    const responseData = await response.json();
    if (responseData.success) return responseData
    else return responseData;
  }
  catch (error) {
    alert(error)
    console.log("error", error);
  }


}
export async function exportPlanning(payload) {

  //payload
  // {
  //     "param": {
  //         "query": {
  //             "fromDate": 1671301800000, 
  //             "toDate": 1671706599000,
  //             "channelSID": [1,2,...],
  //             }
  //     },
  //     "slotDuration":slotDuration,
  //     "planningPreference":planningPreference,
  //     "selectedColumns":selectedColumns
  // }

  try {
    var FINALURL = PLANNINGURL.BASEURL + 'exportPlanning';

    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(payload) };
    const response = await fetch(FINALURL, Finalinit);

    const responseData = await response.json();
    if (responseData.success) return responseData
    else return responseData;
  }
  catch (error) {
    alert(error)
    console.log("error", error);
  }


}


export async function exportEPG(payload) {

  //payload
  // {
  //     "param": {
  //         "query": {
  //             "fromDate": 1671301800000, 
  //             "toDate": 1671706599000,
  //             "channelSID": [1,2,...],
  //             }
  //     }
  // }

  try {
    var FINALURL = PLANNINGURL.BASEURL + 'exportEPG';

    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(payload) };
    const response = await fetch(FINALURL, Finalinit);

    const responseData = await response.json();
    if (responseData.success) return responseData
    else return responseData;
  }
  catch (error) {
    alert(error)
    console.log("error", error);
  }


}
//scheduling
export async function getScheduling(payload) {

  utility.checkSessionExpire();
  //payload data:
  // ScheduleDate in ms
  // channelSID

  try {
    var FINALURL = SCHEDULINGURL.BASEURL + 'getScheduling'

    var Finalinit = { ...GetHeader('POST'), "body": JSON.stringify(payload) }
    const response = await fetch(FINALURL, Finalinit);
    const data = await response.json();
    return data;
  }
  catch (error) {
    console.log("error", error);
  }

}

export async function getSchedulingHeaders(payload) {

  utility.checkSessionExpire();
  //payload data:
  // ScheduleDate in ms
  // channelSID

  try {
    var FINALURL = SCHEDULINGURL.BASEURL + 'getSchedulingHeaders'

    var Finalinit = { ...GetHeader('POST'), "body": JSON.stringify(payload) }
    const response = await fetch(FINALURL, Finalinit);
    const data = await response.json();
    return data;
  }
  catch (error) {
    console.log("error", error);
  }

}

//scheduling
export async function getAsrunData(payload) {

  utility.checkSessionExpire();
  //payload data:
  // ScheduleDate in ms
  // channelSID

  try {
    var FINALURL = RECONSILEURL.BASEURL + 'getAsrunData'

    var Finalinit = { ...GetHeader('POST'), "body": JSON.stringify(payload) }
    const response = await fetch(FINALURL, Finalinit);
    const data = await response.json();
    return data;
  }
  catch (error) {
    console.log("error", error);
  }


}

export async function loadSchedulingMediaLibrary(searchText, filterDataItem, payload, filterByDate, pagination, isBreak = false) {

  utility.checkSessionExpire();

  var finalData = {
    data: searchText,
    filter: filterDataItem,
    payload: payload,
    filterByDate: filterByDate,
    pagination: pagination,
    isBreak: isBreak
  }

  try {
    var FINALURL = SCHEDULINGURL.BASEURL + 'loadSchedulingMediaLibrary'

    var Finalinit = { ...GetHeader('POST'), "body": JSON.stringify(finalData) }
    const response = await fetch(FINALURL, Finalinit);
    const data = await response.json();
    return data;
  }
  catch (error) {
    console.log("error", error);
  }

}

export async function loadSchedulingCompleteMediaLibrary(payload) {

  utility.checkSessionExpire();

  var finalData = {
    payload: payload,
  }

  try {
    var FINALURL = SCHEDULINGURL.BASEURL + 'loadSchedulingCompleteMediaLibrary'

    var Finalinit = { ...GetHeader('POST'), "body": JSON.stringify(finalData) }
    const response = await fetch(FINALURL, Finalinit);
    const data = await response.json();
    return data;
  }
  catch (error) {
    console.log("error", error);
  }

}

//scheduling
export async function getScheduleBookedCommercial(payload) {

  utility.checkSessionExpire();
  //payload data:
  // ScheduleDate in ms
  // channelSID

  try {
    var FINALURL = SCHEDULINGURL.BASEURL + 'loadBookedCommercials'

    var Finalinit = { ...GetHeader('POST'), "body": JSON.stringify(payload) }
    const response = await fetch(FINALURL, Finalinit);
    const data = await response.json();
    return data;
  }
  catch (error) {
    console.log("error", error);
  }


}

export async function saveScheduling(programScheduleDetailData) {

  try {
    var FINALURL = PLANNINGURL.BASEURL + 'saveScheduling';

    //prepare data
    var finalData = {
      "ProgramScheduleDetail": programScheduleDetailData
    };

    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };
    const response = await fetch(FINALURL, Finalinit);

    const responseData = await response.json();
    console.log("SAVED DATA");
    console.log(responseData);
    return responseData;
  }
  catch (error) {
    alert(error)
    console.log("error", error);
  }


}


export async function exportSchedule(payload) {

  //payload
  // {
  //     "automation": "PXF",
  //     "param": {
  //         "query": {
  //             "startDate": 1672312909684, 
  //             "endDate": 1671706599000,
  //             "channelSID": 1
  //             },
  //         "sort" :{ "SID": 1 },
  //         "tableName": "programschedule"
  //     }
  // }
  const user = utility.getValue(LOCALSTORAGE_KEY.userData)

  try {
    var finalPayload = {
      ...payload,
      user: { _id: user?._id ?? "", name: user?.name ?? "" }
    }
    var FINALURL = SCHEDULINGURL.BASEURL + 'exportSchedule';

    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalPayload) };
    const response = await fetch(FINALURL, Finalinit);

    const responseData = await response.json();
    if (responseData.success) return responseData
    else return responseData;
  }
  catch (error) {
    // alert(error)
    console.log("error", error);
  }


}

//contract

export async function updateMediaDealRights(sourceEntity, removedMedia, updatedMedia, source, isDefault = false, syncFromParent = false) {

  const user = utility.getValue(LOCALSTORAGE_KEY.userData)

  try {
    let finalData = {
      data: {
        publishingSource: source,
        sourceEntity: sourceEntity,
        removedMedia: removedMedia,
        updatedMedia: updatedMedia,
        currentUser: { _id: user?._id ?? "", name: user?.name ?? "", roletypeid: user?.RoleType?.ID ?? ROLETYPE.Admin },
        isDefault: isDefault,
        syncFromParent: syncFromParent
      }
    }
    let FINALURL = CONTRACTURL.BASEURL + 'updateMediaDealRights';
    let Finalinit = { ...GetHeader('POST'), "body": JSON.stringify(finalData) }
    const response = await fetch(FINALURL, Finalinit);
    const data = await response.json();
    return data;

  } catch (error) {
    alert(error)
    console.log("error", error);
  }


}

//Booking
export async function loadBookingData(payload) {

  utility.checkSessionExpire();

  //Payload
  // {
  //   "channelSID":1,
  //   "BookingDate":6000,

  // }

  try {
    var FINALURL = BOOKINGURL.BASEURL + 'loadBookingData';

    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(payload) };
    const response = await fetch(FINALURL, Finalinit);

    const responseData = await response.json();
    if (responseData.success) return responseData
    else return responseData;
  }
  catch (error) {
    alert(error)
    console.log("error", error);
  }


}

export async function saveBooking(data, days, campaignNo, currentBookingDetails) {

  try {
    var FINALURL = BOOKINGURL.BASEURL + 'saveBooking';

    //prepare data
    const finalData = {
      data: data,
      days: days,
      campaignNo: campaignNo,
      currentBookingDetails: currentBookingDetails
    }

    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };
    const response = await fetch(FINALURL, Finalinit);

    const responseData = await response.json();
    if (responseData.success) return responseData
    else return responseData;
  }
  catch (error) {
    console.log("error", error);
  }


}

export async function saveYearlyBooking(data, days, campaignNo, currentBookingDetails) {

  try {
    var FINALURL = BOOKINGURL.BASEURL + 'saveYearlyBooking';

    //prepare data
    const finalData = {
      data: data,
      days: days,
      campaignNo: campaignNo,
      currentBookingDetails: currentBookingDetails
    }

    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };
    const response = await fetch(FINALURL, Finalinit);

    const responseData = await response.json();
    if (responseData.success) return responseData
    else return responseData;
  }
  catch (error) {
    console.log("error", error);
  }


}

export async function updateBookingLineNumber(data, days) {

  try {
    var FINALURL = BOOKINGURL.BASEURL + "updateBookingLineNumber"

    //prepare data
    const finalData = {
      data: data,
      days: days
    }
    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };

    const response = await fetch(FINALURL, Finalinit);
    const responseData = await response.json();

    console.log('Saved data response');
    console.log(responseData);
    return responseData;
  }
  catch (error) {
    console.log("error", error.message);
  }


}


export async function deleteBookingLine(data) {

  try {
    var FINALURL = BOOKINGURL.BASEURL + "deleteBookingLine"

    //prepare data
    const finalData = {
      data: data
    }
    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };

    const response = await fetch(FINALURL, Finalinit);
    const responseData = await response.json();

    console.log('Saved data response');
    console.log(responseData);
    return responseData;
  }
  catch (error) {
    console.log("error", error.message);
  }

}

export async function getBookedDataByLineNumber(payload) {

  utility.checkSessionExpire();

  try {
    var FINALURL = BOOKINGURL.BASEURL + 'getBookedDataByLineNumber';

    console.log(payload);

    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(payload) };
    const response = await fetch(FINALURL, Finalinit);

    const responseData = await response.json();
    if (responseData.success) return responseData
    else return responseData;
  }
  catch (error) {
    alert(error)
    console.log("error", error);
  }
}

export async function updateBookingSpotMakeGoodPurge(ids, makeGood, purge) {

  const user = utility.getValue(LOCALSTORAGE_KEY.userData);
  try {
    var FINALURL = BOOKINGURL.BASEURL + "updateBookingSpotMakeGoodPurge"

    //prepare data
    const finalData = {
      booking_ids: ids,
      makeGood: makeGood ?? false,
      purge: purge ?? false,
      user: { _id: user?._id ?? "", name: user?.name ?? "" }
    }
    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };

    const response = await fetch(FINALURL, Finalinit);
    const responseData = await response.json();

    console.log('Saved data response');
    console.log(responseData);
    return responseData;
  }
  catch (error) {
    console.log("error", error.message);
  }

}

//media
export async function saveMedia(payload) {

  try {
    var FINALURL = MEDIAURL.BASEURL + 'saveMedia';

    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(payload) };
    const response = await fetch(FINALURL, Finalinit);

    const responseData = await response.json();
    console.log("SAVED DATA");
    console.log(responseData);
    return responseData;
  }
  catch (error) {
    alert(error)
    console.log("error", error);
  }


}

export async function canChangeCommercialDuration(mediaEpsiode) {

  try {
    var FINALURL = MEDIAURL.BASEURL + 'canChangeCommercialDuration';

    const finalData = {
      data: mediaEpsiode
    }

    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };
    const response = await fetch(FINALURL, Finalinit);

    const responseData = await response.json();
    console.log(responseData);
    return responseData;
  }
  catch (error) {
    alert(error)
    console.log("error", error);
  }
}

export async function onChangeArchiveInSeriesSeason(payload) {

  // {
  //     "_id" : "64b138b6b15d3caeb816c3e1",
  //     "archive" : false,
  //     "entityName" : "season"
  // }

  console.log(payload);
  const user = utility.getValue(LOCALSTORAGE_KEY.userData)

  try {
    var FINALURL = MEDIAURL.BASEURL + 'onChangeArchiveInSeriesSeason';

    var finalData = {
      ...payload,
      user: { _id: user?._id ?? "", name: user?.name ?? "" }
    }

    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };
    const response = await fetch(FINALURL, Finalinit);

    const responseData = await response.json();
    if (responseData.success) return responseData
    else return responseData;
  }
  catch (error) {
    console.log("error", error);
  }
}

export async function getRepeatTypeLiveEventSegment(payload) {

  try {
    var FINALURL = MEDIAURL.BASEURL + 'getRepeatTypeLiveEventSegment';

    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(payload) };
    const response = await fetch(FINALURL, Finalinit);

    const responseData = await response.json();
    console.log("SAVED DATA");
    console.log(responseData);
    return responseData;
  }
  catch (error) {
    alert(error)
    console.log("error", error);
  }


}




export async function getApplicationConfiguration() {

  try {
    var FINALURL = APPCONFIGURL.BASEURL + "getApplicationConfiguration";
    var Finalinit = { ...GetHeader() };
    const response = await fetch(FINALURL, Finalinit);

    const responseData = await response.json();

    return responseData;
  }
  catch (error) {
    alert(error)
    console.log("error", error);
  }


}

export async function uploadImage(imageData) {

  try {
    let FINALURL = FILEBUCKETURL.BASEURL + 'UploadFile';

    let formData = new FormData()
    formData.append('file', imageData)

    let Finalinit = { ...GetHeader('POST', true), body: formData };
    let response = await fetch(FINALURL, Finalinit);
    if (response.status === 413) {
      return { success: false, message: "The file you're trying to upload is too large. Please choose a smaller file." }
    }
    let responseData = await response.json();
    return responseData;
  }
  catch (error) {
    console.log("error", error);
    return { success: false, message: error.message }
  }

}

export async function UploadFileInChunks(chunk, chunkIndex, totalChunks, fileName, data) {

  try {
    let FINALURL = FILEBUCKETURL.BASEURL + 'UploadFileInChunks';

    const formData = new FormData();
    formData.append('chunk', chunk);
    formData.append('chunkIndex', chunkIndex);
    formData.append('totalChunks', totalChunks);
    formData.append('fileName', fileName);
    formData.append('data', data);

    let Finalinit = { ...GetHeader('POST', true), body: formData };
    let response = await fetch(FINALURL, Finalinit);
    let responseData = await response.json();
    return responseData;
  }
  catch (error) {
    console.log("error", error);
    return { success: false, message: error.message }
  }

}

// Example payload for getReport
// {
//   "reportType": "XLSX", // can be = {"CSV","XML","XLSX"}
//   "param": {"query": ["SID","=", 3092],"sort" :{ "SID": 1 },"tableName": "programschedule"}
// }
export async function getReport(payload) {

  utility.checkSessionExpire();

  try {
    var FINALURL = FILEURL.BASEURL + 'getReport';

    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(payload) };
    const response = await fetch(FINALURL, Finalinit);

    const responseData = await response.json();
    if (responseData.success) return responseData
    else return responseData;
  }
  catch (error) {
    alert(error)
    console.log("error", error);
  }

}

export async function exportSamsungEPG(payload) {

  utility.checkSessionExpire();

  try {
    var FINALURL = PLANNINGURL.BASEURL + 'exportSamsungEPG';

    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(payload) };
    const response = await fetch(FINALURL, Finalinit);

    const responseData = await response.json();
    if (responseData.success) return responseData
    else return responseData;
  }
  catch (error) {
    alert(error)
    console.log("error", error);
  }

}

export async function exportTooberEPG(payload) {

  utility.checkSessionExpire();

  try {
    var FINALURL = PLANNINGURL.BASEURL + 'exportTooberEPG';

    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(payload) };
    const response = await fetch(FINALURL, Finalinit);

    const responseData = await response.json();
    if (responseData.success) return responseData
    else return responseData;
  }
  catch (error) {
    alert(error)
    console.log("error", error);
  }

}

//cms

export async function getLayout() {

  utility.checkSessionExpire();

  try {
    var FINALURL = CMSURL.BASEURL + 'getLayout';
    var Finalinit = { ...GetHeader() };

    const response = await fetch(FINALURL, Finalinit);

    const responseData = await response.json();

    return responseData;
  }
  catch (error) {
    alert(error)
    console.log("error", error);
  }


}

export async function getLayout3() {

  utility.checkSessionExpire();

  try {
    var FINALURL = CMSURL.BASEURL + 'getLayout3';
    var Finalinit = { ...GetHeader() };

    const response = await fetch(FINALURL, Finalinit);

    const responseData = await response.json();

    return responseData;
  }
  catch (error) {
    alert(error)
    console.log("error", error);
  }


}

//page
export async function getPage(SID) {

  utility.checkSessionExpire();

  try {
    var FINALURL = PAGEURL.BASEURL + 'getPage';

    //prepare data
    var finalData = {
      "SID": SID
    };

    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };
    const response = await fetch(FINALURL, Finalinit);
    const responseData = await response.json();

    return responseData;
  }
  catch (error) {
    alert(error.message)
    console.log("error", error);
  }


}

export async function savePage(pageData) {

  try {
    var FINALURL = PAGEURL.BASEURL;

    //prepare data
    var finalData = {
      "data": pageData
    };

    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };
    const response = await fetch(FINALURL, Finalinit);
    const responseData = await response.json();

    return responseData;
  }
  catch (error) {
    alert(error.message)
    console.log("error", error);
  }


}

export async function exportXML(data) {

  try {
    var FINALURL = FILEURL.BASEURL + 'exportXML';

    //prepare data
    var finalData = {
      data: data
    };

    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };

    const response = await fetch(FINALURL, Finalinit);
    const responseData = await response.json();
    return responseData;

  }
  catch (error) {
    alert(error.message)
    console.log("error", error);
  }

}


export async function saveSchedule(saveData) {

  const user = utility.getValue(LOCALSTORAGE_KEY.userData)

  try {
    var FINALURL = SCHEDULINGURL.BASEURL + 'saveSchedule';

    //prepare data
    const finalData = {
      data: saveData,
      user: { _id: user?._id ?? "", name: user?.name ?? "" }
    }

    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };
    const response = await fetch(FINALURL, Finalinit);

    const responseData = await response.json();
    return responseData;
  }
  catch (error) {
    alert(error)
    console.log("error", error);
  }


}

export async function getVariation(payload) {

  try {
    var FINALURL = SCHEDULINGURL.BASEURL + 'getVariation';
    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(payload) };
    const response = await fetch(FINALURL, Finalinit);

    const responseData = await response.json();
    return responseData;
  }
  catch (error) {
    console.log("error", error);
  }

}


export async function saveUserPreference(saveData) {

  try {
    var FINALURL = USERURL.BASEURL + 'saveUserPreference';

    //prepare data
    const finalData = {
      data: saveData
    }

    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };
    const response = await fetch(FINALURL, Finalinit);

    const responseData = await response.json();
    return responseData;
  }
  catch (error) {
    alert(error)
    console.log("error", error);
  }


}

export async function saveAdminUserPreferenceToNewUser(userId) {

  try {
    var FINALURL = USERURL.BASEURL + 'saveAdminUserPreferenceToNewUser';

    //prepare data
    const finalData = {
      userId: userId
    }

    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };
    const response = await fetch(FINALURL, Finalinit);

    const responseData = await response.json();
    return responseData;
  }
  catch (error) {
    alert(error)
    console.log("error", error);
  }


}

export async function exportCSV(data) {

  try {
    var FINALURL = FILEURL.BASEURL + 'exportCSV';

    //prepare data
    var finalData = {
      data: data
    };

    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };

    const response = await fetch(FINALURL, Finalinit);
    const responseData = await response.json();
    return responseData;

  }
  catch (error) {
    alert(error.message)
    console.log("error", error);
  }

}

export async function deleteEntities(tableName, data) {

  try {
    var FINALURL = APIURL.BASEURL + tableName;

    //prepare data
    var finalData = {
      data: data
    };

    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };

    const response = await fetch(FINALURL, Finalinit);
    const responseData = await response.json();
    return responseData;

  }
  catch (error) {
    alert(error.message)
    console.log("error", error);
  }

}

export async function deleteLogHistory() {

  try {
    var FINALURL = DELETELOGURL.BASEURL;

    var Finalinit = { ...GetHeader("POST") };

    const response = await fetch(FINALURL, Finalinit);
    const responseData = await response.json();
    return responseData;

  }
  catch (error) {
    alert(error.message)
    console.log("error", error);
  }

}

export async function deleteDataByQuerry(tableName, query) {

  try {
    var FINALURL = APIURL.BASEURL + 'deleteData/' + tableName;

    //prepare data
    var finalData = {
      query: query
    };

    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };

    const response = await fetch(FINALURL, Finalinit);
    const responseData = await response.json();
    return responseData;

  }
  catch (error) {
    alert(error.message)
    console.log("error", error);
  }

}


export async function getContentDetailsFromPartner(args) {

  utility.checkSessionExpire();

  try {
    // var finalData = {
    //   searchtext:args.SearchTextValue
    // };

    var Finalinit = { ...GetHeader(args.RequestType) };
    //Finalinit.mode= 'no-cors'; 
    // var FINALURL = APIURL.BASEURL + 'getImdbDetails?searchtext='+args.SearchTextValue;
    // var FINALURL = args.Url + '?'+args.SearchText+'='+args.SearchTextValue;
    var FINALURL = args.Url + args.QueryString;
    // console.log(FINALURL);
    const response = await fetch(FINALURL, Finalinit);
    const data = await response.json();
    return data;
  }
  catch (error) {
    console.log("error", error.message);
  }
}


//page
export async function readFile(file, headerRowIndex = 0, sheetIndex = 0) {

  if (headerRowIndex < 0 || sheetIndex < 0) {
    return { success: false, message: "Invalid headerRowIndex or sheetIndex", data: { data: undefined } }
  }

  var ext = file.name.split('.').pop().toLowerCase();

  var url = FILETYPEURL[ext];
  //console.log();
  try {
    var FINALURL = FILEURL.BASEURL + url;
    let formData = new FormData()
    formData.append('file', file);
    formData.append('headerRowIndex', headerRowIndex);
    formData.append('sheetIndex', sheetIndex);

    var Finalinit = { method: 'POST', body: formData };
    const response = await fetch(FINALURL, Finalinit);
    const responseData = await response.json();

    return responseData;
  }
  catch (error) {
    //alert(error.message)
    console.log("error", error);
    return { success: false, message: error.message, data: { data: undefined } }

  }


}

export async function getExcelSheets(file) {

  try {
    var FINALURL = FILEURL.BASEURL + 'getExcelSheets';
    let formData = new FormData()
    formData.append('file', file);

    var Finalinit = { method: 'POST', body: formData };
    const response = await fetch(FINALURL, Finalinit);
    const responseData = await response.json();

    return responseData;
  }
  catch (error) {
    //alert(error.message)
    console.log("error", error);
    return { success: false, message: error.message, data: { data: undefined } }

  }

}


export async function readWhatsOnPlaylist(file) {
  try {
    var FINALURL = SCHEDULINGURL.BASEURL + 'readWhatsOnPlaylist';
    let formData = new FormData()
    formData.append('file', file);

    var Finalinit = { method: 'POST', body: formData };
    const response = await fetch(FINALURL, Finalinit);
    const responseData = await response.json();

    return responseData;
  }
  catch (error) {
    //alert(error.message)
    console.log("error", error);
    data = { data: undefined }
    return { success: false, message: error.message, data: data }

  }


}

export async function importWhatsOnPlaylist(payload) {

  try {
    var FINALURL = SCHEDULINGURL.BASEURL + 'importWhatsOnPlaylist';

    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(payload) };
    const response = await fetch(FINALURL, Finalinit);

    const responseData = await response.json();
    if (responseData.success) return responseData
    else return responseData;
  }
  catch (error) {
    alert(error)
    console.log("error", error);
  }

}

export async function readBookingPdf(file) {

  try {
    var FINALURL = BOOKINGURL.BASEURL + 'readBookingPdf';
    let formData = new FormData()
    formData.append('file', file);

    var Finalinit = { method: 'POST', body: formData };
    const response = await fetch(FINALURL, Finalinit);
    const responseData = await response.json();

    return responseData;
  }
  catch (error) {
    //alert(error.message)
    console.log("error", error);
    data = { data: undefined }
    return { success: false, message: error.message, data: data }

  }


}



export async function importPlanning(planningData, canReplace, createMedia = false) {

  try {
    const currentUser = utility.getValue(LOCALSTORAGE_KEY.userData);
    let FINALURL = PLANNINGURL.BASEURL + 'importPlanning';

    const finalData = {
      data: planningData,
      canReplace: canReplace,
      createMedia: createMedia,
      userId: currentUser?._id ?? ''
    }

    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };
    const response = await fetch(FINALURL, Finalinit);

    const responseData = await response.json();
    return responseData;

  }
  catch (error) {
    //alert(error.message)
    console.log("error", error);
  }


}

export async function createSegmentsBasedOnFormatsAttached(attachedFormats, MediaEpisodeSID) {

  try {
    var FINALURL = MEDIAURL.BASEURL + 'createSegmentsBasedOnFormatsAttached';

    //prepare data
    const finalData = {
      data: { MediaEpisodeSID: MediaEpisodeSID, Formats: attachedFormats }
    }

    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };
    const response = await fetch(FINALURL, Finalinit);

    const responseData = await response.json();
    return responseData;
  }
  catch (error) {
    alert(error)
    console.log("error", error);
  }


}

export async function createFastSegments(payload) {

  try {
    var FINALURL = MEDIAURL.BASEURL + 'createFastSegments';

    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(payload) };
    const response = await fetch(FINALURL, Finalinit);

    const responseData = await response.json();
    return responseData;
  }
  catch (error) {
    alert(error)
    console.log("error", error);
  }


}
export async function updateSeasonEpisodeCount(payload) {

  try {
    var FINALURL = MEDIAURL.BASEURL + 'updateSeasonEpisodeCount';

    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(payload) };
    const response = await fetch(FINALURL, Finalinit);

    const responseData = await response.json();
    return responseData;
  }
  catch (error) {
    alert(error)
    console.log("error", error);
  }


}

export async function getDummyMedia(payload) {

  try {
    var FINALURL = MEDIAURL.BASEURL + 'getDummyMedia';

    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(payload) };
    const response = await fetch(FINALURL, Finalinit);

    const responseData = await response.json();
    return responseData;
  }
  catch (error) {
    alert(error)
    console.log("error", error);
  }


}

export async function insertProgramGroup(payload) {

  const user = utility.getValue(LOCALSTORAGE_KEY.userData)

  try {
    let FINALURL = PLANNINGURL.BASEURL + 'insertProgramGroup';
    let finalData = {
      ...payload,
      user: { _id: user?._id ?? "", name: user?.name ?? "" }
    }

    let Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };
    const response = await fetch(FINALURL, Finalinit);

    const responseData = await response.json();
    return responseData;
  }
  catch (error) {
    alert(error)
    console.log("error", error);
  }


}

//campaign
export async function getCampaignBookedSpots(campaign_id, filter) {

  utility.checkSessionExpire();

  try {
    var FINALURL = BOOKINGURL.BASEURL + 'getCampaignBookedSpots';

    const finalData = {
      CampaignID: campaign_id,
      filter: filter
    }
    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };
    const response = await fetch(FINALURL, Finalinit);

    const responseData = await response.json();
    return responseData;


  }
  catch (error) {
    //alert(error.message)
    console.log("error", error);
  }


}

export async function deleteScheduledSpots(ids) {

  try {
    var FINALURL = BOOKINGURL.BASEURL + 'deleteScheduledSpots';

    const finalData = {
      scheduleSpotIds: ids
    }
    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };
    const response = await fetch(FINALURL, Finalinit);

    const responseData = await response.json();
    return responseData;


  }
  catch (error) {
    //alert(error.message)
    console.log("error", error);
  }


}

//data migration

export async function ImportMediaManagement(payload, type) {

  //Payload
  // {
  //   "type" : "mediaepsiode"
  //   "data": gridData,
  // }

  try {
    var FINALURL = '';

    if (type.Description == 'Segment') {
      FINALURL = DATAMIGRATIONURL.BASEURL + 'importSegmentation';
    }
    else if (type.Description == 'Playlist') {
      FINALURL = PLANNINGURL.BASEURL + 'importPlaylist';
    }
    else {
      FINALURL = DATAMIGRATIONURL.BASEURL + 'importMediaManagement';
    }

    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(payload) };
    const response = await fetch(FINALURL, Finalinit);

    const responseData = await response.json();
    if (responseData.success) return responseData
    else return responseData;
  }
  catch (error) {
    alert(error)
    console.log("error", error);
  }

}

export async function ImportMediaManagementDataMigration(payload, type) {

  //Payload
  // {
  //   "type" : "mediaepsiode"
  //   "data": gridData,
  // }

  console.log(type);

  try {
    var FINALURL = '';

    if (type.Description == 'Publishing Rights') {
      FINALURL = DATAMIGRATIONURL.BASEURL + 'importSegmentation'; //importPublishingRights
    }
    else if (type.Description == 'Segment') {
      FINALURL = DATAMIGRATIONURL.BASEURL + 'importSegmentation';
    }
    else if (type.Description == 'BTS Segmentation') {
      FINALURL = DATAMIGRATIONURL.BASEURL + 'importBTSSegmentation';
    }
    else if (type.Description == 'Playlist') {
      FINALURL = PLANNINGURL.BASEURL + 'importPlaylist';
    }
    else if (type.Description == 'Series') {
      FINALURL = DATAMIGRATIONURL.BASEURL + 'importSeries';
    }
    else if (type.Description == 'Season') {
      FINALURL = DATAMIGRATIONURL.BASEURL + 'importSeasons';
    }
    else if (type.Description == 'Episode') {
      FINALURL = DATAMIGRATIONURL.BASEURL + 'importEpisodes';
    }
    else if (type.Description == 'Movie' || type.Description == 'Program') {
      FINALURL = DATAMIGRATIONURL.BASEURL + 'importMovieProgram';
    }
    else // working for interstitial,commercial,filler,music
    {
      FINALURL = DATAMIGRATIONURL.BASEURL + 'importInterstialsCommercialFillerMusic';
    }

    console.log(FINALURL)

    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(payload) };
    const response = await fetch(FINALURL, Finalinit);

    const responseData = await response.json();
    if (responseData.success) return responseData
    else return responseData;
  }
  catch (error) {
    alert(error)
    console.log("error", error);
  }

}

export async function importOttAsset(payload) {

  try {
    var FINALURL = OTTURL.BASEURL + 'importOttAssets';
    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(payload) };
    const response = await fetch(FINALURL, Finalinit);

    const responseData = await response.json();
    return responseData;
  }
  catch (error) {
    console.log("error", error);
  }

}

export async function importPlaylistIngestion(payload) {

  try {
    var FINALURL = SCHEDULINGURL.BASEURL + 'importPlaylistIngestion';
    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(payload) };
    const response = await fetch(FINALURL, Finalinit);

    const responseData = await response.json();
    return responseData;
  }
  catch (error) {
    console.log("error", error);
  }

}

export async function importAgencyClient(payload, type) {

  try {
    var FINALURL = '';

    if (type.SID == INGESTION_MODULETYPES.Agency) {
      FINALURL = DATAMIGRATIONURL.BASEURL + 'importAgency';
    } else {
      FINALURL = DATAMIGRATIONURL.BASEURL + 'importClient';
    }

    console.log(FINALURL)

    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(payload) };
    const response = await fetch(FINALURL, Finalinit);

    const responseData = await response.json();
    if (responseData.success) return responseData
    else return responseData;
  }
  catch (error) {
    alert(error)
    console.log("error", error);
  }

}

export async function importOPTADetails(payload) {

  try {
    var FINALURL = DATAMIGRATIONURL.BASEURL + 'importOPTADetails';

    console.log(FINALURL)

    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(payload) };
    const response = await fetch(FINALURL, Finalinit);

    const responseData = await response.json();
    if (responseData.success) return responseData
    else return responseData;
  }
  catch (error) {
    alert(error)
    console.log("error", error);
  }

}

export async function readPlannerGridCSV(file) {

  try {
    var FINALURL = FILEURL.BASEURL + 'readPlannerGridCSV';
    let formData = new FormData()
    formData.append('file', file);

    var Finalinit = { method: 'POST', body: formData };
    const response = await fetch(FINALURL, Finalinit);
    const responseData = await response.json();

    return responseData;
  }
  catch (error) {
    //alert(error.message)
    console.log("error", error);
    return { success: false, message: error.message, data: { data: undefined } }

  }


}


export async function readScheduleBssFile(file) {

  try {
    var FINALURL = FILEURL.BASEURL + 'readScheduleBssFile';
    let formData = new FormData()
    formData.append('file', file);

    var Finalinit = { method: 'POST', body: formData };
    const response = await fetch(FINALURL, Finalinit);
    const responseData = await response.json();

    return responseData;
  }
  catch (error) {
    //alert(error.message)
    console.log("error", error);
    return { success: false, message: error.message, data: { data: undefined } }

  }


}




export async function SaveLogs(logData) {

  if (!utility.getApplicationConfigurationByKey(ApplicationConfigurationKeys.isLogEnabled) ?? false) {
    return;
  }

  const userData = utility.getValue(LOCALSTORAGE_KEY.userData);

  try {
    const payload =
    {
      "data":
      {
        "Event": logData.event,
        "Message": logData.message,
        "User": { _id: userData?._id ?? "", name: userData?.name ?? "" },
        "Module": logData.module,
        "Value": logData.data,
        "Entity_id": logData._id ?? logData.data?._id ?? "",
      }
    }

    var FINALURL = LOGGERURL.BASEURL + 'savelogs';

    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(payload) };
    const response = await fetch(FINALURL, Finalinit);

    const responseData = await response.json();
    if (responseData.success) return responseData
    else return responseData;
  }
  catch (error) {
    console.log("error", error);
  }

}

export async function SaveProgramScheduleHeaderLogs(logData) {

  if (!utility.getApplicationConfigurationByKey(ApplicationConfigurationKeys.isLogEnabled) ?? false) {
    return;
  }

  const userData = utility.getValue(LOCALSTORAGE_KEY.userData);

  try {

    const prepareData = {
      _id: logData?.data?._id,
      SID: logData?.data?.SID,
      Channel: { _id: logData?.data?.Channel?._id, SID: logData?.data?.Channel?.SID, FullChannelName: logData?.data?.Channel?.FullChannelName },
      MediaEpisode_id: logData?.data?.MediaEpisode_id,
      SegmentDuration: logData?.data?.mediaEpisode?.Duration ?? 0,
      ScheduleDate: logData?.data?.ScheduleDate,
      SlotDateTime: logData?.data?.SlotDateTime,
      Segment_id: logData?.data?.Segment_id,
      ParentProgramSchedule_id: logData?.dat?.ParentProgramSchedule_id,
      IsValid: logData?.data?.IsValid,
      MediaDealRight_id: logData?.data?.MediaDealRight_id,
      ExhibitionNumber: logData?.data?.ExhibitionNumber,
      RepeatNumber: logData?.data?.RepeatNumber,
      PlanningType: logData?.data?.PlanningType,
      InvalidReason: logData?.data?.InvalidReason,
      customTitle: logData?.data?.customTitle ?? '',
      customDuration: logData?.data?.customDuration ?? 0,
      mediaEpisode: {
        _id: logData?.data?.mediaEpisode?._id,
        SID: logData?.data?.mediaEpisode?.SID,
        Title: logData?.data?.mediaEpisode?.Title,
        Description: logData?.data?.mediaEpisode?.Description,
        AssetId: logData?.data?.mediaEpisode?.AssetId,
        Duration: logData?.data?.mediaEpisode?.Duration,
        EpisodeNumber: logData?.data?.mediaEpisode?.EpisodeNumber,
      }
    }

    const payload = {
      "data": {
        "Event": logData.event,
        "Message": logData.message,
        "User": { _id: userData?._id ?? "", name: userData?.name ?? "" },
        "Module": logData.module,
        "Value": prepareData,
        "Entity_id": logData._id ?? prepareData?._id ?? "",
        "Header_id": logData._id ?? prepareData?._id ?? "",
        "MediaEpisode_id": logData._id ?? prepareData.mediaEpisode?._id ?? "",
      }
    }

    console.log(payload);

    let FINALURL = LOGGERURL.BASEURL + 'saveProgramScheduleHeaderLogs';

    let Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(payload) };
    const response = await fetch(FINALURL, Finalinit);

    const responseData = await response.json();
    if (responseData.success) return responseData
    else return responseData;
  }
  catch (error) {
    console.log("error", error);
  }

}

export async function getCount(entityname, query = "") {

  utility.checkSessionExpire();

  try {

    var FINALURL = APIURL.BASEURL + 'getCount/' + entityname + "?" + query;
    var Finalinit = { ...GetHeader() };
    const response = await fetch(FINALURL, Finalinit);
    const responseData = await response.json();
    return responseData;
  }
  catch (error) {
    alert(error)
    console.log("error", error);
  }
}


export async function loadBillingData(billingData) {

  utility.checkSessionExpire();

  try {
    var FINALURL = BILLINGURL.BASEURL + 'loadBillingData';

    const finalData = {
      data: billingData
    }
    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };
    const response = await fetch(FINALURL, Finalinit);

    const responseData = await response.json();
    // console.log(responseData);
    return responseData;


  }
  catch (error) {
    //alert(error.message)
    console.log("error", error);
  }


}

export async function loadGeneratedBills(query) {

  utility.checkSessionExpire();

  try {
    var FINALURL = BILLINGURL.BASEURL + 'loadGeneratedBills';

    const finalData = {
      data: query
    }
    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };
    const response = await fetch(FINALURL, Finalinit);

    const responseData = await response.json();
    // console.log(responseData);
    return responseData;


  }
  catch (error) {
    //alert(error.message)
    console.log("error", error);
  }


}



export async function getInvoiceDetails(billingData) {

  utility.checkSessionExpire();

  try {
    var FINALURL = BILLINGURL.BASEURL + 'getInvoiceDetails';

    const finalData = {
      data: billingData
    }
    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };
    const response = await fetch(FINALURL, Finalinit);

    const responseData = await response.json();
    // console.log(responseData);
    return responseData;


  }
  catch (error) {
    //alert(error.message)
    console.log("error", error);
  }

}


export async function saveBills(billingData) {


  try {
    var FINALURL = BILLINGURL.BASEURL + 'saveBills';

    const finalData = {
      data: billingData
    }
    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };
    const response = await fetch(FINALURL, Finalinit);

    const responseData = await response.json();
    // console.log(responseData);
    return responseData;


  }
  catch (error) {
    //alert(error.message)
    console.log("error", error);
  }


}


export async function getInvoiceTelecastDetails(billingData) {

  utility.checkSessionExpire();

  try {
    var FINALURL = BILLINGURL.BASEURL + 'getInvoiceTelecastDetails';

    const finalData = {
      data: billingData
    }
    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };
    const response = await fetch(FINALURL, Finalinit);

    const responseData = await response.json();
    // console.log(responseData);
    return responseData;


  }
  catch (error) {
    //alert(error.message)
    console.log("error", error);
  }

}

export async function getSecondaryEventStyleSheetsWithPrototypes(styleSheetList) {

  utility.checkSessionExpire();

  try {
    var FINALURL = SCHEDULINGURL.BASEURL + 'getSecondaryEventStyleSheetsWithPrototypes';

    const finalData = {
      _ids: styleSheetList.map((x) => { return x._id })
    }
    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };
    const response = await fetch(FINALURL, Finalinit);

    const responseData = await response.json();
    return responseData;


  }
  catch (error) {
    //alert(error.message)
    console.log("error", error);
  }

}


export async function getCampaignOverAllDetails(billingData) {

  utility.checkSessionExpire();

  try {
    var FINALURL = BILLINGURL.BASEURL + 'getCampaignOverAllDetails';
    const finalData = {
      data: billingData
    }
    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };
    const response = await fetch(FINALURL, Finalinit);

    const responseData = await response.json();
    // console.log(responseData);
    return responseData;


  }
  catch (error) {
    //alert(error.message)
    console.log("error", error);
  }

}




//Dashboard
export async function getCMSDashBoardView() {

  utility.checkSessionExpire();

  try {
    var FINALURL = BOOKINGSUMMARYURL.BASEURL + 'getCMSDashBoardView'
    var Finalinit = { ...GetHeader('POST'), "body": JSON.stringify({ data: '' }) }
    const response = await fetch(FINALURL, Finalinit);
    const data = await response.json();
    return data;
  }
  catch (error) {
    console.log("error", error);
  }

}

export async function getTop10Agency(payload) {

  utility.checkSessionExpire();

  try {
    var FINALURL = BOOKINGSUMMARYURL.BASEURL + 'getTop10Agency'
    var Finalinit = { ...GetHeader('POST'), "body": JSON.stringify(payload) }
    const response = await fetch(FINALURL, Finalinit);
    const data = await response.json();
    if (data.length < 0) {
      toast.error(`${lang.no_data_found_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    return data;
  }
  catch (error) {
    console.log("error", error);
  }

}

export async function getTop10Client(payload) {
  try {
    var FINALURL = BOOKINGSUMMARYURL.BASEURL + 'getTop10Client'
    var Finalinit = { ...GetHeader('POST'), "body": JSON.stringify(payload) }
    const response = await fetch(FINALURL, Finalinit);
    const data = await response.json();
    return data;
  }
  catch (error) {
    console.log("error", error);
  }
}

export async function getRevenueByLocation(payload) {
  try {
    var FINALURL = BOOKINGSUMMARYURL.BASEURL + 'getRevenueByLocation'
    var Finalinit = { ...GetHeader('POST'), "body": JSON.stringify(payload) }
    const response = await fetch(FINALURL, Finalinit);
    const data = await response.json();
    return data;
  }
  catch (error) {
    console.log("error", error);
  }
}

export async function getBookingSummaryDashboardData(payload) {
  try {
    var FINALURL = BOOKINGSUMMARYURL.BASEURL + 'getBookingSummaryDashboardData'
    var Finalinit = { ...GetHeader('POST'), "body": JSON.stringify(payload) }
    const response = await fetch(FINALURL, Finalinit);
    const data = await response.json();
    return data;
  }
  catch (error) {
    console.log("error", error);
  }
}


export async function getBookingByDealType(payload) {
  try {
    var FINALURL = BOOKINGSUMMARYURL.BASEURL + 'getBookingByDealType'
    var Finalinit = { ...GetHeader('POST'), "body": JSON.stringify(payload) }
    const response = await fetch(FINALURL, Finalinit);
    const data = await response.json();
    return data;
  }
  catch (error) {
    console.log("error", error);
  }
}

export async function getSalesTargetvsAchived(payload) {
  try {
    var FINALURL = BOOKINGSUMMARYURL.BASEURL + 'getSalesTargetvsAchived'
    var Finalinit = { ...GetHeader('POST'), "body": JSON.stringify(payload) }
    const response = await fetch(FINALURL, Finalinit);
    const data = await response.json();
    return data;
  }
  catch (error) {
    console.log("error", error);
  }
}

export async function getContentCategories(contetnCategoryId = null) {
  try {
    var FINALURL = MEDIAURL.BASEURL + 'getContentCategories'
    var Finalinit = { ...GetHeader('POST'), body: JSON.stringify({ contetnCategoryId: contetnCategoryId }) }
    const response = await fetch(FINALURL, Finalinit);
    const data = await response.json();
    return data;
  }
  catch (error) {
    console.log("error", error);
  }
}

export async function getMediaFromContentCategory(contetnCategoryId = null) {
  try {
    var FINALURL = MEDIAURL.BASEURL + 'getMediaFromContentCategory'
    var Finalinit = { ...GetHeader('POST'), body: JSON.stringify({ contetnCategoryId: contetnCategoryId }) }
    const response = await fetch(FINALURL, Finalinit);
    const data = await response.json();
    return data;
  }
  catch (error) {
    console.log("error", error);
  }
}

export async function getTotalAndActiveCount(payload) {

  utility.checkSessionExpire();

  try {
    var FINALURL = BOOKINGSUMMARYURL.BASEURL + 'getTotalAndActiveCount'
    var Finalinit = { ...GetHeader('POST'), "body": JSON.stringify(payload) }
    const response = await fetch(FINALURL, Finalinit);
    const data = await response.json();
    return data;
  }
  catch (error) {
    console.log("error", error);
  }
}


export async function getPendingVsOnAirBooking(payload) {

  utility.checkSessionExpire();

  try {
    var FINALURL = BOOKINGSUMMARYURL.BASEURL + 'getPendingVsOnAirBooking'
    var Finalinit = { ...GetHeader('POST'), "body": JSON.stringify(payload) }
    const response = await fetch(FINALURL, Finalinit);
    const data = await response.json();
    return data;

  }
  catch (error) {
    console.log("error", error);
  }
}

export async function printBill(billingDataToPrint) {

  try {
    var FINALURL = BILLINGURL.BASEURL + 'printBill';

    const finalData = {
      data: billingDataToPrint
    }
    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };
    const response = await fetch(FINALURL, Finalinit);
    const responseData = await response.json();
    // console.log(responseData);
    return responseData;


  }
  catch (error) {
    //alert(error.message)
    console.log("error", error);
  }
}

export async function getMonthylRevenue(payload) {

  utility.checkSessionExpire();

  try {
    var FINALURL = BOOKINGSUMMARYURL.BASEURL + 'getMonthylRevenue'
    var Finalinit = { ...GetHeader('POST'), "body": JSON.stringify(payload) }
    const response = await fetch(FINALURL, Finalinit);
    const data = await response.json();
    return data;

  }
  catch (error) {
    console.log("error", error);
  }
}



export async function ImportPlaylist(payload, type) {

  //Payload
  // {
  //   "type" : "mediaepsiode"
  //   "data": gridData,
  // }

  try {
    // WINDOW TO WEB
    // PLANNINGURL.BASEURL + 'importPlaylist';

    var FINALURL = SCHEDULINGURL.BASEURL + 'importPlaylist';

    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(payload) };
    const response = await fetch(FINALURL, Finalinit);

    const responseData = await response.json();
    if (responseData.success) return responseData
    else return responseData;
  }
  catch (error) {
    alert(error)
    console.log("error", error);
  }

}



export async function getAndSaveNextInvoiceNumber(billingData) {

  utility.checkSessionExpire();

  try {
    var FINALURL = BILLINGURL.BASEURL + 'getAndSaveNextInvoiceNumber';

    const finalData = {
      data: billingData
    }
    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };
    const response = await fetch(FINALURL, Finalinit);

    const responseData = await response.json();
    // console.log(responseData);
    return responseData;


  }
  catch (error) {
    //alert(error.message)
    console.log("error", error);
  }


}



export async function exportBooking(bookingData, months, years, isFromYearlyBooking = false) {

  try {
    var FINALURL = BOOKINGURL.BASEURL + 'exportBooking';

    const finalData = {
      data: bookingData,
      isFromYearlyBooking: isFromYearlyBooking,
      months: months,
      years: years
    }

    console.log(FINALURL);
    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };
    const response = await fetch(FINALURL, Finalinit);

    const responseData = await response.json();
    // console.log(responseData);
    return responseData;


  }
  catch (error) {
    //alert(error.message)
    console.log("error", error);
  }


}

// Program Schedule Clash data filter
export async function getProgramScheduleClash(payload) {

  utility.checkSessionExpire();

  try {
    var FINALURL = SCHEDULINGURL.BASEURL + 'getProgramScheduleClash'
    var Finalinit = { ...GetHeader('POST'), "body": JSON.stringify(payload) }
    const response = await fetch(FINALURL, Finalinit);
    const data = await response.json();
    return data;

  }
  catch (error) {
    console.log("error", error);
  }
}


export async function getMakeGoodSpotData() {

  utility.checkSessionExpire();

  try {
    var FINALURL = BOOKINGURL.BASEURL + '/getMakeGoodSpotData';
    console.log(FINALURL)
    var Finalinit = { ...GetHeader() };
    console.log(Finalinit)
    const response = await fetch(FINALURL, Finalinit);
    const responseData = await response.json();
    console.log(responseData)
    return responseData;
  }
  catch (error) {
    console.log("error", error);
  }
}

export async function getRecentFiveJobs() {
  try {
    console.log(BOOKINGSUMMARYURL.BASEURL)
    var FINALURL = BOOKINGSUMMARYURL.BASEURL + '/getRecentFiveJobs';
    var Finalinit = { ...GetHeader() };
    const response = await fetch(FINALURL, Finalinit);
    const responseData = await response.json();
    console.log(responseData)
    return responseData;
  }
  catch (error) {
    console.log("error", error);
  }
}

export async function getSegmentCollectionData() {
  utility.checkSessionExpire();
  try {
    var FINALURL = MONETIZATION.BASEURL + 'getSegmentCollectionData'
    var Finalinit = { ...GetHeader('POST'), "body": JSON.stringify() }
    const response = await fetch(FINALURL, Finalinit);
    const data = await response.json();
    console.log(data);
    return data;

  }
  catch (error) {
    console.log("error", error);
  }
}

export async function getSubscribers(args = defaultArgs, columns, searchText, filters) {

  utility.checkSessionExpire();

  try {
    var finalData = {
      query: args.query || defaultArgs.query,
      sort: args.sort || defaultArgs.sort,
      page: args.page || defaultArgs.page,
      perPage: args.perPage || defaultArgs.perPage,
      archive: args.archive || defaultArgs.archive,
      dataColumns: columns || [],
      searchText: searchText || '',
      filters: filters
    };

    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };
    var FINALURL = MONETIZATION.BASEURL + 'getSubscribers';
    console.log(finalData);
    const response = await fetch(FINALURL, Finalinit);
    const data = await response.json();

    if (data.success) {
      return data;
    }

    utility.checkTokenExpire(data);
    utility.checkIsLicenseExpire(data);

  }
  catch (error) {
    console.log("error", error.message);
  }
}

export async function updateSubscriberPackage(payload) {

  utility.checkSessionExpire();

  try {
    var FINALURL = OTTURL.BASEURL + 'updateSubscriberPackage'
    var Finalinit = { ...GetHeader('POST'), "body": JSON.stringify(payload) }
    const response = await fetch(FINALURL, Finalinit);
    const data = await response.json();
    return data;

  }
  catch (error) {
    console.log("error", error);
  }
}



export async function changeOldPassword(payload) {
  try {
    var FINALURL = USERURL.BASEURL + 'changeOldPassword'
    var Finalinit = { ...GetHeader('POST'), "body": JSON.stringify(payload) }
    const response = await fetch(FINALURL, Finalinit);
    const data = await response.json();
    console.log(data);
    return data;

  }
  catch (error) {
    console.log("error", error);
  }
}

//WORK ORDER
export async function getUserWiseWorkOrder() {

  utility.checkSessionExpire();

  try {
    var FINALURL = WORKORDERURL.BASEURL + '/getUserWiseWorkOrder';
    var Finalinit = { ...GetHeader() };

    const response = await fetch(FINALURL, Finalinit);

    const responseData = await response.json();

    return responseData;
  }
  catch (error) {
    alert(error)
    console.log("error", error);
  }

}

export async function getRoleWiseWorkOrder() {

  utility.checkSessionExpire();

  try {
    var FINALURL = WORKORDERURL.BASEURL + '/getRoleWiseWorkOrder';
    var Finalinit = { ...GetHeader() };

    const response = await fetch(FINALURL, Finalinit);

    const responseData = await response.json();

    return responseData;
  }
  catch (error) {
    alert(error)
    console.log("error", error);
  }

}

export async function createWorkOrder(payload, workflow) {
  try {
    const user = utility.getValue(LOCALSTORAGE_KEY.userData);
    var FINALURL = WORKORDERURL.BASEURL + 'createWorkOrder'

    //prepare data
    const finalData = {
      data: payload,
      workflow: workflow,
      user: { _id: user?._id ?? "", name: user?.name ?? "" }
    }
    var Finalinit = { ...GetHeader('POST'), "body": JSON.stringify(finalData) }
    const response = await fetch(FINALURL, Finalinit);
    const data = await response.json();
    return data;

  }
  catch (error) {
    console.log("error", error);
  }
}


export async function globalSearch(searchText) {
  try {

    var FINALURL = APIURL.BASEURL + 'globalSearch/' + searchText;
    var Finalinit = { ...GetHeader() };
    const response = await fetch(FINALURL, Finalinit);
    const responseData = await response.json();
    return responseData;
  }
  catch (error) {
    alert(error)
    console.log("error", error);
  }
}

//LIVE EVENT
export async function getLiveEventsTournamentMatches(tournamentId, filter) {
  utility.checkSessionExpire();

  try {
    var FINALURL = LIVEEVENTURL.BASEURL + '/getLiveEventsTournamentMatches';

    //prepare data
    const finalData = {
      tournamentId: tournamentId,
      filter: filter
    }

    var Finalinit = { ...GetHeader('POST'), "body": JSON.stringify(finalData) }

    const response = await fetch(FINALURL, Finalinit);

    const responseData = await response.json();

    return responseData;
  }
  catch (error) {
    alert(error)
    console.log("error", error);
  }

}

export async function getLiveEventsTournamentData() {
  utility.checkSessionExpire();

  try {
    var FINALURL = LIVEEVENTURL.BASEURL + '/getLiveEventsTournamentData';
    var Finalinit = { ...GetHeader() };

    const response = await fetch(FINALURL, Finalinit);

    const responseData = await response.json();

    return responseData;
  }
  catch (error) {
    alert(error)
    console.log("error", error);
  }

}

export async function scheduleMatch(payload) {

  try {
    var FINALURL = LIVEEVENTURL.BASEURL + '/scheduleMatch';

    //prepare data
    const finalData = {
      payload: payload,
    }

    var Finalinit = { ...GetHeader('POST'), "body": JSON.stringify(finalData) }

    const response = await fetch(FINALURL, Finalinit);

    const responseData = await response.json();

    return responseData;
  }
  catch (error) {
    alert(error)
    console.log("error", error);
  }

}

export async function exportLiveEventCSV(payload) {

  try {
    var FINALURL = LIVEEVENTURL.BASEURL + '/exportLiveEventCSV';

    //prepare data
    const finalData = {
      payload: payload,
    }

    var Finalinit = { ...GetHeader('POST'), "body": JSON.stringify(finalData) }

    const response = await fetch(FINALURL, Finalinit);

    const responseData = await response.json();

    return responseData;
  }
  catch (error) {
    alert(error)
    console.log("error", error);
  }

}

// manage content
export async function getMediaEpisodeFromManageContent(args) {
  utility.checkSessionExpire();
  // {
  //     fromDate: "1686043000659",
  //     toDate: "1686043000659",
  //     platformSID: 0,   0 = All
  //     regionSID: 0,     0 = All
  // }
  try {
    // var finalData = {
    //   fromDate: args.fromDate,
    //   toDate: args.toDate,
    //   platformSID: args.platformSID,
    //   regionSID: args.regionSID,
    // };
    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(args) };
    var FINALURL = MANAGECONTENTURL.BASEURL + 'getMediaEpisodeFromManageContent';
    // console.log(finalData);
    const response = await fetch(FINALURL, Finalinit);
    const data = await response.json();
    return data;
  }
  catch (error) {
    console.log("error", error.message);
  }
}

export async function getManageContentExport(args) {

  // {
  //     fromDate: "1686043000659",
  //     toDate: "1686043000659",
  //     platformSID: 0,   0 = All
  //     regionSID: 0,     0 = All
  // }
  try {
    // var finalData = {
    //   fromDate: args.fromDate,
    //   toDate: args.toDate,
    //   platformSID: args.platformSID,
    //   regionSID: args.regionSID,
    // };
    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(args) };
    var FINALURL = MANAGECONTENTURL.BASEURL + 'getManageContentExport';
    // console.log(finalData);
    const response = await fetch(FINALURL, Finalinit);

    const responseData = await response.json();
    console.log(responseData);
    if (responseData.success) return responseData
    else return responseData;

    // const data = await response.json();
    // return data;

  }
  catch (error) {
    console.log("error", error.message);
  }

}

export async function getBookingScheduleStatus(payload) {
  try {
    var FINALURL = BOOKINGSUMMARYURL.BASEURL + 'getBookingScheduleStatus'
    var Finalinit = { ...GetHeader('POST'), "body": JSON.stringify(payload) }
    const response = await fetch(FINALURL, Finalinit);
    const data = await response.json();
    if (data.length < 0) {
      toast.error(`${lang.no_data_found_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    return data;
  }
  catch (error) {
    console.log("error", error);
  }
}

export async function getBookingStatus(payload) {

  utility.checkSessionExpire();

  try {
    var FINALURL = BOOKINGSUMMARYURL.BASEURL + 'getBookingStatus'
    var Finalinit = { ...GetHeader('POST'), "body": JSON.stringify(payload) }
    const response = await fetch(FINALURL, Finalinit);
    const data = await response.json();
    if (data.length < 0) {
      toast.error(`${lang.no_data_found_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    return data;
  }
  catch (error) {
    console.log("error", error);
  }
}

export async function getMediaHistory(media, isFromContract = false, segementId) {

  utility.checkSessionExpire();

  try {
    var FINALURL = MEDIAURL.BASEURL + 'getMediaHistory';

    const finaldata = { media: media, isFromContract: isFromContract, segementId: segementId };
    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finaldata) };
    const response = await fetch(FINALURL, Finalinit);
    const data = await response.json();
    return data;
  }
  catch (error) {
    console.log("error", error.message);
  }
}

export async function getInterstitialHistoryReport(payload) {
  utility.checkSessionExpire();
  try {
    var FINALURL = REPORTSURL.BASEURL + 'getInterstitialHistoryReport'
    var Finalinit = { ...GetHeader('POST'), "body": JSON.stringify(payload) }
    const response = await fetch(FINALURL, Finalinit);
    const data = await response.json();
    if (data.length < 0) {
      toast.error(`${lang.no_data_found_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    return data;
  }
  catch (error) {
    console.log("error", error);
  }
}

export async function getClientAgencyBookingReport(payload) {
  try {
    console.log(payload)
    var FINALURL = REPORTSURL.BASEURL + 'getClientAgencyBookingReport'
    var Finalinit = { ...GetHeader('POST'), "body": JSON.stringify(payload) }
    const response = await fetch(FINALURL, Finalinit);
    const data = await response.json();

    if (data.length < 0) {
      toast.error(`${lang.no_data_found_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    return data;
  }
  catch (error) {
    console.log("error", error);
  }
}

export async function getMissingMediaReport(payload) {
  try {
    var FINALURL = REPORTSURL.BASEURL + 'getMissingMediaReport'
    var Finalinit = { ...GetHeader('POST'), "body": JSON.stringify(payload) }
    const response = await fetch(FINALURL, Finalinit);
    const data = await response.json();

    if (data.length < 0) {
      toast.error(`${lang.no_data_found_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    return data;
  }
  catch (error) {
    console.log("error", error);
  }
}

export async function getCampaignsExistingBookingDetails(campaignNo) {
  utility.checkSessionExpire();

  try {
    var FINALURL = BOOKINGURL.BASEURL + 'getCampaignsExistingBookingDetails';

    //prepare data
    const finalData = {
      campaignNo: campaignNo
    }

    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };
    const response = await fetch(FINALURL, Finalinit);

    const responseData = await response.json();
    if (responseData.success) return responseData
    else return responseData;
  }
  catch (error) {
    console.log("error", error);
  }
}

export async function getCampaignMaxLineNo(campaignNo) {

  utility.checkSessionExpire();

  try {
    var FINALURL = BOOKINGURL.BASEURL + 'getCampaignMaxLineNo';

    const finalData = {
      campaignNo: campaignNo
    }

    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };
    const response = await fetch(FINALURL, Finalinit);

    const responseData = await response.json();
    if (responseData.success) return responseData
    else return responseData;
  }
  catch (error) {
    console.log("error", error);
  }
}

export async function linkMediaAndJobs(media, job) {

  try {
    var FINALURL = MEDIAURL.BASEURL + 'linkMediaAndJobs';

    //prepare data
    const finalData = {
      media: media,
      job: job
    }

    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };
    const response = await fetch(FINALURL, Finalinit);

    const responseData = await response.json();
    if (responseData.success) return responseData
    else return responseData;
  }
  catch (error) {
    console.log("error", error);
  }
}

export async function createMultipleEpisodeVersions(payload) {

  try {
    var FINALURL = MEDIAURL.BASEURL + 'createMultipleEpisodeVersions';

    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(payload) };
    const response = await fetch(FINALURL, Finalinit);

    const responseData = await response.json();
    if (responseData.success) return responseData
    else return responseData;
  }
  catch (error) {
    console.log("error", error);
  }
}

//ott

export async function getAssets(args = defaultArgs, columns, searchText, filters, assetType) {

  utility.checkSessionExpire();

  try {
    var finalData = {
      query: args.query || defaultArgs.query,
      sort: args.sort || defaultArgs.sort,
      page: args.page || defaultArgs.page,
      perPage: args.perPage || defaultArgs.perPage,
      archive: args.archive || defaultArgs.archive,
      dataColumns: columns || [],
      searchText: searchText || '',
      filters: filters,
      assetType: assetType
    };

    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };
    var FINALURL = OTTURL.BASEURL + 'getAssets';
    console.log(finalData);
    const response = await fetch(FINALURL, Finalinit);
    const data = await response.json();
    return data;
  }
  catch (error) {
    console.log("error", error.message);
  }
}

export async function onPublishSeason(id) {

  const user = utility.getValue(LOCALSTORAGE_KEY.userData)

  try {
    var FINALURL = OTTURL.BASEURL + 'onPublishSeason';

    var finalData = {
      seasonId: id,
      user: { _id: user?._id ?? "", name: user?.name ?? "" }
    }

    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };
    const response = await fetch(FINALURL, Finalinit);

    const responseData = await response.json();
    if (responseData.success) return responseData
    else return responseData;
  }
  catch (error) {
    console.log("error", error);
  }
}

export async function getTop5SalesExecutive(payload) {
  try {
    var FINALURL = BOOKINGSUMMARYURL.BASEURL + 'getTop5SalesExecutive'
    var Finalinit = { ...GetHeader('POST'), "body": JSON.stringify(payload) }
    const response = await fetch(FINALURL, Finalinit);
    const data = await response.json();
    if (data.length < 0) {
      toast.error(` should not be less than zero`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    return data;
  }
  catch (error) {
    console.log("error", error);
  }
}
export async function printTelecastInPdf(telecastDataInPdf) {
  try {
    console.log(telecastDataInPdf)
    var FINALURL = BILLINGURL.BASEURL + 'printTelecastInPdf';
    console.log(telecastDataInPdf)
    const finalData = {
      data: telecastDataInPdf
    }
    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };
    const response = await fetch(FINALURL, Finalinit);
    const responseData = await response.json();
    return responseData;
  }
  catch (error) {
    console.log("error", error);
  }
}
export async function printTelecastInExcel(telecastDataInExcel) {
  try {
    console.log(telecastDataInExcel)
    var FINALURL = BILLINGURL.BASEURL + 'printTelecastInExcel';
    console.log(telecastDataInExcel)
    const finalData = {
      data: telecastDataInExcel
    }
    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };
    const response = await fetch(FINALURL, Finalinit);
    const responseData = await response.json();
    return responseData;
  }
  catch (error) {
    console.log("error", error);
  }
}


export async function cancelInvoice(generatedBill_id) {

  try {
    var FINALURL = BILLINGURL.BASEURL + 'cancelInvoice';

    const finalData = {
      data: generatedBill_id
    }
    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };
    const response = await fetch(FINALURL, Finalinit);

    const responseData = await response.json();
    // console.log(responseData);
    return responseData;


  }
  catch (error) {
    //alert(error.message)
    console.log("error", error);
  }

}

// Filter Data collection Campaign
export async function getCampaign(payload) {

  utility.checkSessionExpire();

  try {
    var FINALURL = BOOKINGURL.BASEURL + 'getCampaign';

    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(payload) };
    const response = await fetch(FINALURL, Finalinit);

    const responseData = await response.json();
    return responseData;


  }
  catch (error) {
    //alert(error.message)
    console.log("error", error);
  }

}

// Filter Data collection Campaign
export async function setAsDefault(entityname, dataID) {

  try {
    var FINALURL = APIURL.BASEURL + 'setAsDefault/0';
    const finalData = {
      entityName: entityname,
      dataID: dataID
    }
    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };
    const response = await fetch(FINALURL, Finalinit);

    const responseData = await response.json();
    return responseData;

  }
  catch (error) {
    //alert(error.message)
    console.log("error", error);
  }
}

export async function getAmazonMECXML(exportDataItem) {
  try {
    var FINALURL = AMAZONMMCMEFXML.BASEURL + 'getAmazonMECXML';
    //prepare data
    var finalData = {
      data: exportDataItem
    };
    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };
    const response = await fetch(FINALURL, Finalinit);
    const responseData = await response.json();
    return responseData;
  }
  catch (error) {
    alert(error.message)
    console.log("error", error);
  }
}
export async function getAmazonMMCXML(exportDataItem) {
  try {
    var FINALURL = AMAZONMMCMEFXML.BASEURL + 'getAmazonMMCXML';
    //prepare data
    var finalData = {
      data: exportDataItem
    };
    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };
    const response = await fetch(FINALURL, Finalinit);
    const responseData = await response.json();
    return responseData;
  }
  catch (error) {
    alert(error.message)
    console.log("error", error);
  }
}

export async function getPrePostlogReportData(payload, isPreLogReport) {
  try {
    console.log(payload)
    var payload = {
      data: payload,
      isPreLogReport: isPreLogReport
    }
    var FINALURL = REPORTSURL.BASEURL + 'getPrePostlogReportData'
    var Finalinit = { ...GetHeader('POST'), "body": JSON.stringify(payload) }
    const response = await fetch(FINALURL, Finalinit);
    const data = await response.json();

    if (data.length < 0) {
      toast.error(`${lang.no_data_found_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    return data;
  }
  catch (error) {
    console.log("error", error);
  }
}


export async function ImportSalesData(payload, type) {

  console.log(type);

  try {
    var FINALURL = '';

    if (type.Description == 'BossWindowSalesExecutives') {
      FINALURL = DATAMIGRATIONURL.BASEURL + 'importSalesExecutives';
    }
    else if (type.Description == 'BossWindowAgency') {
      FINALURL = DATAMIGRATIONURL.BASEURL + 'importAgency';
    }

    else if (type.Description == 'BossWindowClient') {
      FINALURL = DATAMIGRATIONURL.BASEURL + 'importClient';
    }
    else {
      FINALURL = DATAMIGRATIONURL.BASEURL + 'importCampaign';
    }

    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(payload) };
    const response = await fetch(FINALURL, Finalinit);

    const responseData = await response.json();
    if (responseData.success) return responseData
    else return responseData;
  }
  catch (error) {
    alert(error)
    console.log("error", error);
  }

}


export async function getCreditLineReport(payload) {
  try {
    console.log(payload)
    var FINALURL = REPORTSURL.BASEURL + 'getCreditLineReport'
    var Finalinit = { ...GetHeader('POST'), "body": JSON.stringify(payload) }
    const response = await fetch(FINALURL, Finalinit);
    const data = await response.json();

    if (data.length < 0) {
      toast.error(`${lang.no_data_found_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    return data;
  }
  catch (error) {
    console.log("error", error);
  }
}

//Server heath page 
export async function getServerHealth() {

  utility.checkSessionExpire();

  try {
    var FINALURL = SERVERHEALTHURL.BASEURL + '/';
    var Finalinit = { ...GetHeader() };

    const response = await fetch(FINALURL, Finalinit);

    const responseData = await response.json();

    return responseData;
  }
  catch (error) {
    alert(error)
    console.log("error", error);
  }
}

export async function getPurgeSpotReport(payload) {
  try {
    console.log(payload)
    var FINALURL = REPORTSURL.BASEURL + 'getPurgeSpotReport'
    var Finalinit = { ...GetHeader('POST'), "body": JSON.stringify(payload) }
    const response = await fetch(FINALURL, Finalinit);
    const data = await response.json();

    if (data.length < 0) {
      toast.error(`${lang.no_data_found_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    return data;
  }
  catch (error) {
    console.log("error", error);
  }
}


export async function printBillInTextFormat(billingDataToPrint) {

  try {
    var FINALURL = BILLINGURL.BASEURL + 'printBillInTextFormat';

    const finalData = {
      data: billingDataToPrint
    }
    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };
    const response = await fetch(FINALURL, Finalinit);
    const responseData = await response.json();
    // console.log(responseData);
    return responseData;


  }
  catch (error) {
    //alert(error.message)
    console.log("error", error);
  }
}


export async function getAirTimeConsumptionReport(payload) {
  try {
    console.log(payload)
    var FINALURL = REPORTSURL.BASEURL + 'getAirTimeConsumptionReport'
    var Finalinit = { ...GetHeader('POST'), "body": JSON.stringify(payload) }
    const response = await fetch(FINALURL, Finalinit);
    const data = await response.json();

    if (data.length < 0) {
      toast.error(`${lang.no_data_found_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    return data;
  }
  catch (error) {
    console.log("error", error);
  }
}

export async function getAndSavePrototypeContentId(SID, saveData) {

  utility.checkSessionExpire();

  try {
    var FINALURL = APIURL.BASEURL + "getAndSavePrototypeContentId/" + SID;

    const finalData = {
      data: saveData,
    }
    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };

    const response = await fetch(FINALURL, Finalinit);
    const responseData = await response.json();
    return responseData;
  }
  catch (error) {
    console.log("error", error.message);
  }
}

export async function getUserByPlanData() {

  utility.checkSessionExpire();

  try {
    var FINALURL = MONETIZATION.BASEURL + 'getUserByPlanData'
    var Finalinit = { ...GetHeader('POST'), "body": JSON.stringify() }
    const response = await fetch(FINALURL, Finalinit);
    const data = await response.json();
    console.log(data);
    return data;
  }
  catch (error) {
    console.log("error", error);
  }
}

export async function attachFormats(payload) {

  try {
    var FINALURL = MEDIAURL.BASEURL + 'attachFormats'
    const finalData = {
      data: payload
    }
    var Finalinit = { ...GetHeader('POST'), "body": JSON.stringify(finalData) }
    const response = await fetch(FINALURL, Finalinit);
    const data = await response.json();
    console.log(data);
    return data;
  }
  catch (error) {
    console.log("error", error);
  }
}

export async function exportGridExcel(gridData, dataColumns) {

  try {
    var FINALURL = FILEURL.BASEURL + 'exportGridExcel'
    const finalData = {
      data: gridData,
      columns: dataColumns,
    }
    var Finalinit = { ...GetHeader('POST'), "body": JSON.stringify(finalData) }
    const response = await fetch(FINALURL, Finalinit);
    const data = await response.json();
    console.log(data);
    return data;
  }
  catch (error) {
    console.log("error", error);
  }
}

export async function getContractRunReport(media) {
  console.log(media);
  try {
    var FINALURL = MEDIAURL.BASEURL + 'getContractRunReport';

    const finaldata = { media: media };
    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finaldata) };
    const response = await fetch(FINALURL, Finalinit);
    const data = await response.json();
    return data;
  }
  catch (error) {
    console.log("error", error.message);
  }
}

export async function getBookingScheduleAndPendingCount(payload) {
  try {
    var FINALURL = BOOKINGSUMMARYURL.BASEURL + 'getBookingScheduleAndPendingCount'
    var Finalinit = { ...GetHeader('POST'), "body": JSON.stringify(payload) }
    const response = await fetch(FINALURL, Finalinit);
    const data = await response.json();
    if (data.length < 0) {
      toast.error(`${lang.no_data_found_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    return data;
  }
  catch (error) {
    console.log("error", error);
  }
}

export async function getCampaignPendingApproval() {
  try {
    var FINALURL = BOOKINGURL.BASEURL + 'getCampaignPendingApproval'
    var Finalinit = { ...GetHeader() }
    const response = await fetch(FINALURL, Finalinit);
    const data = await response.json();
    return data;
  }
  catch (error) {
    console.log("error", error);
  }
}


export async function readBookingTemplateFile(file) {

  //console.log();
  try {
    var FINALURL = FILEURL.BASEURL + 'readBookingTemplateFile';
    let formData = new FormData()
    formData.append('file', file);

    var Finalinit = { method: 'POST', body: formData };
    const response = await fetch(FINALURL, Finalinit);
    const responseData = await response.json();

    return responseData;
  }
  catch (error) {
    //alert(error.message)
    console.log("error", error);
    return { success: false, message: error.message, data: { data: undefined } }

  }


}

export async function getBillingSummaryReport(payload) {
  try {
    console.log(payload)
    var FINALURL = REPORTSURL.BASEURL + 'getBillingSummaryReport'
    var Finalinit = { ...GetHeader('POST'), "body": JSON.stringify(payload) }
    const response = await fetch(FINALURL, Finalinit);
    const data = await response.json();
    if (data.length < 0) {
      toast.error(`${lang.no_data_found_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    return data;
  }
  catch (error) {
    console.log("error", error);
  }
}

export async function exportBookingScheduleStatus(bookingSchedule, headerDates) {
  try {
    var FINALURL = REPORTSURL.BASEURL + 'exportBookingScheduleStatus'
    var finalData = {
      data: bookingSchedule.map(x => { return { ...x, bookingdatestring: moment(new Date(x.bookingdate)).format("DD-MMM-YYYY") } }),
      dates: headerDates
    }
    console.log(finalData);
    var Finalinit = { ...GetHeader('POST'), "body": JSON.stringify(finalData) }
    const response = await fetch(FINALURL, Finalinit);
    const data = await response.json();
    return data;
  }
  catch (error) {
    console.log("error", error);
  }
}

export async function getShowCreditLineReportData(payload) {
  try {
    var FINALURL = REPORTSURL.BASEURL + 'getShowCreditLineReportData'
    var Finalinit = { ...GetHeader('POST'), "body": JSON.stringify(payload) }
    const response = await fetch(FINALURL, Finalinit);
    const data = await response.json();

    return data;
  }
  catch (error) {
    console.log("error", error);
  }
}

export async function isCreditAvaliable(selectedCampaign, currentAmount) {
  try {
    var FINALURL = BOOKINGURL.BASEURL + 'isCreditAvaliable'
    var finalData = {
      data: selectedCampaign,
      currentAmount: currentAmount
    }
    console.log(finalData);
    var Finalinit = { ...GetHeader('POST'), "body": JSON.stringify(finalData) }
    const response = await fetch(FINALURL, Finalinit);
    const data = await response.json();
    return data;
  }
  catch (error) {
    console.log("error", error);
  }
}

export async function getScheduledAnnouncement(filterQuery) {

  utility.checkSessionExpire();

  try {
    var FINALURL = BOOKINGURL.BASEURL + 'getScheduledAnnouncement'
    var finalData = {
      data: filterQuery
    }
    var Finalinit = { ...GetHeader('POST'), "body": JSON.stringify(finalData) }
    const response = await fetch(FINALURL, Finalinit);
    const data = await response.json();
    return data;
  }
  catch (error) {
    console.log("error", error);
  }
}

export async function getTopNews() {
  utility.checkSessionExpire();
  try {
    var FINALURL = OTTURL.BASEURL + 'getTopNews'
    var Finalinit = { ...GetHeader('POST'), "body": JSON.stringify() }
    const response = await fetch(FINALURL, Finalinit);
    const data = await response.json();
    if (data.length < 0) {
      toast.error(`${lang.no_data_found_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    return data;
  }
  catch (error) {
    console.log("error", error);
  }
}

export async function getMediaMasterReport(filters, download = false) {
  try {
    let finalData = {
      filters: filters,
      download: download
    }

    var FINALURL = REPORTSURL.BASEURL + 'getMediaMasterReport';
    var Finalinit = { ...GetHeader('POST'), "body": JSON.stringify(finalData) };
    const response = await fetch(FINALURL, Finalinit);
    const data = await response.json();
    if (data.success) {
      return data;
    }
    utility.checkTokenExpire(data);
  }
  catch (error) {
    console.log("error", error);
  }
}


export async function exportBookingStatus(bookingData, headerDates) {
  try {
    var FINALURL = REPORTSURL.BASEURL + 'exportBookingStatus'
    var finalData = {
      data: bookingData,
      dates: headerDates
    }
    console.log(finalData);
    var Finalinit = { ...GetHeader('POST'), "body": JSON.stringify(finalData) }
    const response = await fetch(FINALURL, Finalinit);
    const data = await response.json();
    return data;
  }
  catch (error) {
    console.log("error", error);
  }
}

export async function getRailsWithCampaign(args = defaultArgs, columns, searchText, isActiveRails = RAIL_STATUS.All) {

  utility.checkSessionExpire();

  try {
    var finalData = {
      query: args.query || defaultArgs.query,
      sort: args.sort || defaultArgs.sort,
      page: args.page || defaultArgs.page,
      perPage: args.perPage || defaultArgs.perPage,
      archive: args.archive || defaultArgs.archive,
      dataColumns: columns || [],
      searchText: searchText || '',
      isActive: isActiveRails
    };

    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };
    var FINALURL = OTTURL.BASEURL + 'getRailsWithCampaign';
    console.log(finalData);
    const response = await fetch(FINALURL, Finalinit);
    const data = await response.json();

    if (data.success) {
      return data;
    }

    utility.checkTokenExpire(data);

  }
  catch (error) {
    console.log("error", error.message);
  }
}

export async function getPages(args = defaultArgs, columns, searchText, isActiveRails = RAIL_STATUS.All) {

  utility.checkSessionExpire();

  try {
    var finalData = {
      query: args.query || defaultArgs.query,
      sort: args.sort || defaultArgs.sort,
      page: args.page || defaultArgs.page,
      perPage: args.perPage || defaultArgs.perPage,
      archive: args.archive || defaultArgs.archive,
      dataColumns: columns || [],
      searchText: searchText || '',
      isActive: isActiveRails
    };

    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };
    var FINALURL = OTTURL.BASEURL + 'getPages';
    console.log(finalData);
    const response = await fetch(FINALURL, Finalinit);
    const data = await response.json();

    if (data.success) {
      return data;
    }

    utility.checkTokenExpire(data);

  }
  catch (error) {
    console.log("error", error.message);
  }
}

export async function getBottomMenu() {

  utility.checkSessionExpire();

  try {


    var Finalinit = { ...GetHeader() };
    var FINALURL = OTTURL.BASEURL + 'getBottomMenu';
    const response = await fetch(FINALURL, Finalinit);
    const data = await response.json();

    if (data.success) {
      return data;
    }

    utility.checkTokenExpire(data);

  }
  catch (error) {
    console.log("error", error.message);
  }
}

export async function getOttSettings() {

  utility.checkSessionExpire();

  try {


    var Finalinit = { ...GetHeader('Post') };
    var FINALURL = OTTURL.BASEURL + 'getOttSetting';
    const response = await fetch(FINALURL, Finalinit);
    const data = await response.json();

    if (data.success) {
      return data;
    }

    utility.checkTokenExpire(data);

  }
  catch (error) {
    console.log("error", error.message);
  }
}

export async function getPagesV2(args = defaultArgs, columns, searchText, isActiveRails = RAIL_STATUS.All) {

  utility.checkSessionExpire();

  try {
    var finalData = {
      query: args.query || defaultArgs.query,
      sort: args.sort || defaultArgs.sort,
      page: args.page || defaultArgs.page,
      perPage: args.perPage || defaultArgs.perPage,
      archive: args.archive || defaultArgs.archive,
      dataColumns: columns || [],
      searchText: searchText || '',
      isActive: isActiveRails
    };

    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };
    var FINALURL = OTTURL.BASEURL + 'getPagesV2';
    console.log(finalData);
    const response = await fetch(FINALURL, Finalinit);
    const data = await response.json();

    if (data.success) {
      return data;
    }

    utility.checkTokenExpire(data);

  }
  catch (error) {
    console.log("error", error.message);
  }
}

export async function downloadFile(fileName) {
  try {
    var FINALURL = FILEURL.BASEURL + 'downloadFile/' + fileName;
    var Finalinit = {
      ...GetHeader(),
    };
    try {
      const response = await fetch(FINALURL, Finalinit);
      //download the file
      const responseData = await response.blob();
      const url = window.URL.createObjectURL(new Blob([responseData]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      toast.error(error.message);
    }
  }
  catch (error) {
    alert(error.message)
    console.log("error", error);
  }
}

export async function saveFAQ(data) {

  try {
    var FINALURL = FILEURL.BASEURL + 'saveFAQ';

    const finalData = {
      data: data
    }

    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };
    const response = await fetch(FINALURL, Finalinit);

    const responseData = await response.json();
    return responseData;
  }
  catch (error) {
    alert(error)
    console.log("error", error);
  }


}

export async function languageTranslator(text, target) {
  try {
    let FINALURL = TRANSLATORURL.BASEURL + 'languageTranslator';

    const finalData = {
      text: text,
      target: target
    }

    let Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };
    const response = await fetch(FINALURL, Finalinit);

    const responseData = await response.json();
    return responseData;
  }
  catch (error) {
    //alert(error.message)
    console.log("error", error);
    return { success: false, message: error.message, data: { data: undefined } }

  }


}

//get entities function with search for server side search
export async function getEntitiesSearchWithMedia(entityname, args = defaultArgs, columns, searchText, filters, mediaCategorySID, channelFilter) {

  utility.checkSessionExpire();

  const user = utility.getValue(LOCALSTORAGE_KEY.userData)

  try {
    var finalData = {
      query: args.query || defaultArgs.query,
      sort: args.sort || defaultArgs.sort,
      page: args.page || defaultArgs.page,
      perPage: args.perPage || defaultArgs.perPage,
      archive: args.archive || defaultArgs.archive,
      dataColumns: columns || [],
      searchText: searchText || '',
      filters: filters,
      user: { _id: user?._id ?? "", name: user?.name ?? "" },
      mediaCategorySID: mediaCategorySID,
      channelFilter: channelFilter || []
    };

    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };
    var FINALURL = APIURL.BASEURL + 'getEntitiesSearchWithMedia/' + entityname;
    console.log(finalData);
    const response = await fetch(FINALURL, Finalinit);
    const data = await response.json();

    if (data.success) {
      return data;
    }

    utility.checkTokenExpire(data);
    utility.checkIsLicenseExpire(data);

  }
  catch (error) {
    console.log("error", error.message);
  }
}

export async function getEntitiesWithSearchForShortMedia(entityname, args = defaultArgs, columns, searchText, filters) {

  utility.checkSessionExpire();

  const user = utility.getValue(LOCALSTORAGE_KEY.userData)

  try {
    var finalData = {
      query: args.query || defaultArgs.query,
      sort: args.sort || defaultArgs.sort,
      page: args.page || defaultArgs.page,
      perPage: args.perPage || defaultArgs.perPage,
      archive: args.archive || defaultArgs.archive,
      dataColumns: columns || [],
      searchText: searchText || '',
      filters: filters,
      user: { _id: user?._id ?? "", name: user?.name ?? "" }
    };

    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };
    var FINALURL = APIURL.BASEURL + 'getEntitiesWithSearchForShortMedia/' + entityname;
    console.log(finalData);
    const response = await fetch(FINALURL, Finalinit);
    const data = await response.json();

    if (data.success) {
      return data;
    }

    utility.checkTokenExpire(data);
    utility.checkIsLicenseExpire(data);

  }
  catch (error) {
    console.log("error", error.message);
  }
}

export async function uploadPDF(file) {

  try {
    let FINALURL = FILEURL.BASEURL + 'uploadPDFOnCloudinary';

    const formData = new FormData();
    formData.append('file', file);

    const finalInit = {
      method: 'POST',
      body: formData,
    };

    const response = await fetch(FINALURL, finalInit);
    const responseData = await response.json();

    if (responseData.success) return responseData.data;
    else return responseData;
  } catch (error) {
    alert(error);
    console.log("error", error);
    return { success: false, message: error.message }
  }
}
export async function printBillInExcel(billingDataToPrint) {

  try {
    var FINALURL = BILLINGURL.BASEURL + 'printBillInExcel';

    const finalData = {
      data: billingDataToPrint
    }
    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };
    const response = await fetch(FINALURL, Finalinit);
    const responseData = await response.json();
    // console.log(responseData);
    return responseData;


  }
  catch (error) {
    //alert(error.message)
    console.log("error", error);
  }
}


export async function GetCoolitaEPG(payload) {


  try {
    console.log("Coolita")
    var finalData = {
      data: payload
    }
    var FINALURL = PLANNINGURL.BASEURL + 'GetCoolitaEPG'

    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) }
    console.log(Finalinit)
    const response = await fetch(FINALURL, Finalinit);
    console.log(response)
    const data = await response.json();
    console.log(data)
    return data;
  }
  catch (error) {
    console.log("error", error);
  }

}

export async function GetXiaomiEPG(payload) {

  try {
    var finalData = {
      data: payload
    }
    var FINALURL = PLANNINGURL.BASEURL + 'GetXiaomiEPG'

    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) }
    console.log(Finalinit)
    const response = await fetch(FINALURL, Finalinit);
    console.log(response)
    const data = await response.json();
    console.log(data)
    return data;
  }
  catch (error) {
    console.log("error", error);
  }

}
export async function getContentCounts() {

  const user = utility.getValue(LOCALSTORAGE_KEY.userData)

  try {
    let FINALDATA = {
      userID: user?._id
    }
    let FINALURL = DASHBOARDURL.BASEURL + 'getContentCounts';

    let Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(FINALDATA) };
    let response = await fetch(FINALURL, Finalinit);
    let responseData = await response.json();
    return responseData;

  }
  catch (error) {
    //alert(error.message)
    console.log("error", error);
  }
}
export async function getCMSCounts() {

  try {
    let FINALURL = DASHBOARDURL.BASEURL + 'getCMSCounts';

    let Finalinit = { ...GetHeader("POST"), "body": JSON.stringify() };
    let response = await fetch(FINALURL, Finalinit);
    let responseData = await response.json();
    return responseData;

  }
  catch (error) {
    //alert(error.message)
    console.log("error", error);
  }
}
export async function getMonetizationCounts() {

  try {
    let FINALURL = DASHBOARDURL.BASEURL + 'getMonetizationCounts';

    let Finalinit = { ...GetHeader("POST"), "body": JSON.stringify() };
    let response = await fetch(FINALURL, Finalinit);
    let responseData = await response.json();
    return responseData;

  }
  catch (error) {
    //alert(error.message)
    console.log("error", error);
  }
}

export async function getMonetizationDashBoardData(payload) {

  utility.checkSessionExpire();

  try {
    var FINALURL = MONETIZATION.BASEURL + 'getMonetizationDashBoardData'
    var Finalinit = { ...GetHeader('POST'), "body": JSON.stringify(payload) }
    const response = await fetch(FINALURL, Finalinit);
    const data = await response.json();
    return data;
  }
  catch (error) {
    console.log("error", error);
  }
}

export async function getSubscriptionGraphDetails(payload) {

  utility.checkSessionExpire();

  try {
    var FINALURL = MONETIZATION.BASEURL + 'getSubscriptionGraphDetails'
    var Finalinit = { ...GetHeader('POST'), "body": JSON.stringify(payload) }
    const response = await fetch(FINALURL, Finalinit);
    const data = await response.json();
    return data;
  }
  catch (error) {
    console.log("error", error);
  }
}

export async function updateEpisodeReferenceCode(data) {

  try {

    let FINALURL = MEDIAURL.BASEURL + 'updateEpisodeReferenceCode';
    let finalData = {
      data: data
    }
    console.log(finalData);
    let Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) }
    let response = await fetch(FINALURL, Finalinit);
    let responseData = await response.json();
    return responseData;

  }
  catch (error) {
    console.log("error", error);
  }
}

export async function canArchiveMediaEpisode(mediaEpisodeId) {

  try {

    let FINALURL = MEDIAURL.BASEURL + 'canArchiveMediaEpisode';
    let finalData = {
      mediaEpisodeId: mediaEpisodeId
    }
    let Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) }
    let response = await fetch(FINALURL, Finalinit);
    let responseData = await response.json();
    return responseData;

  }
  catch (error) {
    console.log("error", error);
  }
}
export async function getMetaDataFromMediaEpisode(mediaEpisodeSIDs) {

  try {

    let FINALURL = MEDIAURL.BASEURL + 'getMetaDataFromMediaEpisode';
    let finalData = {
      mediaEpisodeSIDs: mediaEpisodeSIDs
    }
    let Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) }
    let response = await fetch(FINALURL, Finalinit);
    let responseData = await response.json();
    return responseData;

  }
  catch (error) {
    console.log("error", error);
  }
} getMetaDataFromMediaEpisode
export async function validateMediaInSchedule(payload) {
  //payload
  // {
  //     "startDate": 1712296800000, 
  //     "endDate": 1712383199999,
  //     "channelSID": 2,
  // }
  try {

    let FINALURL = SCHEDULINGURL.BASEURL + 'validateMediaInSchedule';
    let finalData = {
      data: payload
    }
    let Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) }
    let response = await fetch(FINALURL, Finalinit);
    let responseData = await response.json();
    return responseData;

  }
  catch (error) {
    console.log("error", error);
  }
}

export async function loadSecondaryEventDefaultPrototypes(channelSID) {

  try {

    let FINALURL = SCHEDULINGURL.BASEURL + 'loadSecondaryEventDefaultPrototypes';
    let finalData = {
      channelSID: channelSID
    }
    let Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) }
    let response = await fetch(FINALURL, Finalinit);
    let responseData = await response.json();
    return responseData;

  }
  catch (error) {
    console.log("error", error);
  }
}


export async function programScheduleDraft(payload) {

  const user = utility.getValue(LOCALSTORAGE_KEY.userData)

  try {

    let FINALURL = SCHEDULINGURL.BASEURL + 'programScheduleDraft';
    let finalData = {
      data: {
        ...payload,
        User: { _id: user?._id, name: user?.name, SID: user?.SID }
      }
    }
    let Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) }
    let response = await fetch(FINALURL, Finalinit);
    let responseData = await response.json();
    return responseData;

  }
  catch (error) {
    console.log("error", error);
  }
}

export async function publishProgramScheduleDraft(payload) {

  const user = utility.getValue(LOCALSTORAGE_KEY.userData)

  let finalData = {
    ...payload,
    User: { _id: user?._id, name: user?.name, SID: user?.SID }
  }

  try {

    let FINALURL = SCHEDULINGURL.BASEURL + 'publishProgramScheduleDraft';
    let Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) }
    let response = await fetch(FINALURL, Finalinit);
    let responseData = await response.json();
    return responseData;

  }
  catch (error) {
    console.log("error", error);
  }
}

export async function getRegionData(SID) {

  utility.checkSessionExpire();

  try {

    let Finalinit = { ...GetHeader("POST"), "body": JSON.stringify() };
    let FINALURL = APIURL.BASEURL + 'getRegionData/' + SID;
    const response = await fetch(FINALURL, Finalinit);
    const data = await response.json();

    utility.checkTokenExpire(data);
    utility.checkIsLicenseExpire(data);

    return data;

  }
  catch (error) {
    console.log("error", error.message);
  }
}

export async function getIMDBData(data) {

  utility.checkSessionExpire();

  try {
    let payload = {
      searchName: data.SearchName,
      type: data.Type,
      page: data.Page
    }

    let Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(payload) };
    var FINALURL = IMDBURL.BASEURL + "getIMDBData";
    const response = await fetch(FINALURL, Finalinit);

    const responseData = await response.json();

    utility.checkTokenExpire(responseData);
    utility.checkIsLicenseExpire(responseData);

    return responseData;
  }
  catch (error) {
    console.log("error", error);
  }


}
export async function getSpotifyMusicData(payload) {
  //   {
  //     "searchPhrase":"sorry",
  //     "page":2,
  //     "limit":10
  // }

  utility.checkSessionExpire();

  try {

    let Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(payload) };
    var FINALURL = THIRDPARTYAPIURL.BASEURL + "searchMusic";
    const response = await fetch(FINALURL, Finalinit);

    const responseData = await response.json();

    utility.checkTokenExpire(responseData);
    utility.checkIsLicenseExpire(responseData);

    return responseData;
  }
  catch (error) {
    console.log("error", error);
  }


}

export async function getMusicById(id) {

  utility.checkSessionExpire();

  try {

    let Finalinit = { ...GetHeader("POST"), "body": JSON.stringify({ id: id }) };
    var FINALURL = THIRDPARTYAPIURL.BASEURL + "getMusicById";
    const response = await fetch(FINALURL, Finalinit);

    const responseData = await response.json();

    utility.checkTokenExpire(responseData);
    utility.checkIsLicenseExpire(responseData);

    return responseData;
  }
  catch (error) {
    console.log("error", error);
  }


}
export async function getIMDBDetails(imdbID) {

  utility.checkSessionExpire();

  try {
    let payload = {
      imdbID: imdbID
    }

    let Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(payload) };
    var FINALURL = IMDBURL.BASEURL + "getIMDBDetails";
    const response = await fetch(FINALURL, Finalinit);

    const responseData = await response.json();

    utility.checkTokenExpire(responseData);
    utility.checkIsLicenseExpire(responseData);

    return responseData;
  }
  catch (error) {
    console.log("error", error);
  }


}

export async function saveIMDbMetaData(mediaSID, imdbData, saveMetaData) {

  utility.checkSessionExpire();

  try {
    let payload = {
      mediaSID: mediaSID,
      imdbData: imdbData,
      saveMetaData: saveMetaData
    }

    console.log(payload);

    let Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(payload) };
    var FINALURL = IMDBURL.BASEURL + "saveIMDbMetaData";
    const response = await fetch(FINALURL, Finalinit);

    const responseData = await response.json();

    utility.checkTokenExpire(responseData);
    utility.checkIsLicenseExpire(responseData);

    return responseData;
  }
  catch (error) {
    console.log("error", error);
  }


}

export async function getProgramTimeConsumptionReport(payload) {

  utility.checkSessionExpire();

  try {

    let Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(payload) };
    var FINALURL = REPORTSURL.BASEURL + 'getProgramTimeConsumptionReport';
    const response = await fetch(FINALURL, Finalinit);
    const data = await response.json();
    return data;

  }
  catch (error) {
    console.log("error", error);
  }
}

export async function getMusicReport(payload) {

  utility.checkSessionExpire();

  try {

    let Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(payload) };
    var FINALURL = REPORTSURL.BASEURL + 'getMusicReport';
    const response = await fetch(FINALURL, Finalinit);
    const data = await response.json();
    return data;

  }
  catch (error) {
    console.log("error", error);
  }
}

export async function exportPrgramTimeConsumtionReport(payload) {

  utility.checkSessionExpire();

  try {
    var FINALURL = FILEURL.BASEURL + 'exportPrgramTimeConsumtionReport';

    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(payload) };
    const response = await fetch(FINALURL, Finalinit);

    const responseData = await response.json();
    if (responseData.success) return responseData
    else return responseData;
  }
  catch (error) {
    alert(error)
    console.log("error", error);
  }

}

export async function getSegmentationDataExport(payload) {
  try {
    console.log(payload)
    var FINALURL = MEDIAURL.BASEURL + 'getSegmentationDataExport'
    var Finalinit = { ...GetHeader('POST'), "body": JSON.stringify(payload) }
    const response = await fetch(FINALURL, Finalinit);
    const data = await response.json();

    if (data.length < 0) {
      toast.error(`${lang.no_data_found_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    return data;
  }
  catch (error) {
    console.log("error", error);
  }
}

export async function loadPlanningSeriesEpisodes(searchText, filterDataItem, pagination, channels = []) {

  utility.checkSessionExpire();

  var finalData = {
    data: searchText,
    filter: filterDataItem,
    pagination: pagination,
    channels: channels.length > 0 ? channels.map((x) => x.SID) : []
  }

  try {
    var FINALURL = PLANNINGURL.BASEURL + 'loadPlanningSeriesEpisodes'

    var Finalinit = { ...GetHeader('POST'), "body": JSON.stringify(finalData) }
    const response = await fetch(FINALURL, Finalinit);
    const data = await response.json();

    if (data.success) {
      return data;
    }

    utility.checkTokenExpire(data);

  }
  catch (error) {
    console.log("error", error);
  }

}


export async function canChangeTxStatusToLiveOrDalayed(slotdatetime, mediaepisode_id) {

  utility.checkSessionExpire();

  var finalData = {
    data: {
      slotdatetime: slotdatetime,
      mediaepisode_id: mediaepisode_id
    }
  }

  try {
    var FINALURL = PLANNINGURL.BASEURL + 'canChangeTxStatusToLiveOrDalayed'

    var Finalinit = { ...GetHeader('POST'), "body": JSON.stringify(finalData) }
    const response = await fetch(FINALURL, Finalinit);
    const responsedata = await response.json();

    return responsedata;

  }
  catch (error) {
    console.log("error", error);
  }

}

export async function updateUserPreferenceColumnWidth(payload) {
  const user = utility.getValue(LOCALSTORAGE_KEY.userData);
  utility.checkSessionExpire();

  try {
    console.log(payload);
    let finalData = {
      ...payload,
      userId: user?._id
    }
    let FINALURL = APIURL.BASEURL + 'updateUserPreferenceColumnWidth' + "/0";
    let Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };
    let response = await fetch(FINALURL, Finalinit);
    let responseData = await response.json();
    console.log(responseData?.message);
    return responseData;
  } catch (error) {
    console.log("error", error);
  }
}

export async function uploadVideoAndImage(imageData) {

  try {
    let FINALURL = FILEURL.BASEURL + 'uploadVideoAndImageOnCloudinary';

    let formData = new FormData()
    formData.append('file', imageData)

    let Finalinit = { method: 'POST', body: formData };
    let response = await fetch(FINALURL, Finalinit);
    // if (response.status === 413) {
    //   return { success: false, message: "The file you're trying to upload is too large. Please choose a smaller file." }
    // }
    let responseData = await response.json();
    return responseData;
  }
  catch (error) {
    console.log("error", error);
    return { success: false, message: error.message }
  }

}

export async function login(userName, password) {

  try {
    var FINALURL = LOGINURL.BASEURL;

    //prepare data
    var finalData = {
      "data": {
        "userName": userName,
        "password": password
      }
    };

    var init = GetHeader("POST");
    var Finalinit = { ...init, "body": JSON.stringify(finalData) };
    const response = await fetch(FINALURL, Finalinit);

    const responseData = await response.json();

    return responseData;
  }
  catch (error) {
    alert(error)
    console.log("error", error);
  }

}


export async function getAppVersion() {

  try {
    var FINALURL = CONFIGURL.BASEURL + '/getAppVersion';
    var Finalinit = { ...GetHeader() };

    const response = await fetch(FINALURL, Finalinit);

    const responseData = await response.json();

    return responseData;
  }
  catch (error) {
    alert(error)
    console.log("error", error);
  }

}

export async function getPreviousDayEndTime(payload) {

  utility.checkSessionExpire();
  //payload data:
  // ScheduleDate in ms
  // channelSID

  try {
    var FINALURL = SCHEDULINGURL.BASEURL + 'getPreviousDayEndTime'

    var Finalinit = { ...GetHeader('POST'), "body": JSON.stringify(payload) }
    const response = await fetch(FINALURL, Finalinit);
    const data = await response.json();
    return data;
  }
  catch (error) {
    console.log("error", error);
  }

}


export async function checkDuplicateInterSectTimeBand(value) {

  utility.checkSessionExpire();

  try {
    let FINALURL = APIURL.BASEURL + 'checkDuplicateInterSectTimeBand/0';
    let FinalData = {
      data: value
    }
    let Finalinit = { ...GetHeader('POST'), "body": JSON.stringify(FinalData) }
    const response = await fetch(FINALURL, Finalinit);
    const data = await response.json();
    return data;
  }
  catch (error) {
    toast.error(error.message);
  }
}

export const getCompanyData = async (tableName, args = defaultArgs, columns, searchText) => {

  utility.checkSessionExpire();

  try {

    let finalData = {
      query: args.query || defaultArgs.query,
      sort: args.sort || defaultArgs.sort,
      page: args.page || defaultArgs.page,
      perPage: args.perPage || defaultArgs.perPage,
      dataColumns: columns || [],
      archive: args.archive || defaultArgs.archive,
      searchText: searchText || ''
    };

    let Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };
    let FINALURL = get_APIURL(ENTITYNAME.Company) + 'getCompanyData/' + tableName;
    let response = await fetch(FINALURL, Finalinit);
    let data = await response.json();

    utility.checkTokenExpire(data);
    utility.checkIsLicenseExpire(data);

    return data;
  }
  catch (error) {
    toast.error(error.message);
  }
}

export async function getSecondaryEventWithSearch(entityname, args = defaultArgs, columns, searchText) {

  utility.checkSessionExpire();

  const user = utility.getValue(LOCALSTORAGE_KEY.userData);

  try {
    let finalData = {
      userData: { channels: user?.Channels ?? [], RoleType: user?.RoleType },
      query: args.query || defaultArgs.query,
      sort: args.sort || defaultArgs.sort,
      page: args.page || defaultArgs.page,
      perPage: args.perPage || defaultArgs.perPage,
      archive: args.archive || defaultArgs.archive,
      dataColumns: columns || [],
      searchText: searchText || '',
    };

    let Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };
    let FINALURL = SECONDARYEVENTURL.BASEURL + 'getSecondaryEventWithSearch/' + entityname;
    const response = await fetch(FINALURL, Finalinit);
    const data = await response.json();

    utility.checkTokenExpire(data);
    utility.checkIsLicenseExpire(data);

    return data;

  }
  catch (error) {
    console.log("error", error.message);
    toast.error(error.message);
  }
}

export async function canLockUnlockItem(id, lock) {

  utility.checkSessionExpire();

  const user = utility.getValue(LOCALSTORAGE_KEY.userData);

  try {
    let finalData = {
      id: id,
      lock: lock
    };

    let Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };
    let FINALURL = PLANNINGURL.BASEURL + 'canLockUnlockItem';
    const response = await fetch(FINALURL, Finalinit);
    const data = await response.json();

    utility.checkTokenExpire(data);
    utility.checkIsLicenseExpire(data);

    return data;

  }
  catch (error) {
    console.log("error", error.message);
    toast.error(error.message);
  }
}

export async function deleteCompanyData(_id) {

  try {

    let Finalinit = { ...GetHeader("DELETE"), "body": JSON.stringify() };

    const response = await fetch(`${APIURL.BASEURL}/${_id}`, Finalinit);
    const resData = await response.json();

    return resData;
  }
  catch (error) {
    toast.error("error", error.message);
  }

}

export async function lockItem(header, children, isSave = true, nextHeaderSlotDateTime) {

  utility.checkSessionExpire();

  const user = utility.getValue(LOCALSTORAGE_KEY.userData);

  try {
    let finalData = {
      header: header,
      children: children,
      isSave: isSave,
      nextHeaderSlotDateTime: nextHeaderSlotDateTime
    };

    let Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };
    let FINALURL = PLANNINGURL.BASEURL + 'lockItem';
    const response = await fetch(FINALURL, Finalinit);
    const data = await response.json();

    utility.checkTokenExpire(data);
    utility.checkIsLicenseExpire(data);

    return data;

  }
  catch (error) {
    console.log("error", error.message);
    toast.error(error.message);
  }
}

export async function unlockItem(header, children) {

  utility.checkSessionExpire();

  const user = utility.getValue(LOCALSTORAGE_KEY.userData);

  try {
    let finalData = {
      header: header,
      children: children,
    };

    let Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };
    let FINALURL = PLANNINGURL.BASEURL + 'unlockItem';
    const response = await fetch(FINALURL, Finalinit);
    const data = await response.json();

    utility.checkTokenExpire(data);
    utility.checkIsLicenseExpire(data);

    return data;

  }
  catch (error) {
    console.log("error", error.message);
    toast.error(error.message);
  }
}

export async function uploadFile(file) {

  utility.checkSessionExpire();

  try {

    let FINALURL = FILEURL.BASEURL + 'uploadFile';

    let formData = new FormData()
    formData.append('file', file)

    let Finalinit = { method: 'POST', body: formData };
    let response = await fetch(FINALURL, Finalinit);

    let responseData = await response.json();
    return responseData;
  }
  catch (error) {
    toast.error(error.message);
  }

}

export async function deleteFile(fileName) {

  utility.checkSessionExpire();

  try {

    let FINALURL = FILEURL.BASEURL + 'deleteFile';

    let Finalinit = { ...GetHeader("POST"), "body": JSON.stringify({ fileName: fileName }) };
    let response = await fetch(FINALURL, Finalinit);

    let responseData = await response.json();
    return responseData;
  }
  catch (error) {
    toast.error(error.message);
  }

}

export async function readFileNameWithContent(fileName) {

  utility.checkSessionExpire();

  try {

    let FINALURL = FILEURL.BASEURL + 'readFileNameWithContent';

    let Finalinit = { ...GetHeader("POST"), "body": JSON.stringify({ fileName: fileName }) };
    let response = await fetch(FINALURL, Finalinit);

    let responseData = await response.json();
    return responseData;
  }
  catch (error) {
    toast.error(error.message);
  }

}

export async function getIngestionReport(filters) {
  try {
    let finalData = {
      filters: filters
    }

    var FINALURL = REPORTSURL.BASEURL + 'getIngestionReport';
    var Finalinit = { ...GetHeader('POST'), "body": JSON.stringify(finalData) };
    const response = await fetch(FINALURL, Finalinit);
    const data = await response.json();
    if (data.success) {
      return data;
    }
    utility.checkTokenExpire(data);
  }
  catch (error) {
    console.log("error", error);
  }

}

export async function checkUserExists(user_id, email) {
  try {
    let finalData = {
      user_id: user_id,
      email: email
    }
    let FINALURL = USERURL.BASEURL + 'checkUserExists';
    let Finalinit = { ...GetHeader('POST'), "body": JSON.stringify(finalData) };
    let response = await fetch(FINALURL, Finalinit);
    let data = await response.json();
    utility.checkTokenExpire(data);
    return data;
  }
  catch (error) {
    toast.error(error.message)
  }

}