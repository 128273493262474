/* eslint-disable */
import { Routes, Route } from "react-router-dom"
import Login from "./modules/login/Login"
import { Collection } from './framework/forms/Collection';
import { MediaEpisodeEditForm } from './modules/MediaLibrary/MediaEpisodeEditForm';
import { OttEditForm } from './framework/OTT/OttEditForm';
import { RailsEditForm } from './framework/OTT/RailsEditForm';
import { PageEditForm } from './framework/OTT/PageEditForm';
import { PosterEditForm } from './framework/OTT/PosterEditForm';
import CollectionWithNewForm from './framework/forms/CollectionWithNewForm';
import LiveTv from './modules/platformLayout/tv/LiveTv/LiveTv';
import Main from "./modules/main/Main"
import { PrivateRoute } from "./PrivateRoute";
import SchedulingWrapper from "./modules/Scheduling/SchedulingWrapper";
import SchedulingWrapperNew from "./modules/Scheduling/Scheduling_Performance/SchedulingWrapperNew";
import { ENTITYNAME, MEDIACATEGORIES, MEDIACATEGORYTYPES, IMPORTTYPE, MODULETYPES, MODULE, PUBLISHING_SOURCE, EXPORT_TEMPLATE_MODULES } from "./framework/constant/constant";
import { ChannelMenuModel, collectionJobsMenuModel, collectionMediaMenuModel, collectionUserMenuModel, collectionQuickEditWithoutDeleteMenuModel, seasonMenuModel, OttMenuModel, collectionMenuModelAsDefualt, collectionPageMenuModel, collectionWithoutCommandMenuModel, collectionPlanningLockScheduleMenuModel, collectionAnnouncementReconcileMenuModel, exportTemplateMenuModel, collectionRailsMenuModel, seriesMenuModel, collectionMovieMenuModel, collectionBreakMenuModel, collectionDummyMenuModel, collectionMediaEpisodeGroupMenuModel, collectionMediaInterstitialModel, collectionMenuModel, collectionProgramMenuModel, collectionDigitalSignScreenMenuModel, collectionMenuModelWithDelete, collectionCompanyMenuModel } from "./framework/forms/helpercomponents/contextMenu/menus/collectionMenuModel";
import { SeriesEditForm } from "./modules/MediaLibrary/SeriesEditForm";
import SeasonEditForm from "./modules/MediaLibrary/SeasonEditForm";
import { ContentEditForm } from "./modules/masters/ContentEditForm";
import CampaignEditForm from "./modules/Sales/CampaignEditForm";
import BookingCollection from "./modules/Sales/BookingCollection";
import YearlyBookingCollection from "./modules/Sales/YearlyBookingCollection";
import ScheduleReconciliation from "./modules/Reconciliation/ScheduleReconciliation";
import ImportAsRun from "./modules/Reconciliation/ImportAsRun";
import ManageAsRun from "./modules/Reconciliation/ManageAsRun";
import PartnerIngetion from "./modules/PartnerIngestion/PartnerIngetion";
import SecondaryEventPrototype from "./modules/SecondaryEvent/SecondaryEventProtoType";
import SecondaryEventStyleSheet from "./modules/SecondaryEvent/SecondaryEventStyleSheet";
import BillingCollection from "./modules/Billing/BillingCollection";
import DefaultSecondaryEventPrototype from "./modules/SecondaryEvent/DefaultSecondaryEventPrototype";
import IngestionTemplate from "./modules/IngestionTemplate/IngestionTemplate";
import ShowSpots from "./modules/Sales/ShowSpots";
import { AgencyEditForm } from "./modules/Sales/AgencyEditForm";
import { MediaEpisodeInterstitialEditForm } from "./modules/Interstitial/MediaEpisodeInterstitialEditForm";
import Dashboard from "./modules/Dashboard/Dashboard";
import { FormFields } from "./modules/Form_Fields/Form_Fields";
import Format from "./modules/Planning/Format";
import ContractEditForm from "./modules/Contract/ContractEditForm";
import ExportTemplate from "./modules/Export/ExportTemplate";
import ExportEPGReport from "./modules/Export/ExportEPGReport";
import SecondaryEventDynamicRule from "./modules/SecondaryEvent/SecondaryEventDynamicRule";
import DataMigration from "./modules/DataMigration/DataMigration";
import ChannelEditForm from "./modules/masters/ChannelEditForm";
import { FormFieldsCollection } from "./modules/Form_Fields/FormFieldsCollection";
import UpdateBookingLineNumber from "./modules/Sales/Update Booking Line Number/UpdateBookingLineNumber";
import MediaEpisodeGroupEditForm from "./modules/Interstitial/MediaEpisodeGroup";
import RepeatPatternEditForm from "./modules/masters/RepeatPatternEditForm";
import GeneratedBillCollection from "./modules/Billing/GeneratedBillCollection";
import InvoiceContentEditForm from "./modules/masters/InvoiceContentEditForm";
import BookingTransfer from "./modules/Sales/BookingTransfer";
import { SalesTeamMasterEditForm } from "./modules/Sales/SalesTeamMasterEditForm";
import ClientEditForm from "./modules/Sales/ClientEditForm";
import PlannerWrapper from "./modules/Planning/PlannerWrapper";
import RoleEditForm from "./modules/masters/RoleEditFrom";
import UserEditForm from "./framework/OTT/UserEditForm";
import { useTranslation } from "./locale/useTranslation";
import ErrorComponent from "./components/ErrorComponent";
import SegmentTypeEditForm from "./modules/masters/SegmentTypeEditForm";
import VariationEditForm from "./modules/masters/VariationEditForm";
import BreakFormatEditForm from "./modules/masters/BreakFormatEditForm";
import ContentCategoryEditForm from "./modules/masters/ContentCategoryEditForm";
import MediaByCategoryMain from "./modules/MediaLibrary/mediaByCategory/mediaByCategoryMain";
import TimelineView from "./modules/TimelineView/timelineView";
import ProgramScheduleClash from "./modules/Planning/planningPreference/ProgramScheduleClash";
import PaymentPlanEditForm from "./modules/Monetization/PaymentPlanEditForm";
import OttProductEditForm from "./modules/Monetization/OttProductEditForm";
import OttPeriodEditForm from "./modules/Monetization/OttPeriodEditForm";
import SubscriberEditForm from "./modules/Monetization/SubscriberEditForm";
import OttCampaignEditForm from "./modules/Monetization/OttCampaignEditForm";
import Preview from "./modules/platformLayout/tv/homePage/components/Preview";
import JobsShowForm from "./modules/IngestionTemplate/JobsShowForm";
import MakeGoodSpot from "./modules/Sales/MakeGoodSpotCollection";
import ScheduleConstraintsEditForm from "./modules/masters/ScheduleConstraintsEditForm";
import { CouponEditForm } from "./modules/Monetization/CouponEditForm";
import { AuditLogCollection } from "./modules/Audit Log/AuditLogCollection";
import OttSegmentEditForm from "./modules/Monetization/OttSegmentEditForm";
import ManageContent from "./modules/MediaLibrary/manageContent/manageContent";
import { WorkflowEditForm } from "./modules/Work Order/WorkflowEditForm";
import FavouritesEditForm from "./modules/Favourites/FavouritesEditForm";
import { LiveEventsMain } from "./modules/LiveEvents/LiveEventsMain";
import OptimisationEditForm from "./modules/Sales/OptimisationEditForm";
import MediaCategoryTypeEditForm from "./modules/masters/MediaCategoryTypeEditForm";
import PdfToJson from "./modules/POC/pdfToJson";
import InterstitialHistoryReport from "./modules/Reports/InterstitialHistoryReport";
import ClientAgencyBookingReport from "./modules/Reports/ClientAgencyBookingReport";
import TechnicalDetailPrototypeEditForm from "./modules/masters/TechnicalDetailPrototypeEditForm";
import BookingScheduleStatus from "./modules/Dashboard/BookingScheduleStatus";
import MediaMamAssetsEditForm from "./modules/MediaLibrary/MediaMamAssetsEditForm";
import MissingMediaReport from "./modules/Reports/MissingMediaReport";
import TimeRangeEditForm from "./modules/masters/TimeRangeEditForm";
import LanguageEditForm from "./modules/masters/LanguageEditForm";
import RateCardEditForm from "./modules/Sales/RateCardEditForm";
import { MediaEpisodeInterstitialCollection } from "./modules/Interstitial/MediaEpisodeInterstitialCollection";
import { MaterialContentEditForm } from "./modules/masters/MaterialContentEditForm";
import { MediaEpisodeBreakEditForm } from "./modules/Interstitial/MediaEpisodeBreakEditForm";
import { CampaignCollection } from "./modules/Sales/CampaignEditForm/CampaignCollection";
import { OttMenuForm } from "./framework/OTT/OttMenuForm";
import { OttAssetCollection } from "./framework/OTT/OttAssetCollection";
import { OttSettingForm } from "./framework/OTT/OttSettingForm";
import PrelogPostlogReport from "./modules/Reports/PrelogPostlogReport";
import CreditLineReport from "./modules/Reports/CreditLineReport";
import PurgeSpotReport from "./modules/Reports/PurgeSpotReport";
import AirTimeConsumptionReport from "./modules/Reports/AirTimeConsumptionReport";
import { ProtoTypeContentEditForm } from "./modules/masters/ProtoTypeContentEditForm";
import AmazonDeliveryGroup from "./modules/masters/AmazonDeliveryGroup";
import ScheduleAutofillInterstitial from "./modules/masters/ScheduleAutofillInterstitial";
import { MediaEpisodeDummyEditForm } from "./modules/Interstitial/MediaEpisodeDummyEditForm";
import MamAssetReport from "./modules/Reports/MamAssetReport";
import PendingApprovalCollection from "./modules/Sales/PendingApprovalCollection";
import BreakConfigurationEditForm from "./modules/Scheduling/BreakConfigurationEditForm";
import BillingSummaryReport from "./modules/Billing/BillingSummaryReport";
import { AnnouncementReconcileCollection } from "./modules/Sales/AnnouncementReconcileCollection";
import { EPGCollection } from "./modules/EPG/EPGCollection";
import EPGChannelEditForm from "./modules/EPG/EPGChannelEditForm";
import FaqCollection from "./modules/Faq/FaqCollection";
import { FaqEditForm } from "./modules/Faq/FaqEditForm";
import TutorialVideosEditForm from "./modules/TopNews/TutorialVideosEditForm";
import MediaMasterReport from "./modules/Reports/MediaMasterReport";
import { MarketingScreenEditForm } from "./framework/OTT/MarketingScreenEditForm";
import { PromotionalScreenEditForm } from "./framework/OTT/PromotionalScreenEditForm";
import { RailsCollection } from "./framework/OTT/RailsCollection";
import { PageCollection } from "./framework/OTT/PageCollection";
import ProviderEditForm from "./modules/masters/ProviderEditForm";
import ModuleEditForm from "./modules/Module_Form/ModuleEditForm";
import { ModuleCollection } from "./modules/Module_Form/ModuleCollection";
import { GenreEditForm } from "./modules/masters/GenreEditForm";
import { CompanyForm } from "./modules/Company/CompanyForm";
import { FinancialYearEditForm } from "./modules/masters/FinancialYearEditForm";
import { SalesTargetEditForm } from "./modules/Sales/SalesTargetEditForm";
import DocumentEditForm from "./framework/OTT/DocumentEditForm";
import { MediaEpisodeCollection } from "./modules/MediaLibrary/MediaEpisodeCollection";
import { ScreenSaverEditForm } from "./framework/OTT/ScreenSaverEditForm";
import PlanningSlotEditForm from "./modules/masters/PlanningSlotEditForm";
import WebAssetsEditForm from "./framework/OTT/WebAssetsEditForm";
import RegionEditForm from "./modules/masters/RegionEditForm";
import SponsorTypeEditForm from "./modules/masters/SponsorTypeEditForm";
import PiAndSeoEditForm from "./framework/OTT/PiAndSeoEditForm";
import ThemeEditForm from "./framework/OTT/ThemeEditForm";
import CurrencyEditForm from "./modules/masters/CurrencyEditForm";
import ApplicationConfigurationForm from "./modules/ApplicationConfig/ApplicationConfigurationForm";
import { JobsScheduleEditForm } from "./modules/Scheduling/JobsScheduleEditForm";
import { VarianceEditForm } from "./modules/masters/VarianceEditForm";
import TimeZoneEditForm from "./modules/masters/TimeZoneEditForm";
import { SubscriberCollection } from "./modules/Monetization/SubscriberCollection";
import PaymentProviderEditForm from "./modules/Monetization/PaymentProviderEditForm";
import TournamentTypeEditForm from "./modules/masters/TournamentTypeEditForm";
import LiveEventTypeEditForm from "./modules/masters/LiveEventTypeEditForm";
import MusicCategoryEditForm from "./modules/masters/MusicCategoryEditForm";
import ProgramTimeConsumptionReport from "./modules/Reports/ProgramTimeConsumptionReport";
import MusicReport from "./modules/Reports/MusicReport";
import ResolutionEditForm from "./modules/DigitalSign/ResolutionEditForm";
import OverlayEditForm from "./modules/DigitalSign/OverlayEditForm";
import TxModeEditForm from "./modules/masters/TxModeEditForm";
import MediaEpisodesBySeasonAssetIdCollection from "./modules/MediaLibrary/MediaEpisodesBySeasonAssetIdCollection";
import FormatScheduleForm from "./modules/Planning/FormatScheduleForm";
import { DigisignScreenCollection } from "./modules/DigitalSign/DigisignScreenCollection";
import { HeaderPlaylistCollection } from "./modules/DigitalSign/HeaderPlaylistCollection";
import PlaylistWrapperNew from "./modules/DigitalSign/Playlist/PlaylistWrapperNew";
import ScreenEditForm from "./modules/DigitalSign/ScreenEditForm";
import { ScreenGroupEditForm } from "./modules/DigitalSign/ScreenGroupEditForm";
import DigiSignContentCollection from "./modules/DigitalSign/FileUpload/DigiSignContentCollection";
import DigitalSignDashboardForm from "./modules/DigitalSign/DigitalSignDashboardForm";
import { DigitalSignCollection } from "./framework/forms/DigitalSignCollection";
import DayPartEditForm from "./modules/masters/DayPartEditForm";
import { DigitalSignScreenGroupCollection } from "./modules/DigitalSign/DigitalSignScreenGroupCollection";
import { CompanyCollection } from "./modules/Company/CompanyCollection";
import { DigitalSign_CompanyColumns, DigitalSign_CompanyPlansColumns, DigitalSign_ContentGroupColumns, DigitalSign_OverlaysColumns, DigitalSign_PlansMasterColumns } from "./framework/utility/customColumns";
import { UsersCollection } from "./modules/users/UsersCollection";
import DigitalSignAdminDashboardForm from "./modules/DigitalSign/DigitalSignAdminDashboardForm";
import MediaEpisodesBySeriesAssetIdCollection from "./modules/MediaLibrary/MediaEpisodesBySeriesAssetIdCollection";
import { SecondaryEventCollection } from "./modules/SecondaryEvent/SecondaryEventCollection";
import InstanceEditForm from "./modules/Instance/InstanceEditForm";
import DigitalSignContentGroupEditForm from "./modules/DigitalSign/DigitalSignContentGroupEditForm";
import DigitalSignSignUpForm from "./modules/DigitalSign/LoginPage/DigitalSignSignupForm";
import IngestionReport from "./modules/Reports/IngestionReport";
import { DigitalSignCompanyCollection } from "./modules/DigitalSign/company/DigitalSignCompanyCollection";
import DigitalSignCompanyForm from "./modules/DigitalSign/company/DigitalSIgnCompanyForm";
import { TournamentGroupEditForm } from "./modules/LiveEvents/TournamentGroupEditForm";
import EPGConvertor from "./modules/Planning/EPGConvertor";
import { MediaTemplateEditForm } from "./modules/MediaLibrary/MediaTemplateEditForm";

//for pagination 
// isPageable={true} page_take={20} 

const RouteManagment = () => {
    const lang = useTranslation();

    return <Routes>

        {/* login route */}
        <Route path='/' element={<Login />} />

        <Route path="/signup" element={<DigitalSignSignUpForm />} />

        <Route path='/error' element={<ErrorComponent />} />

        {/* main route */}
        <Route element={<PrivateRoute />} >
            <Route path='/home' exact element={<Main />} >
                <Route path='manageContent' element={<ManageContent />} />
                <Route path='pdfToJson' element={<PdfToJson />} />
                <Route path='' element={<Dashboard />} />
                <Route path='tv' element={<Preview isMobile={false} />} />
                <Route path='mobile' element={<Preview isMobile={true} />} />
                <Route path='tv/liveTv' element={<LiveTv />} />
                <Route path='mobile/liveTv' element={<LiveTv />} />
                <Route path='Page/liveTv' element={<LiveTv />} />
                <Route path='OttSetting/liveTv' element={<LiveTv />} />
                {/* <Route path='planning' element={<Planner entityname={ENTITYNAME.ProgramSchedule} wherestatement={['sequence', '>', 0]} />} /> */}
                <Route path='planning' element={<PlannerWrapper isPrimeTime={false} />} />
                <Route path='planningPrimeTime' element={<PlannerWrapper isPrimeTime={true} />} />
                <Route path='mediaByCategory' element={<MediaByCategoryMain />} />
                <Route path='timeline' element={<TimelineView title={lang.timeline_page_breadcrumb} />} />

                <Route path='Language' element={<Collection title={lang.language_page_breadcrumb} entityname={ENTITYNAME.Language} customEditForm={LanguageEditForm} menuModel={collectionMenuModelAsDefualt} />} />
                <Route path='Region' element={<Collection title={lang.region_page_breadcrumb} entityname={ENTITYNAME.Region} customEditForm={RegionEditForm} />} />
                <Route path='Brand' element={<Collection module={MODULE.BRAND} title={lang.brand_page_breadcrumb} entityname={ENTITYNAME.Brand} showLogs={true} menuModel={collectionMenuModelAsDefualt} />} />
                <Route path='Product' element={<Collection module={MODULE.PRODUCT} title={lang.product_page_breadcrumb} entityname={ENTITYNAME.Product} showLogs={true} menuModel={collectionMenuModelAsDefualt} />} />
                <Route path='OttAssetType' element={<Collection entityname={ENTITYNAME.OttAssetType} title={lang.assets_type_page_breadcrumb} />} />
                <Route path='OttPosterType' element={<Collection entityname={ENTITYNAME.OttPosterType} title={lang.posters_type_page_breadcrumb} />} />
                <Route path='Department' element={<Collection entityname={ENTITYNAME.Department} title={lang.department_page_breadcrumb} menuModel={collectionMenuModelAsDefualt} />} />
                <Route path='users' element={<CollectionWithNewForm IsSalesExecutive={false} entityname={ENTITYNAME.Users} />} >
                    <Route path='' element={<UsersCollection preferenceEntityName={"users"} entityname={ENTITYNAME.Users} openNewEditForm={true} title={lang.users_page_breadcrumb} wherestatement={[['IsSalesExecutive', '!=', true]]} customEditForm={UserEditForm} menuModel={collectionUserMenuModel} isPageable={true} />} />
                    <Route path=':SID' element={<UserEditForm IsSalesExecutive={false} entityname={ENTITYNAME.Users} />} />
                </Route>
                <Route path='Role' element={<CollectionWithNewForm entityname={ENTITYNAME.Role} />} >
                    <Route path='' element={<Collection entityname={ENTITYNAME.Role} openNewEditForm={true} title={lang.role_page_breadcrumb} customEditForm={RoleEditForm} isPageable={true} />} />
                    <Route path=':SID' element={<RoleEditForm entityname={ENTITYNAME.Role} />} />
                </Route>
                <Route path='MediaEpisodeEditForm' element={<MediaEpisodeEditForm />} />
                {/* <Route path='Scheduling' element={<Scheduler />} /> */}
                <Route path='ProgramScheduleClash' element={<ProgramScheduleClash title={lang.program_schedule_clash_breadcrumb} />} />
                <Route path='Scheduling' element={<SchedulingWrapperNew />} />
                <Route path='Scheduling1' element={<SchedulingWrapper />} />
                {/* <Route path='OttAssets' element={<Collection entityname={ENTITYNAME.OttAsset} title={lang.assets_page_breadcrumb} customEditForm={OttEditForm} page_take={100} filterable={false} />} /> */}
                <Route path='OttAssets' element={<OttAssetCollection module={MODULE.OTT_ASSETS} entityname={ENTITYNAME.OttAsset} title={lang.assets_page_breadcrumb} customEditForm={OttEditForm} page_take={100} filterable={false} showFilterTemplate={true}/>} />
                <Route path='OttPosters' element={<Collection module={MODULE.OTT_POSTERS} entityname={ENTITYNAME.OttPosters} title={lang.posters_page_breadcrumb} customEditForm={PosterEditForm} />} />
                <Route path='Client' element={<CollectionWithNewForm entityname={ENTITYNAME.Client} />} >
                    <Route path='' element={<Collection module={MODULE.CLIENT} entityname={ENTITYNAME.Client} openNewEditForm={true} title={lang.client_page_breadcrumb} customEditForm={ClientEditForm} isPageable={true} />} />
                    <Route path=':SID' element={<ClientEditForm entityname={ENTITYNAME.Client} />} />
                </Route>
                <Route path='Agency' element={<Collection module={MODULE.AGENCY} entityname={ENTITYNAME.Agency} title={lang.agency_page_breadcrumb} customEditForm={AgencyEditForm} />} />
                <Route path='SalesTeam' element={<Collection entityname={ENTITYNAME.SalesTeam} title={lang.sales_team_page_breadcrumb} customEditForm={SalesTeamMasterEditForm} />} />
                <Route path='users/SalesExecutive' element={<CollectionWithNewForm IsSalesExecutive={true} entityname={ENTITYNAME.Users} />} >
                    <Route path='' element={<Collection preferenceEntityName={"salesexecutive"} entityname={ENTITYNAME.Users} openNewEditForm={true} title={lang.sales_executive_page_breadcrumb} wherestatement={[['IsSalesExecutive', '=', true]]} customEditForm={UserEditForm} isPageable={true} />} />
                    <Route path=':SID' element={<UserEditForm IsSalesExecutive={true} entityname={ENTITYNAME.Users} />} />
                </Route>

                <Route path='Content' element={<Collection entityname={ENTITYNAME.Content} title={lang.content_page_breadcrumb} customEditForm={ContentEditForm} />} />
                <Route path='MaterialContent' element={<Collection entityname={ENTITYNAME.MaterialContent} title={lang.material_content_page_breadcrumb} customEditForm={MaterialContentEditForm} />} />

                <Route path="Licensor" element={<Collection entityname={ENTITYNAME.Licensor} title={lang.licensor_page_breadcrumb} />} />

                <Route path="Channel" element={<Collection entityname={ENTITYNAME.Channel} title={lang.channel_page_breadcrumb} showAdd={false} customEditForm={ChannelEditForm} menuModel={ChannelMenuModel} />} />
                <Route path="Currency" element={<Collection entityname={ENTITYNAME.Currency} title={lang.currency_page_breadcrumb} customEditForm={CurrencyEditForm} menuModel={collectionMenuModelAsDefualt} />} />
                <Route path='RepeatPattern' element={<CollectionWithNewForm entityname={ENTITYNAME.RepeatPattern} />} >
                    <Route path='' element={<Collection entityname={ENTITYNAME.RepeatPattern} title={lang.repeat_pattern_page_breadcrumb} openNewEditForm={true} customEditForm={RepeatPatternEditForm} />} />
                    <Route path=':SID' element={<RepeatPatternEditForm entityname={ENTITYNAME.RepeatPattern} />} />
                </Route>
                <Route path="InvoiceContent" element={<Collection entityname={ENTITYNAME.InvoiceContent} title={lang.invoice_content_page_breadcrumb} customEditForm={InvoiceContentEditForm} />} />
                <Route path="SegmentType" element={<Collection entityname={ENTITYNAME.SegmentType} title={lang.segment_type_page_breadcrumb} customEditForm={SegmentTypeEditForm} menuModel={collectionMenuModelAsDefualt} />} />
                <Route path='Variation' element={<CollectionWithNewForm entityname={ENTITYNAME.Variation} />} >
                    <Route path='' element={<Collection entityname={ENTITYNAME.Variation} title={lang.variation_page_breadcrumb} openNewEditForm={true} customEditForm={VariationEditForm} />} />
                    <Route path=':SID' element={<VariationEditForm entityname={ENTITYNAME.Variation} />} />
                </Route>
                <Route path='BreakFormat' element={<CollectionWithNewForm entityname={ENTITYNAME.BreakFormat} />} >
                    <Route path='' element={<Collection entityname={ENTITYNAME.BreakFormat} title={lang.breakformat_page_breadcrumb} openNewEditForm={true} customEditForm={BreakFormatEditForm} />} />
                    <Route path=':SID' element={<BreakFormatEditForm entityname={ENTITYNAME.BreakFormat} />} />
                </Route>
                <Route path='ScheduleConstraints' element={<CollectionWithNewForm entityname={ENTITYNAME.ScheduleConstraints} />} >
                    <Route path='' element={<Collection entityname={ENTITYNAME.ScheduleConstraints} title={lang.schedule_constraints_page_breadcrumb} openNewEditForm={true} customEditForm={ScheduleConstraintsEditForm} />} />
                    <Route path=':SID' element={<ScheduleConstraintsEditForm entityname={ENTITYNAME.ScheduleConstraints} />} />
                </Route>
                <Route path="PaymentProvider" element={<Collection entityname={ENTITYNAME.PaymentProvider} title={lang.payment_provider_page_breadcrumb} customEditForm={PaymentProviderEditForm} />} />
                <Route path="OttPeriodType" element={<Collection entityname={ENTITYNAME.OttPeriodType} title={lang.ott_period_type_page_breadcrumb} />} />
                <Route path="PaymentType" element={<Collection entityname={ENTITYNAME.PaymentType} title={lang.payment_type_page_breadcrumb} />} />
                <Route path="OttPeriod" element={<Collection entityname={ENTITYNAME.OttPeriod} title={lang.ott_period_page_breadcrumb} customEditForm={OttPeriodEditForm} />} />
                <Route path="Subscriber" element={<SubscriberCollection entityname={ENTITYNAME.Subscriber} title={lang.subscriber_page_breadcrumb} customEditForm={SubscriberEditForm} />} />
                <Route path='OttCampaign' element={<CollectionWithNewForm entityname={ENTITYNAME.OttCampaign} />} >
                    <Route path='' element={<Collection module={MODULE.OTT_CAMPAIGN} entityname={ENTITYNAME.OttCampaign} title={lang.ott_campaign_page_breadcrumb} openNewEditForm={true} customEditForm={OttCampaignEditForm} />} />
                    <Route path=':SID' element={<OttCampaignEditForm entityname={ENTITYNAME.OttCampaign} />} />
                </Route>
                <Route path='Booking' element={<BookingCollection />} />
                <Route path='YearlyBooking' element={< YearlyBookingCollection />} />

                <Route path='UpdateBookingLineNumber' element={<UpdateBookingLineNumber />} />

                <Route path='Contract' element={<CollectionWithNewForm entityname={ENTITYNAME.Contract} />} >

                    <Route path='' element={<Collection entityname={ENTITYNAME.Contract} title={lang.contract_page_breadcrumb} openNewEditForm={true} customEditForm={ContractEditForm} enableMutliSelect={true} />} />
                    <Route path=':SID' element={<ContractEditForm entityname={ENTITYNAME.Contract} />} />

                </Route>

                <Route path='Rails' element={<CollectionWithNewForm entityname={ENTITYNAME.Rails} />} >

                    <Route path='' element={<RailsCollection entityname={ENTITYNAME.Rails} title={lang.rails_page_breadcrumb} openNewEditForm={true} customEditForm={RailsEditForm} menuModel={collectionRailsMenuModel} />} />
                    <Route path=':SID' element={<RailsEditForm entityname={ENTITYNAME.Rails} />} />

                </Route>
                {/* <Route path='/Page' element={<div className={sbopen ? toggle ? 'content-toggle' : 'content-active' : 'body'}><Collection entityname='Page' customEditForm={PageEditForm} /></div>} /> */}
                <Route path='Page' element={<CollectionWithNewForm entityname={ENTITYNAME.Page} />} >

                    <Route path='' element={<PageCollection entityname={ENTITYNAME.Page} title={lang.page_breadcrumb} openNewEditForm={true} customEditForm={PageEditForm} menuModel={collectionPageMenuModel} />} />
                    <Route path=':SID' element={<PageEditForm entityname={ENTITYNAME.Page} />} />

                </Route>
                <Route path='MediaEpisode/Series' element={<CollectionWithNewForm entityname={ENTITYNAME.Series} mediaCategory={MEDIACATEGORIES.Series} mediaCategoryType={MEDIACATEGORYTYPES.Series} />} >
                    <Route path='' element={<MediaEpisodeCollection publishingSource={PUBLISHING_SOURCE.Series} module={MODULE.MEDIA_EPISODE_SERIES} entityname={ENTITYNAME.Series} openNewEditForm={true} title={lang.series_page_breadcrumb} mediaCategory={MEDIACATEGORIES.Series} mediaCategoryType={MEDIACATEGORYTYPES.Series} customEditForm={SeriesEditForm} menuModel={seriesMenuModel} isPageable={true} />} />
                    <Route path=':SID' element={<SeriesEditForm entityname={ENTITYNAME.Series} mediaCategory={MEDIACATEGORIES.Series} mediaCategoryType={MEDIACATEGORYTYPES.Series} />} />
                </Route>
                <Route path='Series/Season/:AssetId' element={<MediaEpisodesBySeriesAssetIdCollection />} />
                <Route path='MediaEpisode/Season' element={<CollectionWithNewForm entityname={ENTITYNAME.Season} mediaCategory={MEDIACATEGORIES.Season} mediaCategoryType={MEDIACATEGORYTYPES.Season} />} >
                    <Route path='' element={<MediaEpisodeCollection publishingSource={PUBLISHING_SOURCE.Season} module={MODULE.MEDIA_EPISODE_SEASON} entityname={ENTITYNAME.Season} openNewEditForm={true} title={lang.season_page_breadcrumb} customEditForm={SeasonEditForm} menuModel={seasonMenuModel} mediaCategory={MEDIACATEGORIES.Season} mediaCategoryType={MEDIACATEGORYTYPES.Season} isPageable={true} showFilterTemplate={true} />} />
                    <Route path=':SID' element={<SeasonEditForm entityname={ENTITYNAME.Season} mediaCategory={MEDIACATEGORIES.Season} mediaCategoryType={MEDIACATEGORYTYPES.Season} />} />
                </Route>
                <Route path='MediaEpisode/Program' element={<CollectionWithNewForm entityname={ENTITYNAME.MediaEpisode} mediaCategory={MEDIACATEGORIES.Program} mediaCategoryType={MEDIACATEGORYTYPES.ProgramSeries} />} >
                    <Route path='' element={<MediaEpisodeCollection publishingSource={PUBLISHING_SOURCE.MediaEpisode} module={MODULE.MEDIA_EPISODE_PROGRAM} entityname={ENTITYNAME.MediaEpisode} preferenceEntityName={"program"} title={lang.programs_page_breadcrumb} openNewEditForm={true} mediaCategory={MEDIACATEGORIES.Program} mediaCategoryType={MEDIACATEGORYTYPES.Program} wherestatement={['MediaCategory.SID', '=', MEDIACATEGORIES.Program]} customEditForm={MediaEpisodeEditForm} menuModel={collectionProgramMenuModel} isPageable={true} showFilterTemplate={true} enableMutliSelect={true} enableMultipleUpdate={true} />} />
                    <Route path=':SID' element={<MediaEpisodeEditForm mediaCategory={MEDIACATEGORIES.Program} mediaCategoryType={MEDIACATEGORYTYPES.Program} />} />
                </Route>

                <Route path='MediaEpisode/Episode' element={<CollectionWithNewForm entityname={ENTITYNAME.MediaEpisode} mediaCategory={MEDIACATEGORIES.Episode} mediaCategoryType={MEDIACATEGORYTYPES.ProgramSeries} />} >
                    <Route path='' element={<MediaEpisodeCollection publishingSource={PUBLISHING_SOURCE.MediaEpisode} module={MODULE.EPISODE} entityname={ENTITYNAME.MediaEpisode} preferenceEntityName={"episode"} title={lang.episode_page_breadcrumb} openNewEditForm={true} mediaCategory={MEDIACATEGORIES.Episode} mediaCategoryType={MEDIACATEGORYTYPES.ProgramSeries} wherestatement={['MediaCategory.SID', '=', MEDIACATEGORIES.Episode]} customEditForm={MediaEpisodeEditForm} menuModel={collectionQuickEditWithoutDeleteMenuModel} isPageable={true} />} />
                    <Route path=':SID' element={<MediaEpisodeEditForm mediaCategory={MEDIACATEGORIES.Episode} mediaCategoryType={MEDIACATEGORYTYPES.ProgramSeries} />} />
                </Route>
                <Route path='Season/Episode/:AssetId' element={<MediaEpisodesBySeasonAssetIdCollection />} />

                <Route path='MediaEpisode/Movie' element={<CollectionWithNewForm entityname={ENTITYNAME.MediaEpisode} mediaCategory={MEDIACATEGORIES.Movie} mediaCategoryType={MEDIACATEGORYTYPES.Movie} />} >
                    <Route path='' element={<MediaEpisodeCollection publishingSource={PUBLISHING_SOURCE.MediaEpisode} module={MODULE.MEDIA_EPISODE_MOVIE} entityname={ENTITYNAME.MediaEpisode} preferenceEntityName={"movie"} title={lang.movies_page_breadcrumb} openNewEditForm={true} mediaCategory={MEDIACATEGORIES.Movie} mediaCategoryType={MEDIACATEGORYTYPES.Movie} wherestatement={['MediaCategory.SID', '=', MEDIACATEGORIES.Movie]} customEditForm={MediaEpisodeEditForm} menuModel={collectionMovieMenuModel} isPageable={true} showFilterTemplate={true} enableMutliSelect={true} enableMultipleUpdate={true} />} />
                    <Route path=':SID' element={<MediaEpisodeEditForm mediaCategory={MEDIACATEGORIES.Movie} mediaCategoryType={MEDIACATEGORYTYPES.Movie} />} />
                </Route>


                <Route path='MediaEpisode/Trailer' element={<CollectionWithNewForm entityname={ENTITYNAME.MediaEpisode} mediaCategory={MEDIACATEGORIES.Trailer} mediaCategoryType={MEDIACATEGORYTYPES.Trailer} />} >
                    <Route path='' element={<MediaEpisodeCollection module={MODULE.MEDIA_EPISODE_TRAILER} entityname={ENTITYNAME.MediaEpisode} preferenceEntityName={"program"} title={lang.trailer_page_breadcrumb} openNewEditForm={true} mediaCategory={MEDIACATEGORIES.Trailer} mediaCategoryType={MEDIACATEGORYTYPES.Trailer} wherestatement={['MediaCategory.SID', '=', MEDIACATEGORIES.Trailer]} customEditForm={MediaEpisodeEditForm} menuModel={collectionQuickEditWithoutDeleteMenuModel} isPageable={true} />} />
                    <Route path=':SID' element={<MediaEpisodeEditForm mediaCategory={MEDIACATEGORIES.Trailer} mediaCategoryType={MEDIACATEGORYTYPES.Trailer} />} />
                </Route>

                <Route path='MediaEpisode/Interstitial' element={<CollectionWithNewForm IsBreak={false} entityname={ENTITYNAME.MediaEpisode} mediaCategory={MEDIACATEGORIES.Interstitial} />}>
                    <Route path='' element={<MediaEpisodeInterstitialCollection module={MODULE.MEDIA_EPISODE_INTERSTITIAL} entityname={ENTITYNAME.MediaEpisode} preferenceEntityName={"interstitial"} title={lang.interstitial_page_breadcrumb} openNewEditForm={true} mediaCategory={MEDIACATEGORIES.Interstitial} wherestatement={[['MediaCategory.SID', '=', MEDIACATEGORIES.Interstitial], ['IsBreak', '!=', true]]} customEditForm={MediaEpisodeInterstitialEditForm} menuModel={collectionMediaInterstitialModel} isPageable={true} showExport={true} />} />
                    <Route path=':SID' element={<MediaEpisodeInterstitialEditForm IsBreak={false} mediaCategory={MEDIACATEGORIES.Interstitial} />} />
                </Route>

                <Route path='MediaEpisode/Commercial' element={<CollectionWithNewForm IsBreak={false} entityname={ENTITYNAME.MediaEpisode} mediaCategory={MEDIACATEGORIES.Commercial} />}>
                    <Route path='' element={<MediaEpisodeInterstitialCollection module={MODULE.MEDIA_EPISODE_COMMERCIAL} entityname={ENTITYNAME.MediaEpisode} preferenceEntityName={"commercial"} title={lang.commercial_page_breadcrumb} openNewEditForm={true} mediaCategory={MEDIACATEGORIES.Commercial} wherestatement={[['MediaCategory.SID', '=', MEDIACATEGORIES.Commercial], ['IsBreak', '!=', true]]} customEditForm={MediaEpisodeInterstitialEditForm} menuModel={collectionMediaInterstitialModel} isPageable={true} showExport={true} />} />
                    <Route path=':SID' element={<MediaEpisodeInterstitialEditForm IsBreak={false} mediaCategory={MEDIACATEGORIES.Commercial} />} />
                </Route>

                <Route path='MediaEpisode/Music' element={<CollectionWithNewForm IsBreak={false} entityname={ENTITYNAME.MediaEpisode} mediaCategory={MEDIACATEGORIES.Music} />}>
                    <Route path='' element={<MediaEpisodeInterstitialCollection module={MODULE.MEDIA_EPISODE_MUSIC} entityname={ENTITYNAME.MediaEpisode} preferenceEntityName={"music"} title={lang.music_page_breadcrumb} openNewEditForm={true} mediaCategory={MEDIACATEGORIES.Music} wherestatement={[['MediaCategory.SID', '=', MEDIACATEGORIES.Music], ['IsBreak', '!=', true]]} customEditForm={MediaEpisodeInterstitialEditForm} menuModel={collectionMediaInterstitialModel} isPageable={true} />} />
                    <Route path=':SID' element={<MediaEpisodeInterstitialEditForm IsBreak={false} mediaCategory={MEDIACATEGORIES.Music} />} />
                </Route>

                <Route path='MediaEpisode/Filler' element={<CollectionWithNewForm IsBreak={false} entityname={ENTITYNAME.MediaEpisode} mediaCategory={MEDIACATEGORIES.Filler} />}>
                    <Route path='' element={<MediaEpisodeInterstitialCollection module={MODULE.MEDIA_EPISODE_FILLER} entityname={ENTITYNAME.MediaEpisode} preferenceEntityName={"filler"} title={lang.filler_page_breadcrumb} openNewEditForm={true} mediaCategory={MEDIACATEGORIES.Filler} wherestatement={[['MediaCategory.SID', '=', MEDIACATEGORIES.Filler], ['IsBreak', '!=', true]]} customEditForm={MediaEpisodeInterstitialEditForm} menuModel={collectionMediaInterstitialModel} isPageable={true} />} />
                    <Route path=':SID' element={<MediaEpisodeInterstitialEditForm IsBreak={false} mediaCategory={MEDIACATEGORIES.Filler} />} />
                </Route>

                {/* <Route path='MediaEpisode/Break' element={<CollectionWithNewForm IsBreak={true} entityname={ENTITYNAME.MediaEpisode} />}>
                    <Route path='' element={<MediaEpisodeInterstitialCollection entityname={ENTITYNAME.MediaEpisode} preferenceEntityName={"break"} title={lang.break_page_breadcrumb} openNewEditForm={true} wherestatement={['IsBreak', '=', true]} customEditForm={MediaEpisodeInterstitialEditForm} menuModel={collectionMediaMenuModel} isPageable={true} />} />
                    <Route path=':SID' element={<MediaEpisodeInterstitialEditForm IsBreak={true} />} />
                </Route> */}

                <Route path='MediaEpisode/Break' element={<MediaEpisodeInterstitialCollection module={MODULE.MEDIA_EPISODE_BREAK} entityname={ENTITYNAME.MediaEpisode} preferenceEntityName={"break"} title={lang.break_page_breadcrumb} wherestatement={['IsBreak', '=', true]} customEditForm={MediaEpisodeBreakEditForm} menuModel={collectionBreakMenuModel} isPageable={true} />} />

                <Route path='MediaEpisodeGroup' element={<Collection module={MODULE.MEDIA_EPISODE_GROUP} entityname={ENTITYNAME.MediaEpisodeGroup} title={lang.episode_group_page_breadcrumb} customEditForm={MediaEpisodeGroupEditForm} isPageable={true} menuModel={collectionMediaEpisodeGroupMenuModel} />} />
                <Route path='ProgramGroup' element={<Collection module={MODULE.PROGRAM_GROUP} entityname={ENTITYNAME.MediaEpisodeGroup} preferenceEntityName={"programgroup"} isLong={true} title={lang.program_group_page_breadcrumb} customEditForm={MediaEpisodeGroupEditForm} menuModel={collectionMediaEpisodeGroupMenuModel} wherestatement={['MediaCategory.isLong', '=', true]} isPageable={true} />} />
                <Route path='InterstitialGroup' element={<Collection module={MODULE.INTERSTITIAL_GROUP} entityname={ENTITYNAME.MediaEpisodeGroup} preferenceEntityName={"interstitialgroup"} isLong={false} title={lang.interstital_group_page_breadcrumb} customEditForm={MediaEpisodeGroupEditForm} menuModel={collectionMediaEpisodeGroupMenuModel} wherestatement={['MediaCategory.isLong', '=', false]} isPageable={true} />} />

                <Route path='Campaign' element={<CollectionWithNewForm entityname={ENTITYNAME.Campaign} />} >

                    <Route path='' element={<CampaignCollection entityname={ENTITYNAME.Campaign} title={lang.campaign_page_breadcrumb} openNewEditForm={true} customEditForm={CampaignEditForm} />} />
                    <Route path=':SID' element={<CampaignEditForm entityname={ENTITYNAME.Campaign} fromBooking={false} />} />

                </Route>

                <Route path='ContentCategory' element={<Collection title={lang.content_caegory_page_breadcrumb} entityname={ENTITYNAME.ContentCategory} customEditForm={ContentCategoryEditForm} />} />
                <Route path='ScheduleReconciliation' element={<ScheduleReconciliation />} />
                <Route path='ImportAsRun' element={<ImportAsRun importType={IMPORTTYPE.AsRun} />} />
                <Route path='ImportPlanning' element={<DataMigration importTypeId={12} />} />
                {/* <Route path='ImportPlanning' element={<ImportAsRun importType={IMPORTTYPE.Planning} />} /> */}
                <Route path='ManageAsRun' element={<ManageAsRun />} />
                <Route path='SecondaryEventPrototype' element={<CollectionWithNewForm entityname={ENTITYNAME.Prototype} />} >

                    <Route path='' element={<SecondaryEventCollection entityname={ENTITYNAME.Prototype} title={lang.prototype_page_breadcrumb} openNewEditForm={true} customEditForm={SecondaryEventPrototype} />} />
                    <Route path=':SID' element={<SecondaryEventPrototype entityname={ENTITYNAME.Prototype} />} />

                </Route>
                <Route path='SecondaryEventStyleSheet' element={<CollectionWithNewForm entityname={ENTITYNAME.SecondaryEventStyleSheet} />} >

                    <Route path='' element={<SecondaryEventCollection entityname={ENTITYNAME.SecondaryEventStyleSheet} title={lang.prototype_style_sheet_page_breadcrumb} openNewEditForm={true} customEditForm={SecondaryEventStyleSheet} />} />
                    <Route path=':SID' element={<SecondaryEventStyleSheet entityname={ENTITYNAME.SecondaryEventStyleSheet} />} />

                </Route>

                <Route path='DefaultSecondaryEventPrototype' element={<CollectionWithNewForm entityname={ENTITYNAME.SecondaryEventDefaultPrototype} />} >

                    <Route path='' element={<SecondaryEventCollection entityname={ENTITYNAME.SecondaryEventDefaultPrototype} title={lang.default_prototype_style_sheet_page_breadcrumb} openNewEditForm={true} customEditForm={DefaultSecondaryEventPrototype} />} />
                    <Route path=':SID' element={<DefaultSecondaryEventPrototype entityname={ENTITYNAME.SecondaryEventDefaultPrototype} />} />

                </Route>
                {/* <Route path='IngestionTemplate' element={<IngestionTemplate />} /> */}
                <Route path='IngestionTemplate' element={<CollectionWithNewForm entityname={ENTITYNAME.PartnerIntegrationTemplate} />} >

                    <Route path='' element={<Collection entityname={ENTITYNAME.PartnerIntegrationTemplate} title={lang.ingestion_page_breadcrumb} openNewEditForm={true} customEditForm={IngestionTemplate} />} />
                    <Route path=':SID' element={<IngestionTemplate entityname={ENTITYNAME.PartnerIntegrationTemplate} />} />

                </Route>
                <Route path='PartnerIngetion' element={<PartnerIngetion />} />

                <Route path="Jobs" element={<Collection entityname={ENTITYNAME.OttTranscodeStatus} showAdd={false} title={lang.jobs_page_breadcrumb} customEditForm={JobsShowForm} menuModel={collectionJobsMenuModel} sort={{ SID: -1 }} showArchive={false} />} />

                {/* <Route path='DataMigration' element={<DataMigration importTypeId={0} />} /> */}
                <Route path='BillingCollection' element={<BillingCollection />} />
                <Route path='GeneratedBillCollection' element={<GeneratedBillCollection />} />
                <Route path='ProtoTypeSource' element={<Collection entityname={ENTITYNAME.ProtoTypeSource} title={lang.prototype_source_page_breadcrumb} />} />
                <Route path='BankAccount' element={<Collection title={lang.bank_account_page_breadcrumb} entityname={ENTITYNAME.BankAccount} />} />
                <Route path='Tax' element={<Collection title={lang.tax_page_breadcrumb} entityname={ENTITYNAME.Tax} menuModel={collectionMenuModelAsDefualt} />} />
                <Route path='BookingTransfer' element={<BookingTransfer />} />
                <Route path='ShowSpots' element={<ShowSpots />} />

                <Route path='exportepgtemplate' element={<Collection entityname={ENTITYNAME.ExportTemplate} title={lang.export_template_page_breadcrumb} customEditForm={ExportTemplate} type={EXPORT_TEMPLATE_MODULES[0]} wherestatement={['ModuleType.SID', '=', EXPORT_TEMPLATE_MODULES[0].SID]} menuModel={exportTemplateMenuModel} />} />

                <Route path='exportmanagecontenttemplate' element={<Collection entityname={ENTITYNAME.ExportTemplate} title={lang.manage_content_template_breadcrumb} customEditForm={ExportTemplate} type={EXPORT_TEMPLATE_MODULES[1]} wherestatement={['ModuleType.SID', '=', EXPORT_TEMPLATE_MODULES[1].SID]} />} />

                <Route path='Dashboard' element={< Dashboard />} />
                <Route path='format' element={<CollectionWithNewForm entityname={ENTITYNAME.Format} />} >
                    <Route path='' element={<Collection entityname={ENTITYNAME.Format} title={lang.format_page_breadcrumb} openNewEditForm={true} customEditForm={Format} menuModel={collectionMenuModelAsDefualt} />} />
                    <Route path=':SID' element={<Format entityname={ENTITYNAME.Format} />} />
                </Route>
                <Route path='FormFields' element={<FormFieldsCollection entityname={ENTITYNAME.FormFields} wherestatement={['sequence', '>', 0]} title={lang.form_fields_page_breadcrumb} customEditForm={FormFields} />} />
                <Route path='exportepgreport' element={<ExportEPGReport />} />
                <Route path='SecondaryEventDynamicRule' element={<Collection entityname={ENTITYNAME.SecondaryEventDynamicRule} title={lang.secondary_event_dynamic_rule_page_breadcrumb} customEditForm={SecondaryEventDynamicRule} />} />
                <Route path='timerange' element={<Collection entityname={ENTITYNAME.TimeRange} showAdd={false} title={lang.time_range_page_breadcrumb} customEditForm={TimeRangeEditForm} />} />
                <Route path='company' element={<CollectionWithNewForm entityname={ENTITYNAME.Company} />} >
                    <Route path='' element={<CompanyCollection showAdd={false} entityname={ENTITYNAME.Company} title={lang.company_page_breadcrumb} openNewEditForm={true} customEditForm={CompanyForm} menuModel={collectionCompanyMenuModel}/>} />
                    <Route path=':SID' element={<CompanyForm />} />
                </Route>
                <Route path='applicationConfiguration' element={<Collection module={MODULE.APPLICATION_CONFIGURATION} entityname={ENTITYNAME.ApplicationConfiguration} title={lang.application_configuration_breadcrumb} customEditForm={ApplicationConfigurationForm} />} />
                <Route path='PaymentPlan' element={<CollectionWithNewForm entityname={ENTITYNAME.PaymentPlan} />} >
                    <Route path='' element={<Collection entityname={ENTITYNAME.PaymentPlan} title={lang.payment_plan_page_breadcrumb} openNewEditForm={true} customEditForm={PaymentPlanEditForm} />} />
                    <Route path=':SID' element={<PaymentPlanEditForm entityname={ENTITYNAME.PaymentPlan} />} />
                </Route >
                <Route path="OttProduct" element={<Collection entityname={ENTITYNAME.OttProduct} title={lang.ott_product_label} customEditForm={OttProductEditForm} />} />
                <Route path='MakeGoodSpot' element={<MakeGoodSpot />} />
                <Route path="Coupon" element={<Collection entityname={ENTITYNAME.Coupon} title={lang.coupon_page_breadcrumb} customEditForm={CouponEditForm} />} />
                <Route path="AuditLog" element={<AuditLogCollection entityname={ENTITYNAME.UserActivityLogs} title={lang.audit_log_breadcrumb} isPageable={true} />} />
                <Route path="Genre" element={<Collection entityname={ENTITYNAME.Genre} title={lang.genre_page_breadcrumb} menuModel={collectionMenuModelAsDefualt} customEditForm={GenreEditForm} />} />
                <Route path="OttSegment" element={<Collection entityname={ENTITYNAME.OttSegment} title={lang.ott_segment_page_breadcrumb} customEditForm={OttSegmentEditForm} />} />
                <Route path="PGRating" element={<Collection entityname={ENTITYNAME.PGRating} title={lang.pgrating_page_breadcrumb} menuModel={collectionMenuModelAsDefualt} />} />
                <Route path="MediaCategoryType" element={<Collection entityname={ENTITYNAME.MediaCategoryType} title={lang.media_category_type_label} customEditForm={MediaCategoryTypeEditForm} />} />
                <Route path='WorkFlow' element={<Collection entityname={ENTITYNAME.Workflow} title={lang.workflow_page_breadcrumb} customEditForm={WorkflowEditForm} />} />
                <Route path='UserFavourite' element={<FavouritesEditForm />} />
                <Route path="Version" element={<Collection entityname={ENTITYNAME.Version} title={lang.version_page_breadcrumb} menuModel={collectionMenuModelAsDefualt} />} />
                <Route path='LiveEvents' element={<LiveEventsMain />} />
                <Route path="LiveEventType" element={<Collection entityname={ENTITYNAME.LiveEventType} title={lang.LiveEventType_sub_menu} customEditForm={LiveEventTypeEditForm} />} />
                <Route path="LiveEventRound" element={<Collection entityname={ENTITYNAME.LiveEventRound} title={lang.LiveEventRound_sub_menu} />} />
                <Route path="TournamentType" element={<Collection entityname={ENTITYNAME.TournamentType} title={lang.TournamentType_sub_menu} customEditForm={TournamentTypeEditForm} />} />
                <Route path='Optimisation' element={<CollectionWithNewForm entityname={ENTITYNAME.SalesOptimizationTemplate} />} >
                    <Route path='' element={<Collection entityname={ENTITYNAME.SalesOptimizationTemplate} title={lang.optimisation_page_breadcrumb} menuModel={exportTemplateMenuModel} openNewEditForm={true} customEditForm={OptimisationEditForm} />} />
                    <Route path=':SID' element={<OptimisationEditForm entityname={ENTITYNAME.SalesOptimizationTemplate} />} />
                </Route >

                <Route path='interstitialhistoryreport' element={<InterstitialHistoryReport />} />
                <Route path='clientagencybookingreport' element={<ClientAgencyBookingReport />} />
                <Route path="TechnicalDetailPrototype" element={<Collection entityname={ENTITYNAME.TechnicalDetailsPrototype} title={lang.technical_details_prototype_page_breadcrumb} customEditForm={TechnicalDetailPrototypeEditForm} />} />
                <Route path="AudioCodec" element={<Collection entityname={ENTITYNAME.AudioCodec} title={lang.audio_codec_sub_menu} />} />
                <Route path="VideoCodec" element={<Collection entityname={ENTITYNAME.VideoCodec} title={lang.video_codec_sub_menu} />} />
                <Route path="DeliveryMethod" element={<Collection entityname={ENTITYNAME.DeliveryMethod} title={lang.delivery_method_sub_menu} />} />
                <Route path="VideoAspectRatio" element={<Collection entityname={ENTITYNAME.VideoAspectRatio} title={lang.video_aspect_ratio_sub_menu} />} />
                <Route path="VideoType" element={<Collection entityname={ENTITYNAME.VideoType} title={lang.video_type_sub_menu} />} />
                <Route path="BookingScheduleStatus" element={<BookingScheduleStatus />} />
                <Route path='missingmediareport' element={<MissingMediaReport />} />
                <Route path='mamAssets' element={<Collection module={MODULE.MEDIA_EPISODE_MAMASSETS} entityname={ENTITYNAME.MediaMamAssets} title={lang.mamassets_page_breadcrumb} customEditForm={MediaMamAssetsEditForm} />} />
                <Route path='casttype' element={<Collection title={lang.cast_Type_page_breadcrumb} entityname={ENTITYNAME.CastType} />} />
                <Route path='ratecard' element={<Collection entityname={ENTITYNAME.RateCard} title={lang.ratecard_page_breadcrumb} customEditForm={RateCardEditForm} />} />
                <Route path='OttMenu' element={<CollectionWithNewForm entityname={ENTITYNAME.OttMenu} />} >
                    <Route path='' element={<Collection module={MODULE.OTTMENU} entityname={ENTITYNAME.OttMenu} showAdd={false} title={lang.ottmenu_page_breadcrumb} openNewEditForm={true} menuModel={OttMenuModel} customEditForm={OttMenuForm} showArchive={false} />} />
                    <Route path=':SID' element={<OttMenuForm entityname={ENTITYNAME.OttMenu} />} />
                </Route >
                <Route path='campaigntype' element={<Collection title={lang.campagn_type_page_breadcrumb} entityname={ENTITYNAME.CampaignType} menuModel={collectionMenuModelAsDefualt} />} />
                <Route path='dealtype' element={<Collection title={lang.deal_type_page_breadcrumb} entityname={ENTITYNAME.DealType} menuModel={collectionMenuModelAsDefualt} />} />
                <Route path='location' element={<Collection title={lang.location_page_breadcrumb} entityname={ENTITYNAME.Location} menuModel={collectionMenuModelAsDefualt} />} />
                <Route path='OttSetting' element={<OttSettingForm />} />
                <Route path='prelogpostlogreport' element={<PrelogPostlogReport />} />
                <Route path='creditlinereport' element={<CreditLineReport />} />
                <Route path='prelogpostlogreport' element={<PrelogPostlogReport />} />
                <Route path='purgespotreport' element={<PurgeSpotReport />} />
                <Route path='airtimeconsumptionreport' element={<AirTimeConsumptionReport />} />
                <Route path='protoTypeContent' element={<Collection entityname={ENTITYNAME.ProtoTypeContent} title={lang.prototypecontent_page_breadcrumb} customEditForm={ProtoTypeContentEditForm} />} />
                <Route path='amazondeliverygroup' element={<CollectionWithNewForm entityname={ENTITYNAME.AmazonDeliveryGroup} />} >
                    <Route path='' element={<Collection entityname={ENTITYNAME.AmazonDeliveryGroup} title={lang.amazon_delivery_group_page_breadcrumb} openNewEditForm={true} customEditForm={AmazonDeliveryGroup} />} />
                    <Route path=':SID' element={<AmazonDeliveryGroup entityname={ENTITYNAME.AmazonDeliveryGroup} />} />

                </Route>
                <Route path='ottpackagedeliveryschedule' element={<Collection entityname={ENTITYNAME.OttPackageDeliverySchedule} title={lang.ott_package_delivery_schedule_page_breadcrumb} showAdd={false} menuModel={collectionWithoutCommandMenuModel} showArchive={false} />} />

                <Route path='scheduleautofillinterstitial' element={<CollectionWithNewForm entityname={ENTITYNAME.ScheduleAutoFillInterstitial} />} >
                    <Route path='' element={<Collection entityname={ENTITYNAME.ScheduleAutoFillInterstitial} title={lang.schedule_auto_fill_interstitial_page_breadcrumb} openNewEditForm={true} customEditForm={ScheduleAutofillInterstitial} />} />
                    <Route path=':SID' element={<ScheduleAutofillInterstitial entityname={ENTITYNAME.ScheduleAutoFillInterstitial} />} />
                </Route >

                <Route path='MediaEpisode/Dummy' element={<MediaEpisodeInterstitialCollection module={MODULE.MEDIA_EPISODE_DUMMY} entityname={ENTITYNAME.MediaEpisode} preferenceEntityName={"dummy"} title={lang.dummy_page_breadcrumb} wherestatement={['IsDummy', '=', true]} customEditForm={MediaEpisodeDummyEditForm} menuModel={collectionDummyMenuModel} isPageable={true} />} />
                <Route path='MamAssetReport' element={<MamAssetReport />} />

                <Route path='PendingApproval' element={<PendingApprovalCollection />} />
                <Route path='breakConfiguration' element={<Collection entityname={ENTITYNAME.BreakConfiguration} title={lang.break_configuration_page_breadcrumb} customEditForm={BreakConfigurationEditForm} />} />
                <Route path='billingsummaryReport' element={<BillingSummaryReport />} />
                <Route path='PlaylistLockStatus' element={<Collection entityname={ENTITYNAME.PLAYLIST_LOCK_STATUS} title={lang.playlist_lock_page_breadcrumb} showAdd={false} menuModel={collectionPlanningLockScheduleMenuModel} showArchive={false} />} />
                <Route path="AnnouncementReconcile" element={<AnnouncementReconcileCollection entityname={ENTITYNAME.Booking} title={lang.announcement_reconcile_page_breadcrumb} />} />
                <Route path='EPGChannel' element={<EPGCollection entityname={ENTITYNAME.EPGChannel} title={lang.epg_channel_page_breadcrumb} customEditForm={EPGChannelEditForm} />} />
                <Route path='faqcategory' element={<Collection title={lang.faq_category_page_breadcrumb} entityname={ENTITYNAME.FaqCategory} />} />
                <Route path='faq' element={<FaqCollection title={lang.faq_page_breadcrumb} entityname={ENTITYNAME.Faq} customEditForm={FaqEditForm} />} />
                <Route path='tutorialvideos' element={<Collection module={MODULE.TUTORIAL_VIDEOS} title={lang.tutorial_videos_page_breadcrumb} entityname={ENTITYNAME.TutorialVideos} customEditForm={TutorialVideosEditForm} />} menuModel={collectionAnnouncementReconcileMenuModel} />
                <Route path='MediaMasterReport' element={<MediaMasterReport />} />
                <Route path='marketingScreens' element={<CollectionWithNewForm entityname={ENTITYNAME.MarketingScreens} />} >
                    <Route path='' element={<Collection entityname={ENTITYNAME.MarketingScreens} title={lang.marketing_screens_page_breadcrumb} openNewEditForm={true} showAdd={false} customEditForm={MarketingScreenEditForm} />} />
                    <Route path=':SID' element={<MarketingScreenEditForm entityname={ENTITYNAME.MarketingScreens} />} />
                </Route>
                <Route path='promotionalScreens' element={<CollectionWithNewForm entityname={ENTITYNAME.PromotionalScreens} />} >
                    <Route path='' element={<Collection entityname={ENTITYNAME.PromotionalScreens} title={lang.promotional_screens_page_breadcrumb} openNewEditForm={true} showAdd={false} customEditForm={PromotionalScreenEditForm} />} />
                    <Route path=':SID' element={<PromotionalScreenEditForm entityname={ENTITYNAME.PromotionalScreens} />} />
                </Route>
                <Route path='ottprovider' element={<Collection title={lang.provider_page_breadcrumb} entityname={ENTITYNAME.OttProvider} customEditForm={ProviderEditForm} menuModel={collectionMenuModelAsDefualt} />} />
                <Route path='Module' element={<ModuleCollection entityname={ENTITYNAME.Module} showAdd={false} title={lang.module_page_breadcrumb} customEditForm={ModuleEditForm} showArchive={false} enableMutliSelect={true} />} />

                <Route path='FinancialYear' element={<Collection entityname={ENTITYNAME.FinancialYear} title={lang.financial_year_page_breadcrumb} customEditForm={FinancialYearEditForm} />} />

                <Route path='SalesTarget' element={<Collection entityname={ENTITYNAME.SalesTarget} title={lang.sales_target_page_breadcrumb} customEditForm={SalesTargetEditForm} />} />
                <Route path='document' element={<Collection module={MODULE.DOCUMENT} entityname={ENTITYNAME.Document} title={lang.document_page_breadcrumb} customEditForm={DocumentEditForm} />} />
                <Route path='screensaver' element={<CollectionWithNewForm entityname={ENTITYNAME.ScreenSaver} />} >
                    <Route path='' element={<Collection entityname={ENTITYNAME.ScreenSaver} title={lang.screen_saver_page_breadcrumb} openNewEditForm={true} showAdd={true} customEditForm={ScreenSaverEditForm} />} />
                    <Route path=':SID' element={<ScreenSaverEditForm entityname={ENTITYNAME.ScreenSaver} />} />
                </Route>
                <Route path='planningslot' element={<Collection entityname={ENTITYNAME.PlanningSlot} title={lang.planning_slot_page_breadcrumb} customEditForm={PlanningSlotEditForm} />} />
                <Route path='WebAssets' element={<CollectionWithNewForm entityname={ENTITYNAME.WebAssets} />} >
                    <Route path='' element={<Collection module={MODULE.WEB_ASSETS} entityname={ENTITYNAME.WebAssets} title={lang.web_assets_page_breadcrumb} openNewEditForm={true} showAdd={true} customEditForm={WebAssetsEditForm} />} />
                    <Route path=':SID' element={<WebAssetsEditForm entityname={ENTITYNAME.WebAssets} />} />
                </Route>
                <Route path='sponsortype' element={<Collection entityname={ENTITYNAME.SponsorType} title={lang.sponsor_type_page_breadcrumb} customEditForm={SponsorTypeEditForm} />} />
                <Route path='pipagesetting' element={<PiAndSeoEditForm />} />
                <Route path='theme' element={<CollectionWithNewForm entityname={ENTITYNAME.Theme} />} >
                    <Route path='' element={<Collection module={MODULE.THEME} entityname={ENTITYNAME.Theme} title={lang.theme_page_breadcrumb} openNewEditForm={true} showAdd={true} customEditForm={ThemeEditForm} />} />
                    <Route path=':SID' element={<ThemeEditForm entityname={ENTITYNAME.Theme} />} />
                </Route>
                <Route path="AgencyType" element={<Collection entityname={ENTITYNAME.AgencyType} title={lang.agency_type_sub_menu} />} />

                <Route path='jobsSchedule' element={<Collection module={MODULE.JOBS_SCHEDULE} entityname={ENTITYNAME.JobsSchedule} title={lang.jobs_schedule_page_breadcrumb} customEditForm={JobsScheduleEditForm} menuModel={collectionMenuModel} />} />
                {/* //<Route path='variance' element={<Collection entityname={ENTITYNAME.Variance} title={lang.variance_type_sub_menu} />} /> */}
                <Route path='variance' element={<Collection module={MODULE.VARIANCE} entityname={ENTITYNAME.Variance} title={lang.variance_type_sub_menu} customEditForm={VarianceEditForm} menuModel={collectionMenuModel} />} />
                <Route path='Pincodes' element={<Collection module={MODULE.PINCODS} entityname={ENTITYNAME.Pincodes} title={lang.pincode_sub_menu} />} />
                <Route path='States' element={<Collection module={MODULE.STATES} entityname={ENTITYNAME.States} title={lang.states_sub_menu} />} />
                <Route path='Countries' element={<Collection module={MODULE.COUNTRIES} entityname={ENTITYNAME.Countries} title={lang.countries_sub_menu} />} />
                {/* <Route path="LiveEventRound" element={<Collection entityname={ENTITYNAME.LiveEventRound} title={lang.LiveEventRound_sub_menu} />} /> */}
                <Route path="timezone" element={<Collection entityname={ENTITYNAME.TimeZone} title={lang.time_zone_page_breadcrumb} customEditForm={TimeZoneEditForm} menuModel={collectionMenuModelAsDefualt} />} />
                <Route path="MusicCategory" element={<Collection entityname={ENTITYNAME.MusicCategory} title={lang.music_category_page_breadcrumb} customEditForm={MusicCategoryEditForm} />} />
                <Route path='programTimeConsumptionReport' element={<ProgramTimeConsumptionReport />} />
                <Route path='musicReport' element={<MusicReport />} />
                <Route path="TxMode" element={<Collection entityname={ENTITYNAME.TXMode} title={lang.tx_mode_page_breadcrumb} customEditForm={TxModeEditForm} />} />
                <Route path="resolution" element={<Collection entityname={ENTITYNAME.Resolution} title={lang.resolution_page_breadcrumb} customEditForm={ResolutionEditForm} />} />
                <Route path='digitalsignScreen' element={<CollectionWithNewForm entityname={ENTITYNAME.DigitalSignScreen} />} >
                    <Route path='' element={<DigisignScreenCollection module={MODULE.Digital_Sign_Screen} entityname={ENTITYNAME.DigitalSignScreen} title={lang.screen_page_breadcrumb} openNewEditForm={true} customEditForm={ScreenEditForm}  menuModel={collectionDigitalSignScreenMenuModel}/>} />
                    <Route path=':SID' element={<ScreenEditForm entityname={ENTITYNAME.DigitalSignScreen} />} />
                </Route>
                <Route path="TxStatus" element={<Collection entityname={ENTITYNAME.TXStatus} title={lang.tx_status_page_breadcrumb} />} />
                <Route path='Playlist' element={<HeaderPlaylistCollection entityname={ENTITYNAME.DigitalSignPlaylist} />} />
                <Route path='PlaylistItem/:playlistId' element={<PlaylistWrapperNew />} />
                <Route path='digitalSignBoardOverlay' element={<CollectionWithNewForm entityname={ENTITYNAME.DigitalSignOverlay} />} >
                    <Route path='' element={<DigitalSignCollection entityname={ENTITYNAME.DigitalSignOverlay} openNewEditForm={true} title={lang.digital_sign_board_overlay_page_breadcrumb} customEditForm={OverlayEditForm} menuModel={collectionMenuModelWithDelete} isPageable={true} customColumns={DigitalSign_OverlaysColumns} showPreferenceButton={false}/> } />
                    <Route path=':SID' element={<OverlayEditForm entityname={ENTITYNAME.DigitalSignOverlay} />} />
                </Route >
                <Route path="formatSchedule" element={<Collection entityname={ENTITYNAME.FormatSchedule} title={lang.format_schedule_page_breadcrumb} customEditForm={FormatScheduleForm} />} />
                <Route path="digitalSignScreenGroup" element={<DigitalSignScreenGroupCollection entityname={ENTITYNAME.DigitalSignScreenGroup} title={lang.digital_sign_screen_group_page_breadcrumb} customEditForm={ScreenGroupEditForm}/>} />
                <Route path='digitalSignContent' element={<DigiSignContentCollection />} />
                <Route path='digitalSignDashboard' element={<DigitalSignDashboardForm />} />
                <Route path="instances" element={<Collection module={MODULE.INSTANCE} entityname={ENTITYNAME.Instance} title={lang.instances_page_breadcrumb} customEditForm={InstanceEditForm}/>} />
                <Route path="dayPart" element={<Collection module={MODULE.DAYPART} entityname={ENTITYNAME.DayPart} title={lang.day_part_page_breadcrumb} customEditForm={DayPartEditForm} />} />
                <Route path='digitalsignCompanyPlans' element={<Collection module={MODULE.DIGITAL_SIGN_COMPANY_PLANS} showArchive={false} showAdd={false} entityname={ENTITYNAME.DigitalSignCompanyPlans} title={lang.digital_sign_company_plans_page_breadcrumb} menuModel={collectionWithoutCommandMenuModel} customColumns={DigitalSign_CompanyPlansColumns}/>} />
                <Route path='digitalSignAdminDashboard' element={<DigitalSignAdminDashboardForm />} />
                <Route path='digitalSignPlans' element={<Collection module={MODULE.DIGITAL_SIGN_PLANS} entityname={ENTITYNAME.DigitalSignPlan} title={lang.digital_sign_plans_page_breadcrumb} customColumns={DigitalSign_PlansMasterColumns}/>} />
                <Route path='digitalSignContentGroup' element={<CollectionWithNewForm entityname={ENTITYNAME.DigitalSignContentGroup} />} >
                    <Route path='' element={<DigitalSignCollection entityname={ENTITYNAME.DigitalSignContentGroup} openNewEditForm={true} title={lang.digital_sign_content_group_page_breadcrumb} customEditForm={DigitalSignContentGroupEditForm}  isPageable={true} customColumns={DigitalSign_ContentGroupColumns} showPreferenceButton={false}/> } />
                    <Route path=':SID' element={<DigitalSignContentGroupEditForm entityname={ENTITYNAME.DigitalSignContentGroup} />} />
                </Route >
                <Route path="ottPlatform" element={<Collection module={MODULE.PLATFORM} entityname={ENTITYNAME.Platform} title={lang.ott_platform_page_breadcrumb} />} />
                <Route path='ingestionReport' element={<IngestionReport />} />
                <Route path='digitalSignCompany' element={<DigitalSignCompanyCollection entityname={ENTITYNAME.Company} title={lang.company_page_breadcrumb} customColumns={DigitalSign_CompanyColumns} customEditForm={DigitalSignCompanyForm} />} />
                <Route path='tournamentGroup' element={<Collection entityname={ENTITYNAME.TournamentGroup} title={lang.tournament_group_page_breadcrumb} customEditForm={TournamentGroupEditForm} />} />
                <Route path='epgConvertor' element={<EPGConvertor />} />
                <Route path='mediaTemplate' element={<CollectionWithNewForm entityname={ENTITYNAME.MediaTemplate}/>} >
                    <Route path='' element={<Collection entityname={ENTITYNAME.MediaTemplate} title={lang.media_template_page_breadcrumb} openNewEditForm={true} customEditForm={MediaTemplateEditForm} isPageable={true}/>} />
                    <Route path=':SID' element={<MediaTemplateEditForm entityname={ENTITYNAME.MediaTemplate}  />} />
                </Route >
            </Route >
        </Route>
    </Routes>
}

export default RouteManagment;